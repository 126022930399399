import { call, put } from 'redux-saga/effects'
import { postSkipEa } from './api'
import actions from '../actions'
import { toastr } from 'react-redux-toastr'

function* doPostSkipEa(payload) {
  try {
    yield put(actions.setWaitingSkipEA(true))
    yield call(postSkipEa, payload)
    yield put(actions.setSkipEa(payload.id))
    yield put(actions.sagaGetLimboProductDetailsBySku(payload.sku))
    toastr.success('', 'Item skipped successfully.')
  } catch (err) {
    let error = ''

    if (err?.response?.data?.length > 0) {
      error = err?.response?.data[0].shelter[0]
    } else {
      error = err?.response?.data?.message || 'Something went wrong.'
    }

    toastr.error('', error)
  } finally {
    yield put(actions.setWaitingSkipEA(false))
  }
}

export default doPostSkipEa
