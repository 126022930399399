// sagas
const SAGA_GET_DOCUMENTATION = 'SAGA_GET_DOCUMENTATION'
const SAGA_GET_DOCUMENTATION_BY_CATEGORY = 'SAGA_GET_DOCUMENTATION_BY_CATEGORY'
const SAGA_GET_DOCUMENTATION_VERSIONS_BY_ID =
  'SAGA_GET_DOCUMENTATION_VERSIONS_BY_ID'
const SAGA_POST_DOCUMENTATION = 'SAGA_POST_DOCUMENTATION'
const SAGA_PUT_DOCUMENTATION = 'SAGA_PUT_DOCUMENTATION'
const SAGA_DOWNLOAD_DOCUMENTATION = 'SAGA_DOWNLOAD_DOCUMENTATION'

// setters and getters
const SET_DOCUMENTATION = 'SET_DOCUMENTATION'
const SET_DOCUMENTATION_BY_CATEGORY = 'SET_DOCUMENTATION_BY_CATEGORY'
const SET_DOCUMENTATION_VERSIONS_BY_ID = 'SET_DOCUMENTATION_VERSIONS_BY_ID'
const SET_DOCUMENTATION_LOADING = 'SET_DOCUMENTATION_LOADING'
const SET_DOCUMENTATION_DOWNLOADING = 'SET_DOCUMENTATION_DOWNLOADING'
const SET_DOCUMENTATION_FORM = 'SET_DOCUMENTATION_FORM'
const UPDATE_DOCUMENTATION_FORM = 'UPDATE_DOCUMENTATION_FORM'
const RESET_DOCUMENTATION_FORM = 'RESET_DOCUMENTATION_FORM'
const SET_DOCUMENTATION_RELOAD = 'SET_DOCUMENTATION_RELOAD'

export default {
  SAGA_GET_DOCUMENTATION,
  SAGA_GET_DOCUMENTATION_BY_CATEGORY,
  SAGA_GET_DOCUMENTATION_VERSIONS_BY_ID,
  SAGA_POST_DOCUMENTATION,
  SAGA_PUT_DOCUMENTATION,
  SAGA_DOWNLOAD_DOCUMENTATION,
  SET_DOCUMENTATION,
  SET_DOCUMENTATION_BY_CATEGORY,
  SET_DOCUMENTATION_VERSIONS_BY_ID,
  SET_DOCUMENTATION_LOADING,
  SET_DOCUMENTATION_DOWNLOADING,
  SET_DOCUMENTATION_FORM,
  UPDATE_DOCUMENTATION_FORM,
  RESET_DOCUMENTATION_FORM,
  SET_DOCUMENTATION_RELOAD
}
