import types from './types'

const INITIAL_STATE = {
  paginator: {},
  current: {},
  refresh_to_dashboard: null,
  reportDetails: {},
  loading: false
}

const fundsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PAGINATOR: {
      return {
        ...state,
        paginator: action.paginator
      }
    }

    case types.SET_CURRENT: {
      return {
        ...state,
        current: action.current
      }
    }

    case types.SWITCH_FUND_REFRESH: {
      return {
        ...state,
        refresh_to_dashboard: action.value
      }
    }

    case types.SET_ECOMMERCE_FUND_REPORT_DETAILS: {
      return {
        ...state,
        reportDetails: action.payload
      }
    }

    case types.SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }

    default:
      return state
  }
}

export default fundsReducer
