const SAGA_GET_MULTIPLE_CAMPAIGN_EMAIL = 'SAGA_GET_MULTIPLE_CAMPAIGN_EMAIL'
const SAGA_POST_MULTIPLE_CAMPAIGN_EMAIL = 'SAGA_POST_MULTIPLE_CAMPAIGN_EMAIL'
const SAGA_POST_MULTIPLE_CAMPAIGN_EMAIL_PREVIEW =
  'SAGA_POST_MULTIPLE_CAMPAIGN_EMAIL_PREVIEW'
const SET_MULTIPLE_CAMPAIGN_EMAIL_FORM = 'SET_MULTIPLE_CAMPAIGN_EMAIL_FORM'
const UPDATE_MULTIPLE_CAMPAIGN_EMAIL_FORM =
  'UPDATE_MULTIPLE_CAMPAIGN_EMAIL_FORM'
const RESET_MULTIPLE_CAMPAIGN_EMAIL_FORM = 'RESET_MULTIPLE_CAMPAIGN_EMAIL_FORM'
const SET_MULTIPLE_CAMPAIGN_EMAIL_PREVIEW =
  'SET_MULTIPLE_CAMPAIGN_EMAIL_PREVIEW'

export default {
  SAGA_GET_MULTIPLE_CAMPAIGN_EMAIL,
  SAGA_POST_MULTIPLE_CAMPAIGN_EMAIL,
  SAGA_POST_MULTIPLE_CAMPAIGN_EMAIL_PREVIEW,
  SET_MULTIPLE_CAMPAIGN_EMAIL_FORM,
  UPDATE_MULTIPLE_CAMPAIGN_EMAIL_FORM,
  RESET_MULTIPLE_CAMPAIGN_EMAIL_FORM,
  SET_MULTIPLE_CAMPAIGN_EMAIL_PREVIEW
}
