import { call, put } from 'redux-saga/effects'
import errorPaser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { getLimboProductDetailsBySku } from './api'
import actions from '../actions'

function* doGetLimboProductDetailsBySku({ sku }) {
  try {
    yield put(actions.setLoadingSkus(true))
    const response = yield call(getLimboProductDetailsBySku, sku)
    yield put(actions.setLimboProductDetailsBySku(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(actions.setLoadingSkus(false))
  }
}

export default doGetLimboProductDetailsBySku
