import types from './types'

const INITIAL_STATE = {
  campaignId: null,
  paginator: {},
  form: {
    short_description: '',
    templateId: 0,
    photo: '',
    email_subject: '',
    send_list: '',
    should_email: true,
    email_list: [],
    subjects: [],
    camp_updates_date: '',
    preview: '',
    type: 1,
    scheduleDate: ''
  },
  exhaustedEmailList: false,
  deleting: false
}

const campaignUpdateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CAMPAIGN_UPDATES: {
      return {
        ...state,
        paginator: action.paginator
      }
    }

    case types.SET_CAMPAIGN_UPDATE_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data
        }
      }
    }

    case types.UPDATE_CAMPAIGN_UPDATE_FORM: {
      return {
        ...state,
        form: {
          ...action.form
        }
      }
    }

    case types.RESET_CAMPAIGN_UPDATE_FORM: {
      return {
        ...state,
        form: {
          ...INITIAL_STATE.form
        }
      }
    }

    case types.SET_CAMPAIGN_UPDATE_PREVIEW_EMAIL: {
      return {
        ...state,
        form: {
          ...state.form,
          preview: action.preview
        }
      }
    }

    case types.EXHAUSTED_CAMPAIGN_UPDATE_EMAIL_LIST: {
      return {
        ...state,
        exhaustedEmailList: action.fetching
      }
    }

    case types.UPDATE_CAMPAIGN_UPDATE_EMAIL_LIST: {
      let tmp = action?.data?.email_list || []

      if (tmp && tmp?.length === 0) {
        tmp = state.update_helpers?.email_list?.map(({ value, label }) => {
          return {
            value,
            label: `${label} (N/A)`
          }
        })

        return {
          ...state,
          exhaustedEmailList: true,
          form: {
            ...state.form,
            email_list: tmp
          }
        }
      }

      return {
        ...state,
        exhaustedEmailList: false,
        form: {
          ...state.form,
          email_list: tmp
        }
      }
    }

    case types.SET_CAMPAIGN_UPDATE_DELETING: {
      return {
        ...state,
        deleting: action.deleting
      }
    }

    default:
      return state
  }
}

export default campaignUpdateReducer
