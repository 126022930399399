import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { CircleCheckIcon, WarningSolidIcon } from 'components/common/icons'

const POSITIONS = {
  'top-left': 'top-4 left-4',
  'top-center': 'top-4 left-1/2 transform -translate-x-1/2',
  'top-right': 'top-4 right-4',
  'bottom-left': 'bottom-4 left-4',
  'bottom-center': 'bottom-4 left-1/2 transform -translate-x-1/2',
  'bottom-right': 'bottom-4 right-4'
}

const ICONS = {
  success: <CircleCheckIcon className="w-6 h-6 text-success-default" />,
  error: <WarningSolidIcon className="w-6 h-6 text-error-default" />
}

const Toastr = ({
  position,
  duration,
  onClick,
  showClose,
  type,
  children,
  classes
}) => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    setIsVisible(true)
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, duration)

    return () => clearTimeout(timer)
  }, [children, type, position, duration, onClick, showClose])

  if (!isVisible) {
    return null
  }

  return createPortal(
    <div
      onClick={onClick}
      className={`fixed ${POSITIONS[position]} flex items-center justify-center bg-gray-700 text-white text-base leading-6 tracking-0.2 p-4 mb-5 rounded-lg shadow-1 z-[9999] min-w-min w-semi-full md:w-auto h-auto ${classes}`}
    >
      {type && ICONS[type] && <span className="mr-2">{ICONS[type]}</span>}
      {children}
      {showClose && (
        <button onClick={() => setIsVisible(false)} className="ml-4">
          X
        </button>
      )}
    </div>,
    document.getElementById('portal')
  )
}

Toastr.propTypes = {
  position: PropTypes.oneOf(Object.keys(POSITIONS)),
  duration: PropTypes.number,
  onClick: PropTypes.func,
  showClose: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(ICONS)),
  children: PropTypes.node.isRequired,
  classes: PropTypes.string
}

Toastr.defaultProps = {
  position: 'bottom-center',
  duration: 3000,
  onClick: () => {},
  showClose: false,
  type: null,
  classes: ''
}

export default Toastr
