import types from './types'

const sagaGetEmailSubjects = (payload) => {
  return {
    type: types.SAGA_GET_EMAIL_SUBJECTS,
    payload
  }
}

const sagaPostEmailSubjects = (payload, callback) => {
  return {
    type: types.SAGA_POST_EMAIL_SUBJECTS,
    payload,
    callback
  }
}

const sagaPutEmailSubjects = (payload, callback) => {
  return {
    type: types.SAGA_PUT_EMAIL_SUBJECTS,
    payload,
    callback
  }
}

const sagaPatchEmailSubjects = (payload, callback) => {
  return {
    type: types.SAGA_PATCH_EMAIL_SUBJECTS,
    payload,
    callback
  }
}

const setEmailSubjects = (payload) => {
  return {
    type: types.SET_EMAIL_SUBJECTS,
    payload
  }
}

const updateEmailSubjectsForm = (key, value) => {
  return {
    type: types.UPDATE_EMAIL_SUBJECTS_FORM,
    key,
    value
  }
}

const setEmailSubjectsModalOpen = (open) => {
  return {
    type: types.SET_EMAIL_SUBJECTS_MODAL_OPEN,
    open
  }
}

const setEmailSubjectsIsLoading = (loading = false) => {
  return {
    type: types.SET_EMAIL_SUBJECTS_IS_LOADING,
    loading
  }
}

const emailSubjectsActions = {
  sagaGetEmailSubjects,
  sagaPostEmailSubjects,
  sagaPutEmailSubjects,
  sagaPatchEmailSubjects,
  setEmailSubjects,
  setEmailSubjectsIsLoading,
  setEmailSubjectsModalOpen,
  updateEmailSubjectsForm
}

export default emailSubjectsActions
