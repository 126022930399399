import types from './types'

const sagaGetDocumentation = (payload = {}) => {
  return {
    type: types.SAGA_GET_DOCUMENTATION,
    payload
  }
}

const sagaGetDocumentationByCategory = (payload = {}) => {
  return {
    type: types.SAGA_GET_DOCUMENTATION_BY_CATEGORY,
    payload
  }
}

const sagaGetDocumentationVersionsById = (payload = {}) => {
  return {
    type: types.SAGA_GET_DOCUMENTATION_VERSIONS_BY_ID,
    payload
  }
}

const sagaPostDocumentation = (payload = {}, callback = undefined) => {
  return {
    type: types.SAGA_POST_DOCUMENTATION,
    payload,
    callback
  }
}

const sagaPutDocumentation = (payload = {}, callback = undefined) => {
  return {
    type: types.SAGA_PUT_DOCUMENTATION,
    payload,
    callback
  }
}

const sagaDownloadDocumentation = (id, name = null) => {
  return {
    type: types.SAGA_DOWNLOAD_DOCUMENTATION,
    id,
    name
  }
}

const setDocumentation = (payload = {}) => {
  return {
    type: types.SET_DOCUMENTATION,
    payload
  }
}

const setDocumentationByCategory = (payload = {}) => {
  return {
    type: types.SET_DOCUMENTATION_BY_CATEGORY,
    payload
  }
}

const setDocumentationVersionsById = (payload = {}) => {
  return {
    type: types.SET_DOCUMENTATION_VERSIONS_BY_ID,
    payload
  }
}

const setDocumentLoading = (loading = false) => {
  return {
    type: types.SET_DOCUMENTATION_LOADING,
    loading
  }
}

const setDocumentDownloading = (downloading) => {
  return {
    type: types.SET_DOCUMENTATION_DOWNLOADING,
    downloading
  }
}

const setDocumentationForm = (form) => {
  return {
    type: types.SET_DOCUMENTATION_FORM,
    form
  }
}

const updateDocumentationForm = (key, value) => {
  return {
    type: types.UPDATE_DOCUMENTATION_FORM,
    key,
    value
  }
}

const resetDocumentationForm = () => {
  return {
    type: types.RESET_DOCUMENTATION_FORM
  }
}

const setDocumentationReload = (reload = false) => {
  return {
    type: types.SET_DOCUMENTATION_RELOAD,
    reload
  }
}

const documentationActions = {
  sagaGetDocumentation,
  sagaGetDocumentationByCategory,
  sagaGetDocumentationVersionsById,
  sagaPostDocumentation,
  sagaPutDocumentation,
  sagaDownloadDocumentation,
  setDocumentation,
  setDocumentationByCategory,
  setDocumentationVersionsById,
  setDocumentLoading,
  setDocumentDownloading,
  setDocumentationForm,
  updateDocumentationForm,
  resetDocumentationForm,
  setDocumentationReload
}

export default documentationActions
