import './App.css'
import { Provider as ReduxProvider } from 'react-redux'
import store, { persistor } from 'store'
import { PersistGate } from 'redux-persist/integration/react'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from 'components/common/ErrorFallback'
import useTabSession from 'hooks/useTabSession'
import { useEffect, useState } from 'react'
import { removeImpersonatedUser } from 'util/impersonation'
import ReduxToastr from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { CookiesProvider } from 'react-cookie'
import Routes from './Routes'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastrProvider } from './contexts/toastr-context'
import { initDatadog } from './scripts/datadog'
import useScript from 'react-script-hook'
import { filterVarBoolean } from './util/filters'
import SegmentContext from './services/SegmentContext'
import analytics from './services/Segment'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
})

function App() {
  const gorgiasChat = filterVarBoolean(process.env.REACT_APP_GORGIAS_CHAT_ENABLED) ? 'https://config.gorgias.chat/bundle-loader/01J3NXWBV3PDMA6TXAA9N6AFDS' : ''
  const [isDatadogLoaded, setIsDatadogLoaded] = useState(false)
  const [loading, error] = useScript({
      src: gorgiasChat,
      checkForExisting: true,
    })

  useTabSession()

  useEffect(() => {
    if (!isDatadogLoaded) {
      initDatadog()
      setIsDatadogLoaded(true)
    }

    // if and admin has several duplicate tabs open from same user and then clicks
    // "Back To Admin" button will reload all tabs where "tabId"(in sessionStorage) is same the
    // to return to the admin page.
    const storage = (e) => {
      const { key, oldValue, newValue } = e
      const tabId = key?.replace('cuddly_user_', '') ?? false

      if (
        tabId &&
        sessionStorage.getItem('tabId') === tabId &&
        newValue !== oldValue
      ) {
        window.location.reload()
      }
    }
    window.addEventListener('storage', storage)

    return () => {
      removeImpersonatedUser()
      persistor.purge()
      window.removeEventListener('storage', storage)
    }
  }, [])

  useEffect(() => {
    if (error) {
      console.error('Error loading Gorgias Chat Widget')
    }

    if (loading) {
      console.log('Loading Gorgias Chat Widget')
    }
  }, [loading, error]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <CookiesProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <ToastrProvider>
                <SegmentContext.Provider value={analytics}>
                  <div className="font-sans text-gray-900 antialiased">
                    <Routes />
                    <ReduxToastr
                      timeOut={4000}
                      newestOnTop={false}
                      preventDuplicates
                      getState={(state) => state.toastr} // This is the default
                      transitionIn="fadeIn"
                      transitionOut="fadeOut"
                      closeOnToastrClick
                      position="bottom-center"
                      showCloseButton
                    />
                  </div>
                </SegmentContext.Provider>
              </ToastrProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </PersistGate>
        </ReduxProvider>
      </CookiesProvider>
    </ErrorBoundary>
  )
}

export default App
