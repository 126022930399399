import { call, put, takeLatest, all } from 'redux-saga/effects'
import types from './types'
import actions from './actions'
import axios from 'axios'
import { generalActions } from 'store/general'
import errorParser from 'util/laravel_error_parser'

const URL = 'api/admin/emails/subjects'

// Axios calls
const getEmailSubjects = async ({ payload }) => {
  return await axios.get(URL, { params: payload })
}

const postEmailSubjects = async (data) => {
  return await axios.post(URL, data)
}

const putEmailSubjects = async (id, data) => {
  return await axios.put(`${URL}/${id}`, data)
}

const patchEmailSubjects = async (id, data) => {
  return await axios.patch(`${URL}/${id}`, data)
}

// Doers
function* doGetEmailSubjects(payload) {
  try {
    yield put(actions.setEmailSubjectsIsLoading(true))
    const response = yield call(getEmailSubjects, payload)
    yield put(actions.setEmailSubjects(response.data))
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setEmailSubjectsIsLoading())
  }
}

function* doPostEmailSubjects(action) {
  try {
    yield put(actions.setEmailSubjectsIsLoading(true))
    const response = yield call(postEmailSubjects, action.payload)
    yield put(generalActions.setSuccess(response.data.message))

    if (action?.callback) {
      action.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setEmailSubjectsIsLoading())
  }
}

function* doPutEmailSubjects(action) {
  try {
    yield put(actions.setEmailSubjectsIsLoading(true))
    const { payload } = action
    const response = yield call(putEmailSubjects, payload.id, payload)
    yield put(generalActions.setSuccess(response.data.message))

    if (action?.callback) {
      action.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setEmailSubjectsIsLoading())
  }
}

function* doPatchEmailSubjects(action) {
  try {
    const { payload } = action
    const response = yield call(patchEmailSubjects, payload.id, payload)
    yield put(generalActions.setSuccess(response.data.message))

    if (action?.callback) {
      action.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  }
}

// Watchers
function* watchGetEmailSubjects() {
  yield takeLatest(types.SAGA_GET_EMAIL_SUBJECTS, doGetEmailSubjects)
}

function* watchPostEmailSubjects() {
  yield takeLatest(types.SAGA_POST_EMAIL_SUBJECTS, doPostEmailSubjects)
}

function* watchPutEmailSubjects() {
  yield takeLatest(types.SAGA_PUT_EMAIL_SUBJECTS, doPutEmailSubjects)
}

function* watchPatchEmailSubjects() {
  yield takeLatest(types.SAGA_PATCH_EMAIL_SUBJECTS, doPatchEmailSubjects)
}

export default function* rootSaga() {
  yield all([
    watchGetEmailSubjects(),
    watchPostEmailSubjects(),
    watchPutEmailSubjects(),
    watchPatchEmailSubjects()
  ])
}
