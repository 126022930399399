import { call, put } from 'redux-saga/effects'
import errorPaser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { putFixLimbo } from './api'
import actions from '../actions'
import { toastr } from 'react-redux-toastr'

function* doPutFixLimbo(action) {
  try {
    yield put(actions.setLoadingSkus(true))
    const response = yield call(putFixLimbo, action)
    toastr.success(
      '',
      response?.data?.message || 'Order Items updated successfully.'
    )
    if (action.callback) {
      action.callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(actions.setLoadingSkus(false))
  }
}

export default doPutFixLimbo
