export const filterVarBoolean = (value) => {
  if (typeof value === 'boolean') {
    return value
  }

  const truthyValues = ['true', 'yes', '1']
  const falsyValues = ['false', 'no', '0']

  if (typeof value === 'string') {
    const lowerValue = value.toLowerCase()

    if (truthyValues.includes(lowerValue)) {
      return true
    }

    if (falsyValues.includes(lowerValue)) {
      return false
    }
  }

  if (typeof value === 'number') {
    return value === 1
  }

  return false
}

export const isEmpty = (value) => {
  if (value == null) {
    return true
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return typeof value === 'object' && Object.keys(value).length === 0
}
