import types from './types'

const INITIAL_STATE = {
  categories: [],
  documentation: [],
  documentationByCategory: [],
  documentationVersionsById: [],
  loading: false,
  downloading: false,
  reload: false,
  form: {
    name: '',
    category: '',
    file: null,
    readOnly: false
  }
}

const buildCategoriesOptions = (categories) => {
  categories = categories.map(({ name, slug }) => {
    return {
      label: name,
      value: slug?.toLowerCase()
    }
  })
  categories = [{ label: 'Select Category', value: '' }, ...categories]

  return categories
}

const documentationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_DOCUMENTATION: {
      let { categories, ...rest } = action.payload
      categories = buildCategoriesOptions(categories || [])

      return {
        ...state,
        documentation: rest,
        categories
      }
    }

    case types.SET_DOCUMENTATION_BY_CATEGORY: {
      let { categories, ...rest } = action.payload
      categories = buildCategoriesOptions(categories || [])

      return {
        ...state,
        documentationByCategory: rest,
        categories
      }
    }

    case types.SET_DOCUMENTATION_VERSIONS_BY_ID: {
      return {
        ...state,
        documentationVersionsById: action.payload
      }
    }

    case types.SET_DOCUMENTATION_LOADING: {
      return {
        ...state,
        loading: action.loading
      }
    }

    case types.SET_DOCUMENTATION_DOWNLOADING: {
      return {
        ...state,
        downloading: action.downloading
      }
    }

    case types.SET_DOCUMENTATION_FORM: {
      const form = { ...state.form, ...action.form }

      return {
        ...state,
        form
      }
    }

    case types.UPDATE_DOCUMENTATION_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          [action.key]: action.value
        }
      }
    }

    case types.RESET_DOCUMENTATION_FORM: {
      return {
        ...state,
        form: {
          name: '',
          category: '',
          file: null
        }
      }
    }

    case types.SET_DOCUMENTATION_RELOAD: {
      return {
        ...state,
        reload: action.reload
      }
    }

    default:
      return state
  }
}

export default documentationReducer
