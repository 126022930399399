import types from './types'

const sagaGetPantryWishlistDonations = (payload) => {
  return {
    type: types.SAGA_GET_PANTRY_WISHLIST_DONATIONS,
    payload
  }
}

const sagaGetPantryWishlistDonationDetails = (payload) => {
  return {
    type: types.SAGA_GET_PANTRY_WISHLIST_DONATION_DETAILS,
    payload
  }
}

const sagaGetPantryWishlistDonationsReport = () => {
  return {
    type: types.SAGA_GET_PANTRY_WISHLIST_DONATIONS_REPORT
  }
}

const sagaGetPantryFullBowlsClubReport = (payload) => {
  return {
    type: types.SAGA_GET_FULL_BOWLS_CLUB_REPORT,
    payload
  }
}

const sagaGetPantryDonorsReport = () => {
  return {
    type: types.SAGA_GET_PANTRY_DONORS_REPORT
  }
}

const setPaginator = (paginator) => {
  return {
    type: types.SET_PAGINATOR,
    paginator
  }
}

const setCurrent = (current) => {
  return {
    type: types.SET_CURRENT,
    current
  }
}

const setDetails = (payload) => {
  return {
    type: types.SET_DETAILS,
    payload
  }
}

const setFullBowlDetails = (payload) => {
  return {
    type: types.SET_FULL_BOWL_DETAIL,
    payload
  }
}

const setLoading = (loading) => {
  return {
    type: types.SET_LOADING,
    payload: loading
  }
}

const setDownloading = (downloading) => {
  return {
    type: types.SET_DOWNLOADING,
    payload: downloading
  }
}

const setDownloadingDonors = (downloading) => {
  return {
    type: types.SET_DOWNLOADING_DONORS,
    payload: downloading
  }
}

export default {
  sagaGetPantryWishlistDonations,
  sagaGetPantryWishlistDonationDetails,
  sagaGetPantryWishlistDonationsReport,
  sagaGetPantryFullBowlsClubReport,
  sagaGetPantryDonorsReport,
  setPaginator,
  setCurrent,
  setDetails,
  setFullBowlDetails,
  setLoading,
  setDownloading,
  setDownloadingDonors
}
