import types from './types'

const initialState = {
  campaignEmailForm: {
    to: '',
    subject: '',
    description_plain: '',
    scheduleDate: new Date().setDate(new Date().getDate() + 1),
    type: 1,
    template: '',
    template_preview: '',
    schedule_hour: '',
    pet: {},
    email_list: [],
    templates: [],
    subjects: [],
    exhausted_email_list: false
  }
}

const campaignEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CAMPAIGN_EMAIL_FORM: {
      let text = action.data?.pet?.description_plain?.replace(/&nbsp;/g, ' ')

      if (text?.length > 250) {
        text = text.slice(0, 250)
      }

      return {
        campaignEmailForm: {
          ...state.campaignEmailForm,
          ...action.data,
          description_plain: text
        }
      }
    }

    case types.UPDATE_CAMPAIGN_EMAIL_FORM: {
      const { key, value } = action

      return {
        campaignEmailForm: {
          ...state.campaignEmailForm,
          [key]: value
        }
      }
    }

    case types.RESET_CAMPAIGN_EMAIL_FORM: {
      return {
        campaignEmailForm: {
          ...initialState.campaignEmailForm
        }
      }
    }

    case types.EXHAUSTED_CAMPAIGN_EMAIL_LIST: {
      return {
        campaignEmailForm: {
          ...state.campaignEmailForm,
          exhausted_email_list: action.fetching
        }
      }
    }

    case types.UPDATE_CAMPAIGN_EMAIL_LIST: {
      let tmp = action?.data?.email_list || []

      if (tmp && tmp?.length === 0) {
        tmp = state.campaignEmailForm?.email_list?.map(({ value, label }) => {
          return {
            value,
            label: `${label} (N/A)`
          }
        })

        return {
          campaignEmailForm: {
            ...state.campaignEmailForm,
            email_list: tmp,
            exhausted_email_list: false
          }
        }
      }

      return {
        campaignEmailForm: {
          ...state.campaignEmailForm,
          email_list: action?.data?.email_list || [],
          exhausted_email_list: false
        }
      }
    }

    default:
      return state
  }
}

export default campaignEmailReducer
