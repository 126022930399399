// For Admin
const SAGA_SHELTER_INDEX = 'SAGA_SHELTER_INDEX'
const SET_PAGINATOR = 'SET_PAGINATOR'
const SET_CURRENT = 'SET_CURRENT'
const SAGA_SWITCH_SHELTER = 'SAGA_SWITCH_SHELTER'
const SAGA_SWITCH_SHELTER_BACK = 'SAGA_SWITCH_SHELTER_BACK'
const SWITCH_SHELTER_REFRESH = 'SWITCH_SHELTER_REFRESH'
const SAGA_ASSIGN_FC = 'SAGA_ASSIGN_FC'
const SAGA_CHANGE_SAR_STATUS = 'SAGA_CHANGE_SAR_STATUS'
const SAGA_DELETE_PAYPAL_ACCOUNT_AS_ADMIN =
  'SAGA_DELETE_PAYPAL_ACCOUNT_AS_ADMIN'

// For Shelters
const SAGA_SHELTER_STATS = 'SAGA_SHELTER_STATS'
const SAGA_SHELTER_CAMPAIGNS = 'SAGA_SHELTER_CAMPAIGNS'
const SET_SHELTER_STATS = 'SET_SHELTER_STATS'
const SET_SHELTER_CAMPAIGNS = 'SET_SHELTER_CAMPAIGNS'
const SET_LOADING_SHELTER_CAMPAIGNS = 'SET_LOADING_SHELTER_CAMPAIGNS'
const SAGA_SHELTER_ACCOUNTS = 'SAGA_SHELTER_ACCOUNTS'
const SET_STRIPE_ACCOUNT = 'SET_STRIPE_ACCOUNT'
const SET_STRIPE_FORM = 'SET_STRIPE_FORM'
const UPDATE_STRIPE_FORM = 'UPDATE_STRIPE_FORM'
const SAGA_CREATE_PAYPAL = 'SAGA_CREATE_PAYPAL'
const SAGA_DELETE_PAYPAL_ACCOUNT = 'SAGA_DELETE_PAYPAL_ACCOUNT'
const SAGA_GET_STRIPE_STATUS = 'SAGA_GET_STRIPE_STATUS'
const SAGA_GET_VET_INFO = 'SAGA_GET_VET_INFO'
const SET_STRIPE_STATUS = 'SET_STRIPE_STATUS'
const SAGA_GET_SIGNED_AGENCY_AGREEMENT = 'SAGA_GET_SIGNED_AGENCY_AGREEMENT'
const SAGA_GET_SIGNED_PANTRY_AGREEMENT = 'SAGA_GET_SIGNED_PANTRY_AGREEMENT'
const SET_SIGNED_AGENCY_AGREEMENT = 'SET_SIGNED_AGENCY_AGREEMENT'
const SET_SIGNED_PANTRY_AGREEMENT = 'SET_SIGNED_PANTRY_AGREEMENT'
const RESET_CAMPAIGN_TABS = 'RESET_CAMPAIGN_TABS'
const SET_VET_INFO = 'SET_VET_INFO'
const SAGA_SAVE_VET_INFO = 'SAGA_SAVE_VET_INFO'
const SAGA_POST_FUNDRAISING_AGREEMENT_ADDENDUM =
  'SAGA_POST_FUNDRAISING_AGREEMENT_ADDENDUM'
const SAGA_GET_PAYPAL_REFERRAL_URL = 'SAGA_GET_PAYPAL_REFERRAL_URL'

export default {
  SAGA_SHELTER_INDEX,
  SET_PAGINATOR,
  SET_CURRENT,
  SAGA_SWITCH_SHELTER,
  SAGA_ASSIGN_FC,
  SAGA_CHANGE_SAR_STATUS,
  SAGA_SWITCH_SHELTER_BACK,
  SAGA_DELETE_PAYPAL_ACCOUNT,
  SWITCH_SHELTER_REFRESH,
  SAGA_SHELTER_STATS,
  SAGA_SHELTER_CAMPAIGNS,
  SET_SHELTER_STATS,
  SET_SHELTER_CAMPAIGNS,
  SET_LOADING_SHELTER_CAMPAIGNS,
  SAGA_SHELTER_ACCOUNTS,
  SET_STRIPE_ACCOUNT,
  SET_VET_INFO,
  SAGA_SAVE_VET_INFO,
  SAGA_CREATE_PAYPAL,
  UPDATE_STRIPE_FORM,
  SET_STRIPE_FORM,
  SAGA_GET_STRIPE_STATUS,
  SAGA_GET_VET_INFO,
  SET_STRIPE_STATUS,
  SAGA_GET_SIGNED_AGENCY_AGREEMENT,
  SAGA_GET_SIGNED_PANTRY_AGREEMENT,
  SET_SIGNED_AGENCY_AGREEMENT,
  SET_SIGNED_PANTRY_AGREEMENT,
  RESET_CAMPAIGN_TABS,
  SAGA_POST_FUNDRAISING_AGREEMENT_ADDENDUM,
  SAGA_GET_PAYPAL_REFERRAL_URL,
  SAGA_DELETE_PAYPAL_ACCOUNT_AS_ADMIN
}
