import PropTypes from 'prop-types'
import { createContext, useReducer, useContext } from 'react'

const SET_WISH_LIST_PRODUCT_PAGE = 'SET_WISH_LIST_PRODUCT_PAGE'
const SET_WISH_LIST_PRODUCTS = 'SET_WISH_LIST_PRODUCTS'
const SET_WISH_LIST_CATEGORIES = 'SET_WISH_LIST_CATEGORIES'
const SET_WISH_LIST_ITEMS = 'SET_WISH_LIST_ITEMS'
const SET_WISH_LIST_CATEGORY = 'SET_WISH_LIST_CATEGORY'
const SET_WISH_LIST_PRODUCT = 'SET_WISH_LIST_PRODUCT'
const SET_WISH_LIST_IS_FETCHING_PRODUCTS = 'SET_WISH_LIST_IS_FETCHING_PRODUCTS'
const SET_WISH_LIST_RECOMMENDATIONS = 'SET_WISH_LIST_RECOMMENDATIONS'
const SET_WISH_LIST_RECOMMENDATION_PAGE = 'SET_WISH_LIST_RECOMMENDATION_PAGE'
const SET_WISH_LIST_IS_FETCHING_RECOMMENDATIONS =
  'SET_WISH_LIST_IS_FETCHING_RECOMMENDATIONS'
const RESET_WISH_LIST_RECOMMENDATIONS = 'RESET_WISH_LIST_RECOMMENDATIONS'
const SET_WISH_LIST_PRODUCT_AS_FAVORITE = 'SET_WISH_LIST_PRODUCT_AS_FAVORITE'
const SET_WISH_LIST_REFRESH_ITEMS = 'SET_WISH_LIST_REFRESH_ITEMS'
const SET_WISH_LIST_BRANDS = 'SET_WISH_LIST_BRANDS'
const SET_WISH_LIST_RECOMMENDED_ITEM_AS_FAVORITE =
  'SET_WISH_LIST_RECOMMENDED_ITEM_AS_FAVORITE'
const SET_WISH_LIST_ITEM_AS_FAVORITE = 'SET_WISH_LIST_ITEM_AS_FAVORITE'
const SET_WISH_LIST_SEARCH = 'SET_WISH_LIST_SEARCH'
const SET_WISH_LIST_SEARCH_RESULTS = 'SET_WISH_LIST_SEARCH_RESULTS'
const SET_WISH_LIST_SEARCH_RESULTS_PAGE = 'SET_WISH_LIST_SEARCH_RESULTS_PAGE'
const SET_WISH_LIST_IS_FETCHING_SEARCH_RESULTS =
  'SET_WISH_LIST_IS_FETCHING_SEARCH_RESULTS'
const RESET_WISH_LIST_SEARCH_RESULTS = 'RESET_WISH_LIST_SEARCH_RESULTS'
const SET_WISH_LIST_IS_SEARCHING = 'SET_WISH_LIST_IS_SEARCHING'
const SET_WISH_LIST_ONLY_WAREHOUSE = 'SET_WISH_LIST_ONLY_WAREHOUSE'
const SET_WISH_LIST_ONLY_WAREHOUSE_FOR_SEARCH =
  'SET_WISH_LIST_ONLY_WAREHOUSE_FOR_SEARCH'
const SET_WISH_LIST_SPECIAL_ADMIN_FILTER = 'SET_WISH_LIST_SPECIAL_ADMIN_FILTER'
const SET_WISH_LIST_SPECIAL_ADMIN_FILTER_FOR_SEARCH =
  'SET_WISH_LIST_SPECIAL_ADMIN_FILTER_FOR_SEARCH'
const SET_WISH_LIST_SHOW_TOOLTIP = 'SET_WISH_LIST_SHOW_TOOLTIP'
const SET_WISH_LIST_IS_UPDATING = 'SET_WISH_LIST_IS_UPDATING'
const SET_WISH_LIST_PENDING_RECOMMENDATIONS =
  'SET_WISH_LIST_PENDING_RECOMMENDATIONS'
const WIPE_MOST_NEEDED_ITEMS_ERROR = 'WIPE_MOST_NEEDED_ITEMS_ERROR'
const ADD_WISH_LIST_ITEM = 'ADD_WISH_LIST_ITEM'
const UPDATE_WISH_LIST_ITEM = 'UPDATE_WISH_LIST_ITEM'
const REMOVE_WISH_LIST_ITEM = 'REMOVE_WISH_LIST_ITEM'
const SET_SHOW_MOST_NEEDED_ITEMS_ERROR = 'SET_SHOW_MOST_NEEDED_ITEMS_ERROR'

const INITIAL_STATE = {
  productPage: 1,
  products: [],
  categories: [],
  items: [],
  category: '',
  isFetchingProducts: false,
  hasMoreProducts: false,
  product: null,
  isFetchingRecommendations: false,
  recommendations: [],
  recommendationPage: 1,
  hasMoreRecommendations: false,
  totalRecommendations: 0,
  refreshWishListItems: true,
  brands: [],
  results: [],
  resultPage: 1,
  totalResults: 0,
  isFetchingResults: false,
  hasMoreResults: false,
  search: '',
  isSearching: false,
  onlyWarehouse: false,
  avoidSpeciesFilter: false,
  onlyWarehouseForSearch: false,
  onlySpecialFilterForSearch: false,
  showCustomTooltip: false,
  isUpdating: false,
  pendingRecommendedItems: 0,
  wipeMostNeededErrors: false,
  unsavedWishListChanges: false,
  showMostNeededItemsError: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case SET_WISH_LIST_PRODUCT_PAGE:
      return {
        ...state,
        productPage: state.hasMoreProducts ? action.payload : state.productPage
      }
    case SET_WISH_LIST_PRODUCTS: {
      const {
        data = [],
        current_page: currentPage = 1,
        last_page: lastPage = 1
      } = action.payload
      const products = [...new Set([...state.products, ...data])]

      return {
        ...state,
        products,
        hasMoreProducts: currentPage < lastPage
      }
    }
    case SET_WISH_LIST_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      }
    case SET_WISH_LIST_ITEMS:
      return {
        ...state,
        items: action.payload
      }
    case SET_WISH_LIST_CATEGORY:
      return {
        ...state,
        category: action.payload,
        productPage: 1,
        products: []
      }
    case SET_WISH_LIST_PRODUCT:
      return {
        ...state,
        product: action.payload
      }
    case SET_WISH_LIST_IS_FETCHING_PRODUCTS:
      return {
        ...state,
        isFetchingProducts: action.payload
      }
    case SET_WISH_LIST_RECOMMENDATIONS: {
      const {
        data = [],
        total,
        current_page: currentPage,
        last_page: lastPage
      } = action.payload
      const tmp = state.recommendations || []
      const recommendations = [...new Set([...tmp, ...data])]

      return {
        ...state,
        recommendations,
        totalRecommendations: total,
        hasMoreRecommendations: currentPage < lastPage
      }
    }
    case SET_WISH_LIST_RECOMMENDATION_PAGE:
      return {
        ...state,
        recommendationPage: state.hasMoreRecommendations
          ? action.payload
          : state.recommendationPage
      }
    case SET_WISH_LIST_IS_FETCHING_RECOMMENDATIONS:
      return {
        ...state,
        isFetchingRecommendations: action.payload
      }
    case RESET_WISH_LIST_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: [],
        recommendationPage: 1,
        totalRecommendations: 0
      }
    case SET_WISH_LIST_PRODUCT_AS_FAVORITE: {
      const { id, isFavorite } = action.payload
      const index = state.products.findIndex((product) => product.id === id)

      if (index !== -1) {
        const updatedProducts = [...state.products]
        updatedProducts[index] = { ...updatedProducts[index], isFavorite }

        return {
          ...state,
          products: updatedProducts,
          product: state.product ? { ...state.product, isFavorite } : null
        }
      }

      return state
    }
    case SET_WISH_LIST_RECOMMENDED_ITEM_AS_FAVORITE: {
      const { id, isFavorite } = action.payload
      const index = state.recommendations.findIndex(
        (product) => product.id === id
      )

      if (index !== -1) {
        const updatedRecommendations = [...state.recommendations]
        updatedRecommendations[index] = {
          ...updatedRecommendations[index],
          isFavorite
        }

        return {
          ...state,
          recommendations: updatedRecommendations,
          product: state.product ? { ...state.product, isFavorite } : null
        }
      }

      return state
    }
    case SET_WISH_LIST_ITEM_AS_FAVORITE: {
      const { id, isFavorite } = action.payload
      const index = state.items.findIndex((product) => product.id === id)

      if (index !== -1) {
        const updatedItems = [...state.items]
        updatedItems[index] = {
          ...updatedItems[index],
          isFavorite
        }

        return {
          ...state,
          items: updatedItems,
          product: state.product ? { ...state.product, isFavorite } : null
        }
      }

      return state
    }
    case SET_WISH_LIST_REFRESH_ITEMS:
      return {
        ...state,
        refreshWishListItems: action.payload
      }
    case SET_WISH_LIST_BRANDS:
      return {
        ...state,
        brands: action.payload
      }
    case SET_WISH_LIST_SEARCH:
      return {
        ...state,
        search: action.payload
      }
    case SET_WISH_LIST_SEARCH_RESULTS_PAGE:
      return {
        ...state,
        resultPage: state.hasMoreResults ? action.payload : state.resultPage
      }
    case SET_WISH_LIST_SEARCH_RESULTS: {
      const {
        data = [],
        current_page: currentPage = 1,
        last_page: lastPage = 1,
        total
      } = action.payload
      const results = [...new Set([...state.results, ...data])]

      return {
        ...state,
        results,
        hasMoreResults: currentPage < lastPage,
        totalResults: total
      }
    }
    case SET_WISH_LIST_IS_FETCHING_SEARCH_RESULTS:
      return {
        ...state,
        isFetchingResults: action.payload
      }
    case RESET_WISH_LIST_SEARCH_RESULTS:
      return {
        ...state,
        results: [],
        resultPage: 1,
        totalResults: 0
      }
    case SET_WISH_LIST_IS_SEARCHING:
      return {
        ...state,
        isSearching: action.payload
      }
    case SET_WISH_LIST_ONLY_WAREHOUSE:
      return {
        ...state,
        onlyWarehouse: action.payload
      }
    case SET_WISH_LIST_SPECIAL_ADMIN_FILTER:
      return {
        ...state,
        avoidSpeciesFilter: action.payload
      }
    case SET_WISH_LIST_SHOW_TOOLTIP:
      return {
        ...state,
        showCustomTooltip: action.payload
      }
    case SET_WISH_LIST_ONLY_WAREHOUSE_FOR_SEARCH:
      return {
        ...state,
        onlyWarehouseForSearch: action.payload
      }
    case SET_WISH_LIST_SPECIAL_ADMIN_FILTER_FOR_SEARCH:
      return {
        ...state,
        onlySpecialFilterForSearch: action.payload
      }
    case SET_WISH_LIST_IS_UPDATING:
      return {
        ...state,
        isUpdating: action.payload
      }
    case SET_WISH_LIST_PENDING_RECOMMENDATIONS:
      return {
        ...state,
        pendingRecommendedItems: action.payload
      }
    case WIPE_MOST_NEEDED_ITEMS_ERROR:
      return {
        ...state,
        wipeMostNeededErrors: action.payload
      }
    case ADD_WISH_LIST_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
        unsavedWishListChanges: true,
        showMostNeededItemsError: false
      }
    case REMOVE_WISH_LIST_ITEM: {
      const { productId, variantId } = action.payload
      const updatedItems = state.items.filter(
        (item) => item.productId !== productId || item.variantId !== variantId
      )

      return {
        ...state,
        items: updatedItems,
        unsavedWishListChanges: true,
        showMostNeededItemsError: false
      }
    }
    case UPDATE_WISH_LIST_ITEM: {
      const { productId, variantId, quantity, isMostNeeded, isUnlimited } =
        action.payload
      const index = state.items.findIndex(
        (item) => item.productId === productId && item.variantId === variantId
      )

      const updatedItems = [...state.items]

      if (quantity === 0) {
        updatedItems.splice(index, 1)
      } else {
        updatedItems[index] = {
          ...updatedItems[index],
          quantity,
          isMostNeeded,
          isUnlimited
        }
      }

      return {
        ...state,
        items: updatedItems,
        unsavedWishListChanges: true,
        showMostNeededItemsError: false
      }
    }
    case SET_SHOW_MOST_NEEDED_ITEMS_ERROR:
      return {
        ...state,
        showMostNeededItemsError: action.payload
      }
    default:
      return state
  }
}

const WishListContext = createContext(null)

const WishListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const hasAtLeastThreeMostNeededItems =
    state.items.filter((item) => item.isMostNeeded).length >= 3

  return (
    <WishListContext.Provider
      value={{ state, dispatch, hasAtLeastThreeMostNeededItems }}
    >
      {children}
    </WishListContext.Provider>
  )
}

WishListProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const useWishList = () => {
  const context = useContext(WishListContext)

  if (context === undefined) {
    throw new Error('useWishList must be used within a WishListProvider')
  }

  return context
}

export {
  WishListProvider,
  useWishList,
  SET_WISH_LIST_PRODUCT_PAGE,
  SET_WISH_LIST_PRODUCTS,
  SET_WISH_LIST_CATEGORIES,
  SET_WISH_LIST_ITEMS,
  SET_WISH_LIST_CATEGORY,
  SET_WISH_LIST_PRODUCT,
  SET_WISH_LIST_IS_FETCHING_PRODUCTS,
  SET_WISH_LIST_RECOMMENDATIONS,
  SET_WISH_LIST_RECOMMENDATION_PAGE,
  SET_WISH_LIST_IS_FETCHING_RECOMMENDATIONS,
  RESET_WISH_LIST_RECOMMENDATIONS,
  SET_WISH_LIST_PRODUCT_AS_FAVORITE,
  SET_WISH_LIST_REFRESH_ITEMS,
  SET_WISH_LIST_BRANDS,
  SET_WISH_LIST_RECOMMENDED_ITEM_AS_FAVORITE,
  SET_WISH_LIST_ITEM_AS_FAVORITE,
  SET_WISH_LIST_SEARCH,
  SET_WISH_LIST_SEARCH_RESULTS,
  SET_WISH_LIST_SEARCH_RESULTS_PAGE,
  SET_WISH_LIST_IS_FETCHING_SEARCH_RESULTS,
  RESET_WISH_LIST_SEARCH_RESULTS,
  SET_WISH_LIST_IS_SEARCHING,
  SET_WISH_LIST_ONLY_WAREHOUSE,
  SET_WISH_LIST_SHOW_TOOLTIP,
  SET_WISH_LIST_ONLY_WAREHOUSE_FOR_SEARCH,
  SET_WISH_LIST_IS_UPDATING,
  SET_WISH_LIST_PENDING_RECOMMENDATIONS,
  WIPE_MOST_NEEDED_ITEMS_ERROR,
  SET_WISH_LIST_SPECIAL_ADMIN_FILTER_FOR_SEARCH,
  SET_WISH_LIST_SPECIAL_ADMIN_FILTER,
  ADD_WISH_LIST_ITEM,
  UPDATE_WISH_LIST_ITEM,
  REMOVE_WISH_LIST_ITEM,
  SET_SHOW_MOST_NEEDED_ITEMS_ERROR
}
