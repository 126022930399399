import types from './types'

const INITIAL_STATE = {
    paginator:  {},
    current:  {},
    refresh_to_dashboard: null,
    paginatorPerEmail: {}
}

const emailReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){

        case types.SET_PAGINATOR:{
            return {
                ...state,
                paginator: action.paginator
            }
        }

        case types.SET_CURRENT: {
            return {
                ...state,
                current: action.current
            }
        }

        case types.SWITCH_EMAIL_REFRESH:{
            return {
                ...state,
                refresh_to_dashboard: action.value
            }
        }

        case types.SET_PAGINATOR_PER_EMAIL:{
            return {
                ...state,
                paginatorPerEmail: action.paginatorPerEmail
            }
        }

        default: return state
    }
}

export default emailReducer;