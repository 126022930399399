import types from './types'

const sagaIndexSuccessStory = (
  payload = { params: undefined, isAdmin: false }
) => {
  return {
    type: types.SAGA_INDEX_SUCCESS_STORY,
    payload
  }
}

const sagaStoreSuccessStory = (form, callback) => {
  return {
    type: types.SAGA_STORE_SUCCESS_STORY,
    form,
    callback
  }
}

const sagaEditSuccessStory = (payload = { id: null, isAdmin: false }) => {
  return {
    type: types.SAGA_EDIT_SUCCESS_STORY,
    payload
  }
}

const sagaUpdateSuccessStory = (id, form, isAdmin, callback) => {
  return {
    type: types.SAGA_UPDATE_SUCCESS_STORY,
    id,
    form,
    isAdmin,
    callback
  }
}

const sagaUpdateSuccessStoryStatus = (id, status, callback) => {
  return {
    type: types.SAGA_UPDATE_SUCCESS_STORY_STATUS,
    id,
    status,
    callback
  }
}

const sagaGetSuccessStoryCampaigns = (userId) => {
  return {
    type: types.SAGA_GET_SUCCESS_STORY_CAMPAIGNS,
    userId
  }
}

const setSuccessStoryList = (payload) => {
  return {
    type: types.SET_SUCCESS_STORY_LIST,
    payload
  }
}

const resetSuccessStoryForm = () => {
  return {
    type: types.RESET_SUCCESS_STORY_FORM
  }
}

const updateSuccessStoryForm = (payload) => {
  return {
    type: types.UPDATE_SUCCESS_STORY_FORM,
    payload
  }
}

const setSuccessStoryCoverImage = (index) => {
  return {
    type: types.SET_SUCCESS_STORY_COVER_IMAGE,
    index
  }
}

const setSuccessStoryForm = (payload) => {
  return {
    type: types.SET_SUCCESS_STORY_FORM,
    payload
  }
}

const resetSuccessStoryList = () => {
  return {
    type: types.RESET_SUCCESS_STORY_LIST
  }
}

const updateSuccessStoryImages = (images) => {
  return {
    type: types.UPDATE_SUCCESS_STORY_IMAGES,
    images
  }
}

const removeSuccessStoryImage = (id) => {
  return {
    type: types.REMOVE_SUCCESS_STORY_IMAGE,
    id
  }
}

const sagaSuccessStoryGetCalculateEmailList = (id) => {
  return {
    type: types.SAGA_GET_SUCCESS_STORY_CALCULATE_EMAIL_LIST,
    id
  }
}

const sagaSuccessStoryGetSubjects = (id) => {
  return {
    type: types.SAGA_GET_SUCCESS_STORY_SUBJECTS,
    id
  }
}

const setExhaustedSuccessStoriesEmailList = (fetching) => {
  return {
    type: types.SET_EXHAUSTED_STORIES_EMAIL_LIST,
    fetching
  }
}

const updateEmailList = (data) => {
  return {
    type: types.UPDATE_STORIES_EMAIL_LIST,
    data
  }
}

const setSuccessStoryCampaigns = (campaigns) => {
  return {
    type: types.SET_SUCCESS_STORY_CAMPAIGNS,
    campaigns
  }
}

const resetSuccessStoryEmailList = () => {
  return {
    type: types.RESET_SUCCESS_STORY_EMAIL_LIST
  }
}

const setSuccessStorySubjects = (subjects) => {
  return {
    type: types.SET_SUCCESS_STORY_SUBJECTS,
    subjects
  }
}

const sagaGetSuccessStoryPreviewEmail = (callback, id) => ({
  type: types.SAGA_POST_PREVIEW_SUCCESS_STORY,
  callback,
  id
})

export default {
  sagaIndexSuccessStory,
  sagaStoreSuccessStory,
  sagaEditSuccessStory,
  sagaUpdateSuccessStory,
  sagaUpdateSuccessStoryStatus,
  sagaGetSuccessStoryCampaigns,
  setSuccessStoryList,
  resetSuccessStoryForm,
  updateSuccessStoryForm,
  setSuccessStoryCoverImage,
  setSuccessStoryForm,
  resetSuccessStoryList,
  updateSuccessStoryImages,
  removeSuccessStoryImage,
  setExhaustedSuccessStoriesEmailList,
  updateEmailList,
  sagaSuccessStoryGetCalculateEmailList,
  sagaGetSuccessStoryPreviewEmail,
  setSuccessStoryCampaigns,
  resetSuccessStoryEmailList,
  setSuccessStorySubjects,
  sagaSuccessStoryGetSubjects
}
