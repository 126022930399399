import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from '../general'
import actions from './actions'
import errorPaser from 'util/laravel_error_parser'

const getFunds = async ({ payload }) => {
  return await axios.get('api/shelter/funds', { params: payload })
}

const getECommerceFundsReport = async ({ payload }) => {
  return await axios.get('api/shelter/ecommerce-report', { params: payload })
}

const getECommerceFundReportDetails = async ({ payload }) => {
  const { fundId, petId } = payload

  return await axios.get(
    `api/shelter/ecommerce-report/${fundId}/pet/${petId}`,
    {
      params: payload
    }
  )
}

function* doGetFunds(payload) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(getFunds, payload)
    yield put(actions.setPaginator(response.data))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doGetECommerceFundsReport(payload) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(getECommerceFundsReport, payload)

    yield put(actions.setPaginator(response.data))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doGetECommerceFundReportDetails(payload) {
  try {
    yield put(actions.setLoading(true))
    const response = yield call(getECommerceFundReportDetails, payload)

    yield put(actions.setECommerceFundReportDetails(response.data))
    yield put(actions.setLoading(false))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

/** Watchers **/
function* watchDoGetFunds() {
  yield takeLatest(types.SAGA_FUND_INDEX, doGetFunds)
}

function* watchDoGetECommerceFundsReport() {
  yield takeLatest(types.SAGA_ECOMMERCE_FUND_REPORT, doGetECommerceFundsReport)
}

function* watchDoGetECommerceFundReportDetails() {
  yield takeLatest(
    types.SAGA_ECOMMERCE_FUND_REPORT_DETAILS,
    doGetECommerceFundReportDetails
  )
}

/**
 * Exporting all watchers
 */
export default function* rootSaga() {
  yield all([
    watchDoGetFunds(),
    watchDoGetECommerceFundsReport(),
    watchDoGetECommerceFundReportDetails()
  ])
}
