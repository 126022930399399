import types from './types'

const SHIPPING_ADDRESS = {
  id: '',
  firstname: '',
  lastname: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  country: 'US',
  is_default: false
}

const INITIAL_STATE = {
  paginator: [],
  currentShippingAddress: SHIPPING_ADDRESS,
  shippingAddressForm: SHIPPING_ADDRESS,
  shippingStates: [],
  shippingCountries: [
    {
      value: 'US',
      label: 'United States',
      states: [
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AS', label: 'American Samoa' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'DC', label: 'District Of Columbia' },
        { value: 'FM', label: 'Federated States Of Micronesia' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'GU', label: 'Guam' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MH', label: 'Marshall Islands' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'MP', label: 'Northern Mariana Islands' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PW', label: 'Palau' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'PR', label: 'Puerto Rico' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VI', label: 'Virgin Islands' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' }
      ]
    },
    {
      value: 'CA',
      label: 'Canada',
      states: [
        { value: 'AB', label: 'Alberta' },
        { value: 'BC', label: 'British Columbia' },
        { value: 'MB', label: 'Manitoba' },
        { value: 'NB', label: 'New Brunswick' },
        { value: 'NL', label: 'Newfoundland and Labrador' },
        { value: 'NT', label: 'Northwest Territories' },
        { value: 'NS', label: 'Nova Scotia' },
        { value: 'NU', label: 'Nunavut' },
        { value: 'ON', label: 'Ontario' },
        { value: 'PE', label: 'Prince Edward Island' },
        { value: 'QC', label: 'Quebec' },
        { value: 'SK', label: 'Saskatchewan' },
        { value: 'YT', label: 'Yukon Territory' }
      ]
    },
    {
      value: 'IN',
      label: 'India',
      states: [
        { value: 'AN', label: 'Andaman and Nicobar Islands' },
        { value: 'AP', label: 'Andhra Pradesh' },
        { value: 'AR', label: 'Arunachal Pradesh' },
        { value: 'AS', label: 'Assam' },
        { value: 'BR', label: 'Bihar' },
        { value: 'CG', label: 'Chandigarh' },
        { value: 'CH', label: 'Chhattisgarh' },
        { value: 'DN', label: 'Dadra and Nagar Haveli' },
        { value: 'DD', label: 'Daman and Diu' },
        { value: 'DL', label: 'Delhi' },
        { value: 'GA', label: 'Goa' },
        { value: 'GJ', label: 'Gujarat' },
        { value: 'HR', label: 'Haryana' },
        { value: 'HP', label: 'Himachal Pradesh' },
        { value: 'JK', label: 'Jammu and Kashmir' },
        { value: 'JH', label: 'Jharkhand' },
        { value: 'KA', label: 'Karnataka' },
        { value: 'KL', label: 'Kerala' },
        { value: 'LA', label: 'Ladakh' },
        { value: 'LD', label: 'Lakshadweep' },
        { value: 'MP', label: 'Madhya Pradesh' },
        { value: 'MH', label: 'Maharashtra' },
        { value: 'MN', label: 'Manipur' },
        { value: 'ML', label: 'Meghalaya' },
        { value: 'MZ', label: 'Mizoram' },
        { value: 'NL', label: 'Nagaland' },
        { value: 'OR', label: 'Odisha' },
        { value: 'PY', label: 'Puducherry' },
        { value: 'PB', label: 'Punjab' },
        { value: 'RJ', label: 'Rajasthan' },
        { value: 'SK', label: 'Sikkim' },
        { value: 'TN', label: 'Tamil Nadu' },
        { value: 'TS', label: 'Telangana' },
        { value: 'TR', label: 'Tripura' },
        { value: 'UP', label: 'Uttar Pradesh' },
        { value: 'UK', label: 'Uttarakhand' },
        { value: 'WB', label: 'West Bengal' }
      ]
    }
  ],
  isModalOpen: false,
  isLoading: false,
  shippingAddressId: null
}

const shippingAddressReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CURRENT_SHIPPING_ADDRESS:
      return {
        ...state,
        currentShippingAddress: action.payload
      }

    case types.SET_SHIPPING_ADDRESS_LIST: {
      return {
        ...state,
        paginator: action.payload
      }
    }

    case types.UPDATE_SHIPPING_ADDRESS_FORM: {
      const { key, value } = action
      return {
        ...state,
        shippingAddressForm: {
          ...state.shippingAddressForm,
          [key]: value
        }
      }
    }

    case types.RESET_SHIPPING_ADDRESS: {
      return {
        ...state,
        shippingAddressForm: INITIAL_STATE.shippingAddressForm
      }
    }

    case types.SET_SHIPPING_ADDRESS_FORM: {
      return {
        ...state,
        shippingAddressForm: action.payload
      }
    }

    case types.SET_SHIPPING_ADDRESS_MODAL_OPEN: {
      return {
        ...state,
        isModalOpen: action.open
      }
    }

    case types.SET_SHIPPING_ADDRESS_IS_LOADING: {
      return {
        ...state,
        isLoading: action.loading
      }
    }

    case types.SET_SHIPPING_ADDRESS_ID: {
      return {
        ...state,
        shippingAddressId: action.id
      }
    }

    default:
      return state
  }
}

export default shippingAddressReducer
