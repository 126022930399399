import { call, put } from 'redux-saga/effects'
import errorParser from 'util/laravel_error_parser'
import { adminOrdersActions } from '../../admin_orders'
import { generalActions } from '../../general'
import { addCartOrderItem } from './api'

function* doPostCartOrderItem({
  shipping_address_id: shippingAddressId,
  variant_id: variantId,
  callback
}) {
  try {
    yield call(addCartOrderItem, shippingAddressId, variantId)
    yield put(adminOrdersActions.sagaGetOrderItems(shippingAddressId, callback))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  }
}

export default doPostCartOrderItem
