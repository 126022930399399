import types from './types'

const sagaGetParameters = () => {
  return {
    type: types.SAGA_GET_PARAMETERS
  }
}

const sagaPutParameters = () => {
  return {
    type: types.SAGA_PUT_PARAMETERS
  }
}

const setParameters = (parameters) => {
  return {
    type: types.SET_PARAMETERS,
    parameters
  }
}

const updateValue = (key, value) => {
  return {
    type: types.UPDATE_VALUE,
    key,
    value
  }
}

const sagaGetPendingProducts = (payload) => {
  return {
    type: types.SAGA_GET_PENDING_PRODUCTS,
    payload
  }
}

const setPendingProducts = (pendingProducts) => {
  return {
    type: types.SET_PENDING_PRODUCTS,
    pending_products: pendingProducts
  }
}

const sagaGetLimboProducts = () => {
  return {
    type: types.SAGA_GET_LIMBO_PRODUCTS
  }
}

const setLimboProducts = (limboProducts) => {
  return {
    type: types.SET_LIMBO_PRODUCTS,
    limbo_products: limboProducts
  }
}

const sagaSkipEa = (sku, shelter) => {
  return {
    type: types.SAGA_SKIP_EA,
    sku,
    shelter
  }
}

const setSkipEa = (id) => {
  return {
    type: types.SET_SKIP_EA,
    id
  }
}

const sagaGetShopifyProduct = (text) => {
  return {
    type: types.SAGA_GET_SHOPIFY_PRODUCT,
    text
  }
}

const sagaGetShopifyVariants = (productId) => {
  return {
    type: types.SAGA_GET_SHOPIFY_VARIANTS,
    product_id: productId
  }
}

const setFixVariants = (fixProduct, fixVariants) => {
  return {
    type: types.SET_FIX_VARIANTS,
    fix_product: fixProduct,
    fix_variants: fixVariants
  }
}

const setFixMessage = (fixMessage) => {
  return {
    type: types.SET_FIX_MESSAGE,
    fix_message: fixMessage
  }
}

const resetFix = () => {
  return {
    type: types.RESET_FIX
  }
}

const sagaFixLimbo = (
  originProductId,
  originVariantId,
  newProductId,
  newVariantId,
  originSkuIndex,
  callback
) => {
  return {
    type: types.SAGA_FIX_LIMBO,
    origin_product_id: originProductId,
    origin_variant_id: originVariantId,
    new_product_id: newProductId,
    new_variant_id: newVariantId,
    origin_sku_index: originSkuIndex,
    callback
  }
}

const sagaGetPendingOrders = () => {
  return {
    type: types.SAGA_GET_PENDING_ORDERS
  }
}

const putPendingOrders = (pendingOrders) => {
  return {
    type: types.PUT_PENDING_ORDERS,
    pending_orders: pendingOrders
  }
}

const putPendingOrdersProducts = (pendingOrdersProducts) => {
  return {
    type: types.PUT_PENDING_ORDERS_PRODUCTS,
    pending_orders_products: pendingOrdersProducts
  }
}

const sagaGetPendingOrdersProducts = (
  shelterId,
  shippingAddressId,
  removeGeneralMessages = true
) => {
  return {
    type: types.SAGA_GET_PENDING_ORDERS_PRODUCTS,
    shelter_id: shelterId,
    shipping_address_id: shippingAddressId,
    remove_general_messages: removeGeneralMessages
  }
}

const updateEaInventory = (index, inventory) => {
  return {
    type: types.UPDATE_EA_INVENTORY,
    index,
    inventory
  }
}

const cancelSagaGetPendingOrdersProducts = () => {
  return {
    type: types.CANCEL_SAGA_GET_PENDING_ORDERS_PRODUCTS
  }
}

const sagaGetEaInventory = () => {
  return {
    type: types.SAGA_GET_EA_INVENTORY
  }
}

const resetEaInventory = () => {
  return {
    type: types.RESET_EA_INVENTORY
  }
}

const sagaGetOrderItems = (
  shippingAddressId,
  callback,
  removeGeneralMessages = true
) => {
  return {
    type: types.SAGA_GET_CART_ORDER_ITEMS,
    shipping_address_id: shippingAddressId,
    callback,
    remove_general_messages: removeGeneralMessages
  }
}

const setOrderItems = (cartOrderItems) => {
  return {
    type: types.SET_CART_ORDER_ITEMS,
    cart_order_items: cartOrderItems
  }
}

const sagaDeleteOrderItem = (shippingAddressId, variantId, callback) => {
  return {
    type: types.SAGA_DELETE_CART_ORDER_ITEM,
    shipping_address_id: shippingAddressId,
    variant_id: variantId,
    callback
  }
}

const sagaPostOrderItem = (shippingAddressId, variantId, callback) => {
  return {
    type: types.SAGA_POST_CART_ORDER_ITEM,
    shipping_address_id: shippingAddressId,
    variant_id: variantId,
    callback
  }
}

const sagaDeleteAllOrderItems = (callback) => {
  return {
    type: types.SAGA_DELETE_ALL_CART_ORDER_ITEMS,
    callback
  }
}

const sagaPostShopifyOrder = (shippingAddressId, notes, callback) => {
  return {
    type: types.SAGA_POST_SHOPIFY_ORDER,
    notes,
    shipping_address_id: shippingAddressId,
    callback
  }
}

const setNextRun = (nextRun) => {
  return {
    type: types.SET_NEXT_RUN,
    next_run: nextRun
  }
}

const sagaGetReportItems = () => {
  return {
    type: types.SAGA_GET_PENDING_ORDERS_REPORT
  }
}

const setReport = (reportItems) => {
  return {
    type: types.SET_PENDING_ORDERS_REPORT,
    report_items: reportItems
  }
}

const sagaDownloadSkusInLimboReport = () => {
  return {
    type: types.SAGA_DOWNLOAD_SKUS_IN_LIMBO_REPORT
  }
}

const setDownloading = (downloading = false) => {
  return {
    type: types.SET_DOWNLOADING,
    downloading
  }
}

const sagaGetLimboProductDetailsBySku = (sku) => {
  return {
    type: types.SAGA_GET_LIMBO_PRODUCT_DETAILS_BY_SKU,
    sku
  }
}

const setLoadingSkus = (loading = false) => {
  return {
    type: types.SET_LOADING_SKUS,
    loading
  }
}

const setLimboProductDetailsBySku = (detailsBySku) => {
  return {
    type: types.SET_LIMBO_PRODUCT_DETAILS_BY_SKU,
    details_by_sku: detailsBySku
  }
}

const setWaitingSkipEA = (waiting = false) => {
  return {
    type: types.SET_WAITING_SKIP_EA,
    waiting
  }
}

const sagaGetPendingOrdersReportDetails = (id) => {
  return {
    type: types.SAGA_GET_PENDING_ORDERS_REPORT_DETAILS,
    id
  }
}

const setPendingOrdersReportDetails = (details) => {
  return {
    type: types.SET_PENDING_ORDERS_REPORT_DETAILS,
    details
  }
}

const setLoadingPendingOrdersReportDetails = (loading = false) => {
  return {
    type: types.SET_LOADING_PENDING_ORDERS_REPORT_DETAILS,
    loading
  }
}

const resetPendingOrdersReportDetails = () => {
  return {
    type: types.RESET_PENDING_ORDERS_REPORT_DETAILS
  }
}

const adminOrdersActions = {
  sagaGetParameters,
  sagaPutParameters,
  setParameters,
  updateValue,
  sagaGetPendingProducts,
  setPendingProducts,
  sagaGetLimboProducts,
  setLimboProducts,
  sagaSkipEa,
  setSkipEa,
  sagaGetShopifyProduct,
  sagaGetShopifyVariants,
  setFixVariants,
  setFixMessage,
  resetFix,
  sagaFixLimbo,
  sagaGetPendingOrders,
  putPendingOrders,
  putPendingOrdersProducts,
  sagaGetPendingOrdersProducts,
  updateEaInventory,
  cancelSagaGetPendingOrdersProducts,
  sagaGetEaInventory,
  resetEaInventory,
  sagaGetOrderItems,
  setOrderItems,
  sagaDeleteOrderItem,
  sagaPostOrderItem,
  sagaDeleteAllOrderItems,
  sagaPostShopifyOrder,
  setNextRun,
  sagaGetReportItems,
  setReport,
  sagaDownloadSkusInLimboReport,
  setDownloading,
  sagaGetLimboProductDetailsBySku,
  setLoadingSkus,
  setLimboProductDetailsBySku,
  setWaitingSkipEA,
  sagaGetPendingOrdersReportDetails,
  setPendingOrdersReportDetails,
  setLoadingPendingOrdersReportDetails,
  resetPendingOrdersReportDetails
}

export default adminOrdersActions
