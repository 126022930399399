import { call, all, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import actions from './actions'
import { generalActions } from 'store/general'
import errorParser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

// Axios calls
const getOrdersReport = async ({ userType, payload }) => {
  const url = `/api/${userType}/reports/orders`

  return await axios.get(url, { params: payload })
}

const getOrdersReportDetails = async ({ payload }) => {
  const { userType, id } = payload
  const url = `/api/${userType}/reports/orders/${id}`

  return await axios.get(url)
}

const getOrdersReportDownload = async ({ userType }) => {
  const response = await axios.get(`/api/${userType}/reports/orders/download`, {
    responseType: 'blob'
  })
  const downloadedAt = new Date().toISOString().slice(0, 10)
  const filename = `${
    userType === 'pantry' ? 'pantry_' : ''
  }orders_${downloadedAt}.xls`
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

// Doers
function* doGetOrdersReport(payload) {
  try {
    yield put(actions.isLoadingOrdersReport(true))
    const { data } = yield call(getOrdersReport, payload)
    yield put(actions.setOrdersReport(data))
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.isLoadingOrdersReport())
  }
}

function* doGetOrdersReportDetails(payload) {
  try {
    yield put(actions.isLoadingOrdersReportDetails(true))
    const { data } = yield call(getOrdersReportDetails, payload)
    yield put(actions.setOrdersReportDetails(data))
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.isLoadingOrdersReportDetails())
  }
}

function* doGetOrdersReportDownload(payload) {
  try {
    yield put(actions.isCreatingOrdersReport(true))
    yield call(getOrdersReportDownload, payload)
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.isCreatingOrdersReport())
  }
}

// Watchers
function* watchGetOrdersReport() {
  yield takeLatest(
    types.SAGA_GET_ORDERS_REPORT,
    safe(onError, doGetOrdersReport)
  )
}

function* watchGetOrdersReportDetails() {
  yield takeLatest(
    types.SAGA_GET_ORDERS_REPORT_DETAILS,
    safe(onError, doGetOrdersReportDetails)
  )
}

function* watchGetOrdersReportDownload() {
  yield takeLatest(
    types.SAGA_GET_ORDERS_REPORT_DOWNLOAD,
    safe(onError, doGetOrdersReportDownload)
  )
}

export default function* rootSaga() {
  yield all([
    watchGetOrdersReport(),
    watchGetOrdersReportDetails(),
    watchGetOrdersReportDownload()
  ])
}
