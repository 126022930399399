import types from './types'

const sagaGetCampaignEmail = (id) => {
  return {
    type: types.SAGA_GET_CAMPAIGN_EMAIL,
    id
  }
}

const sagaGetCalculateEmailList = (id) => {
  return {
    type: types.SAGA_GET_CALCULATE_EMAIL_LIST,
    id
  }
}

const sagaPostCampaignEmail = (data, callback) => {
  return {
    type: types.SAGA_POST_CAMPAIGN_EMAIL,
    data,
    callback
  }
}

const setCampaignEmailForm = (data) => {
  return {
    type: types.SET_CAMPAIGN_EMAIL_FORM,
    data
  }
}

const updateCampaignEmailForm = (key, value) => {
  return {
    type: types.UPDATE_CAMPAIGN_EMAIL_FORM,
    key,
    value
  }
}

const resetCampaignEmailForm = () => {
  return {
    type: types.RESET_CAMPAIGN_EMAIL_FORM
  }
}

const setExhaustedCampaignEmailList = (fetching) => {
  return {
    type: types.EXHAUSTED_CAMPAIGN_EMAIL_LIST,
    fetching
  }
}

const updateEmailList = (data) => {
  return {
    type: types.UPDATE_CAMPAIGN_EMAIL_LIST,
    data
  }
}

export default {
  sagaGetCampaignEmail,
  sagaPostCampaignEmail,
  sagaGetCalculateEmailList,
  setCampaignEmailForm,
  updateCampaignEmailForm,
  resetCampaignEmailForm,
  setExhaustedCampaignEmailList,
  updateEmailList
}
