const SAGA_GET_USERS = 'SAGA_GET_USERS'
const SAGA_PATCH_USER = 'SAGA_PATCH_USER'
const SET_USERS = 'SET_USERS'
const SET_USERS_LOADING = 'SET_USERS_LOADING'
const SET_SIGN_PANTRY_AGREEMENT = 'SET_SIGN_PANTRY_AGREEMENT'

const usersTypes = {
  SAGA_GET_USERS,
  SAGA_PATCH_USER,
  SET_USERS,
  SET_USERS_LOADING,
  SET_SIGN_PANTRY_AGREEMENT
}

export default usersTypes
