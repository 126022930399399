import { EIN_REGEX } from 'util/constants'
import types from './types'

const INITIAL_STATE = {
  is_authenticated: null,
  user: {}
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.IS_AUTHENTICATED: {
      const { value, user } = action
      user.has_valid_ein =
        user.ein_number &&
        EIN_REGEX.test(user.ein_number) &&
        (user.ein_number.length === 9 || user.ein_number.length === 10) // 9 for non-formatted value or 10 if is formatted as xx-xxxxxxx

      return {
        ...state,
        is_authenticated: value,
        user
      }
    }

    case types.PROFILE_UPDATE_FORM: {
      const { key, value } = action
      const tmp = JSON.parse(JSON.stringify(state.user)) // <-- if we stringy we lose reference to file selected (logo)
      tmp.logo = state.user.logo // CUDDLY-1838 <-- over write logo
      tmp[key] = value
      return {
        ...state,
        user: tmp
      }
    }

    case types.PROFILE_REFRESH_AFTER_UPDATE: {
      const registeredAs =
        state.user.registered_as === 'pantry'
          ? 'pantry'
          : action.data.registered_as

      return {
        ...state,
        user: {
          ...state.user,
          ...action.data,
          logo: action.data.logo,
          password: '',
          registered_as: registeredAs
        }
      }
    }

    case types.AGREEMENT_SIGNED: {
      const user = { ...state.user }
      user.agreement_signed = true
      return {
        ...state,
        user
      }
    }

    case types.SET_ACT_AS_PANTRY: {
      const user = { ...state.user }
      const key = `act_as_pantry_${user.id}`
      const { value } = action

      if (value) {
        localStorage.setItem(key, value)
      } else {
        localStorage.removeItem(key)
      }

      user.registered_as = value ? 'pantry' : 'shelter'
      return {
        ...state,
        user
      }
    }

    case types.PANTRY_AGREEMENT_SIGNED: {
      const user = { ...state.user }
      user.pantry_agreement_signed = true

      return {
        ...state,
        user
      }
    }

    case types.SET_FUNDRAISING_AGREEMENT_ADDENDUM: {
      const user = { ...state.user }
      user.needs_to_sign_fundraising_agreement = action.value

      return {
        ...state,
        user
      }
    }

    default:
      return state
  }
}

export default authReducer
