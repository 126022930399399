const SAGA_FUND_INDEX = 'SAGA_FUND_INDEX'
const SAGA_ECOMMERCE_FUND_REPORT = 'SAGA_ECOMMERCE_FUND_REPORT'
const SAGA_ECOMMERCE_FUND_REPORT_DETAILS = 'SAGA_ECOMMERCE_FUND_REPORT_DETAILS'
const SET_PAGINATOR = 'SET_PAGINATOR'
const SET_CURRENT = 'SET_CURRENT'
const SWITCH_FUND_REFRESH = 'SWITCH_FUND_REFRESH'
const SET_ECOMMERCE_FUND_REPORT_DETAILS = 'SET_ECOMMERCE_FUND_REPORT_DETAILS'
const SET_LOADING = 'SET_LOADING'

const _default = {
  SAGA_FUND_INDEX,
  SAGA_ECOMMERCE_FUND_REPORT,
  SAGA_ECOMMERCE_FUND_REPORT_DETAILS,
  SET_PAGINATOR,
  SWITCH_FUND_REFRESH,
  SET_CURRENT,
  SET_ECOMMERCE_FUND_REPORT_DETAILS,
  SET_LOADING
}

export default _default
