export const getUserType = (registeredAs) => {
  switch (registeredAs) {
    case 'admin':
    case 'sadmin':
      return 'admin'
    case 'shelter':
    case 'rescue':
      return 'shelter'
    case 'donor':
      return 'donor'
    case 'pantry':
      return 'pantry'
    default:
      return ''
  }
}

export const getIsPantry = (registeredAs) => {
  return registeredAs === 'pantry'
}

export const getIsShelter = (registeredAs) => {
  return registeredAs === 'shelter' || registeredAs === 'rescue'
}

export const getIsAdmin = (registeredAs) => {
  return registeredAs === 'admin' || registeredAs === 'sadmin'
}

export const getIsImpersonating = (user) => {
  return user?.can_back_to_admin !== false
}

export const getIsVerifiedSar = (user) => {
  const {
    agreement_signed: agreementSigned = false,
    has_valid_ein: hasValidEIN = false,
    shelter_verified: shelterVerified = false,
    needs_to_sign_agreement: needsToSignAgreement = false
  } = user

  return !(
    !shelterVerified ||
    !hasValidEIN ||
    (needsToSignAgreement && !agreementSigned)
  )
}
