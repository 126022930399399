import { call, put, select } from 'redux-saga/effects'
import errorPaser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { putParameters } from './api'

const readParametersFromStore = (state) => state.admin_order.parameters

function* doPutParameters() {
  yield put(generalActions.isLoading())
  const currentParameters = yield select(readParametersFromStore)

  try {
    const response = yield call(putParameters, currentParameters)
    yield put(generalActions.setSuccess(response.data.message))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

export default doPutParameters
