import { all, put, call, cancel, take, fork, join } from 'redux-saga/effects'
import { generalActions } from '../../general'
import { getPendingOrdersProducts } from './api'
import actions from '../actions'
import types from '../types'

function* doGetPendingOrdersProducts(action) {
  const task = yield fork(getPendingOrdersProducts, action)
  yield all([call(cancelTask, task), call(joinTask, task, action)])
}

function* cancelTask(task) {
  yield take(types.CANCEL_SAGA_GET_PENDING_ORDERS_PRODUCTS)
  yield put(actions.putPendingOrdersProducts([]))
  yield cancel(task)
}

function* joinTask(task, action) {
  const response = yield join(task)
  yield put(actions.putPendingOrdersProducts(response.data.data))

  if (action.remove_general_messages)
    yield put(generalActions.setSuccess(response.data.message))
}

export default doGetPendingOrdersProducts
