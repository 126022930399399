const SAGA_GET_PARAMETERS = 'SAGA_GET_PARAMETERS'
const SAGA_PUT_PARAMETERS = 'SAGA_PUT_PARAMETERS'
const SET_PARAMETERS = 'SET_PARAMETERS'
const UPDATE_VALUE = 'UPDATE_VALUE'
const SAGA_GET_PENDING_PRODUCTS = 'SAGA_GET_PENDING_PRODUCTS'
const SET_PENDING_PRODUCTS = 'SET_PENDING_PRODUCTS'
const SAGA_GET_LIMBO_PRODUCTS = 'SAGA_GET_LIMBO_PRODUCTS'
const SET_LIMBO_PRODUCTS = 'SET_LIMBO_PRODUCTS'
const SAGA_SKIP_EA = 'SAGA_SKIP_EA'
const SET_SKIP_EA = 'SET_SKIP_EA'

const SAGA_GET_SHOPIFY_VARIANTS = 'SAGA_GET_SHOPIFY_VARIANTS'
const SAGA_GET_SHOPIFY_PRODUCT = 'SAGA_GET_SHOPIFY_PRODUCT'
const SET_FIX_VARIANTS = 'SET_FIX_VARIANTS'
const SET_FIX_MESSAGE = 'SET_FIX_MESSAGE'
const RESET_FIX = 'RESET_FIX'
const SAGA_FIX_LIMBO = 'SAGA_FIX_LIMBO'
const SAGA_GET_PENDING_ORDERS = 'SAGA_GET_PENDING_ORDERS'
const PUT_PENDING_ORDERS = 'PUT_PENDING_ORDERS'
const PUT_PENDING_ORDERS_PRODUCTS = 'PUT_PENDING_ORDERS_PRODUCTS'
const SAGA_GET_PENDING_ORDERS_PRODUCTS = 'SAGA_GET_PENDING_ORDERS_PRODUCTS'
const CANCEL_SAGA_GET_PENDING_ORDERS_PRODUCTS =
  'CANCEL_SAGA_GET_PENDING_ORDERS_PRODUCTS'
const UPDATE_EA_INVENTORY = 'UPDATE_EA_INVENTORY'
const SAGA_GET_EA_INVENTORY = 'SAGA_GET_EA_INVENTORY'
const RESET_EA_INVENTORY = 'RESET_EA_INVENTORY'
const SAGA_GET_CART_ORDER_ITEMS = 'SAGA_GET_CART_ORDER_ITEMS'
const SET_CART_ORDER_ITEMS = 'SET_CART_ORDER_ITEMS'
const SAGA_DELETE_CART_ORDER_ITEM = 'SAGA_DELETE_CART_ORDER_ITEM'
const SAGA_POST_CART_ORDER_ITEM = 'SAGA_POST_CART_ORDER_ITEM'
const SAGA_DELETE_ALL_CART_ORDER_ITEMS = 'SAGA_DELETE_ALL_CART_ORDER_ITEMS'
const SAGA_POST_SHOPIFY_ORDER = 'SAGA_POST_SHOPIFY_ORDER'
const SET_NEXT_RUN = 'SET_NEXT_RUN'
const SAGA_GET_PENDING_ORDERS_REPORT = 'SAGA_GET_PENDING_ORDERS_REPORT'
const SET_PENDING_ORDERS_REPORT = 'SET_PENDING_ORDERS_REPORT'

const SAGA_DOWNLOAD_SKUS_IN_LIMBO_REPORT = 'SAGA_DOWNLOAD_SKUS_IN_LIMBO_REPORT'
const SET_DOWNLOADING = 'SET_DOWNLOADING'
const SAGA_GET_LIMBO_PRODUCT_DETAILS_BY_SKU =
  'SAGA_GET_LIMBO_PRODUCT_DETAILS_BY_SKU'
const SET_LIMBO_PRODUCT_DETAILS_BY_SKU = 'SET_LIMBO_PRODUCT_DETAILS_BY_SKU'
const SET_LOADING_SKUS = 'SET_LOADING_SKUS'
const SET_WAITING_SKIP_EA = 'SET_WAITING_SKIP_EA'

const SAGA_GET_PENDING_ORDERS_REPORT_DETAILS =
  'SAGA_GET_PENDING_ORDERS_REPORT_DETAILS'
const SET_PENDING_ORDERS_REPORT_DETAILS = 'SET_PENDING_ORDERS_REPORT_DETAILS'
const SET_LOADING_PENDING_ORDERS_REPORT_DETAILS =
  'SET_LOADING_PENDING_ORDERS_REPORT_DETAILS'
const RESET_PENDING_ORDERS_REPORT_DETAILS =
  'RESET_PENDING_ORDERS_REPORT_DETAILS'

const _default = {
  SAGA_GET_PARAMETERS,
  SAGA_PUT_PARAMETERS,
  SET_PARAMETERS,
  UPDATE_VALUE,
  SAGA_GET_PENDING_PRODUCTS,
  SET_PENDING_PRODUCTS,
  SAGA_GET_LIMBO_PRODUCTS,
  SET_LIMBO_PRODUCTS,
  SAGA_SKIP_EA,
  SET_SKIP_EA,
  SAGA_GET_SHOPIFY_PRODUCT,
  SAGA_GET_SHOPIFY_VARIANTS,
  SET_FIX_VARIANTS,
  SET_FIX_MESSAGE,
  RESET_FIX,
  SAGA_FIX_LIMBO,
  SAGA_GET_PENDING_ORDERS,
  PUT_PENDING_ORDERS,
  PUT_PENDING_ORDERS_PRODUCTS,
  SAGA_GET_PENDING_ORDERS_PRODUCTS,
  UPDATE_EA_INVENTORY,
  SAGA_GET_EA_INVENTORY,
  CANCEL_SAGA_GET_PENDING_ORDERS_PRODUCTS,
  RESET_EA_INVENTORY,

  SAGA_GET_CART_ORDER_ITEMS,
  SET_CART_ORDER_ITEMS,
  SAGA_DELETE_CART_ORDER_ITEM,
  SAGA_POST_CART_ORDER_ITEM,
  SAGA_DELETE_ALL_CART_ORDER_ITEMS,
  SAGA_POST_SHOPIFY_ORDER,
  SET_NEXT_RUN,
  SAGA_GET_PENDING_ORDERS_REPORT,
  SET_PENDING_ORDERS_REPORT,

  SAGA_DOWNLOAD_SKUS_IN_LIMBO_REPORT,
  SAGA_GET_LIMBO_PRODUCT_DETAILS_BY_SKU,
  SET_LIMBO_PRODUCT_DETAILS_BY_SKU,
  SET_LOADING_SKUS,
  SET_WAITING_SKIP_EA,
  SET_DOWNLOADING,

  SAGA_GET_PENDING_ORDERS_REPORT_DETAILS,
  SET_PENDING_ORDERS_REPORT_DETAILS,
  SET_LOADING_PENDING_ORDERS_REPORT_DETAILS,
  RESET_PENDING_ORDERS_REPORT_DETAILS
}

export default _default
