import types from './types'

// Sagas
const sagaGetPantryCampaign = (id) => {
  return {
    type: types.SAGA_GET_PANTRY_CAMPAIGN,
    id
  }
}

const sagaPostPantryCampaign = (data, callback) => {
  return {
    type: types.SAGA_POST_PANTRY_CAMPAIGN,
    data,
    callback
  }
}

const sagaPutPantryCampaign = (id, data, callback) => {
  return {
    type: types.SAGA_PUT_PANTRY_CAMPAIGN,
    id,
    data,
    callback
  }
}

const sagaGetPantryCampaigns = (campaignType = 'active', page = 1) => {
  return {
    type: types.SAGA_GET_PANTRY_CAMPAIGNS,
    campaignType,
    page
  }
}

// getters and setters
const setPantryCampaign = (data) => {
  return {
    type: types.SET_PANTRY_CAMPAIGN,
    data
  }
}

const setPantryCampaignForm = (key, value) => {
  return {
    type: types.SET_PANTRY_CAMPAIGN_FORM,
    key,
    value
  }
}

const resetPantryCampaignForm = () => {
  return {
    type: types.RESET_PANTRY_CAMPAIGN_FORM
  }
}

const setRemovePantryCampaignPhoto = (id) => {
  return {
    type: types.SET_REMOVE_PANTRY_CAMPAIGN_PHOTO,
    id
  }
}

const setRemovePantryCampaignVideo = (id) => {
  return {
    type: types.SET_REMOVE_PANTRY_CAMPAIGN_VIDEO,
    id
  }
}

const setPantryCampaigns = (data) => {
  return {
    type: types.SET_PANTRY_CAMPAIGNS,
    data
  }
}

const setPantryCampaignsLoading = (loading) => {
  return {
    type: types.SET_PANTRY_CAMPAIGNS_LOADING,
    loading
  }
}

const resetPantryCampaigns = () => {
  return {
    type: types.RESET_PANTRY_CAMPAIGNS
  }
}

export default {
  sagaGetPantryCampaign,
  sagaPostPantryCampaign,
  sagaPutPantryCampaign,
  sagaGetPantryCampaigns,
  setPantryCampaign,
  setPantryCampaignForm,
  resetPantryCampaignForm,
  setRemovePantryCampaignPhoto,
  setRemovePantryCampaignVideo,
  setPantryCampaigns,
  setPantryCampaignsLoading,
  resetPantryCampaigns
}
