const SAGA_GET_SHIPPING_ADDRESS_LIST = 'SAGA_GET_SHIPPING_ADDRESS_LIST'
const SAGA_POST_SHIPPING_ADDRESS = 'SAGA_POST_SHIPPING_ADDRESS'
const SAGA_PUT_SHIPPING_ADDRESS = 'SAGA_PUT_SHIPPING_ADDRESS'
const SAGA_DELETE_SHIPPING_ADDRESS = 'SAGA_DELETE_SHIPPING_ADDRESS'
const SET_SHIPPING_ADDRESS_LIST = 'SET_SHIPPING_ADDRESS_LIST'
const UPDATE_SHIPPING_ADDRESS_FORM = 'UPDATE_SHIPPING_ADDRESS_FORM'
const RESET_SHIPPING_ADDRESS = 'RESET_SHIPPING_ADDRESS'
const SET_CURRENT_SHIPPING_ADDRESS = 'SET_CURRENT_SHIPPING_ADDRESS'
const SET_SHIPPING_ADDRESS_FORM = 'SET_SHIPPING_ADDRESS_FORM'
const SET_SHIPPING_ADDRESS_MODAL_OPEN = 'SET_SHIPPING_ADDRESS_MODAL_OPEN'
const SET_SHIPPING_ADDRESS_IS_LOADING = 'SET_SHIPPING_ADDRESS_IS_LOADING'
const SET_SHIPPING_ADDRESS_ID = 'SET_SHIPPING_ADDRESS_ID'

const shippingAddressTypes = {
  SAGA_GET_SHIPPING_ADDRESS_LIST,
  SAGA_POST_SHIPPING_ADDRESS,
  SAGA_PUT_SHIPPING_ADDRESS,
  SAGA_DELETE_SHIPPING_ADDRESS,
  SET_SHIPPING_ADDRESS_LIST,
  UPDATE_SHIPPING_ADDRESS_FORM,
  RESET_SHIPPING_ADDRESS,
  SET_CURRENT_SHIPPING_ADDRESS,
  SET_SHIPPING_ADDRESS_FORM,
  SET_SHIPPING_ADDRESS_MODAL_OPEN,
  SET_SHIPPING_ADDRESS_IS_LOADING,
  SET_SHIPPING_ADDRESS_ID
}

export default shippingAddressTypes
