import { lazy } from 'react'

export const commonRoutes = [
  {
    to: '/login',
    component: lazy(() => import('containers/Login')),
    exact: true
  },
  {
    to: '/forgot-password',
    component: lazy(() => import('containers/ForgotPassword')),
    exact: true
  },
  {
    to: '/reset-password',
    component: lazy(() => import('containers/ResetPassword')),
    exact: true
  },
  {
    to: '/:auth_string/login',
    component: lazy(() => import('containers/ExternalLogin')),
    exact: true
  },
  {
    to: '/sign-up',
    component: lazy(() => import('containers/SignUp')),
    exact: true
  },
  {
    to: '*',
    component: lazy(() => import('containers/NotFound'))
  }
]

export default commonRoutes
