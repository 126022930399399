const laravelErrorParser = (error) => {
  let returnMessage = ''

  if (!error.response) return error

  switch (error?.response?.status) {
    case 400: {
      if (error?.response.data?.error) {
        returnMessage = error.response.data.error
      } else {
        const messages = []

        const keys = Object.keys(error?.response?.data[0]) || []

        for (let i = 0; i < keys?.length; i++) {
          messages.push(error.response.data[0][keys[i]].join('\n'))
        }

        returnMessage = messages
      }
      break
    }
    case 422: {
      if (Array.isArray(error?.response?.data?.errors)) {
        const messages = []

        for (const [, errorArray] of Object.entries(
          error.response.data.errors
        )) {
          messages.push(errorArray.join('\n'))
        }

        returnMessage = messages
        break
      }

      returnMessage = error?.response?.data?.errors || 'Bad request'
      break
    }
    case 401:
      localStorage.removeItem('token')

      if (
        window.location.href.toString().split(window.location.host)[1] ===
        '/login?redirect=/'
      ) {
        returnMessage = ''
      } else {
        returnMessage = error.response.data.message
          ? error.response.data.message
          : error.response.data.errors
          ? error.response.data.errors
          : 'Unauthenticated'
      }

      break
    case 403:
      returnMessage = error?.response?.data?.message || 'Forbidden'
      break
    case 500:
      returnMessage = 'Service unavailable. Please try again later.'
      break
    case 429:
      returnMessage =
        'There was an issue processing your request. Please try again at a later time.'
      break
    default:
      returnMessage = error?.toString()
  }

  return returnMessage
}

export default laravelErrorParser
