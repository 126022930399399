// For Admin
const SAGA_EMAIL_INDEX = 'SAGA_EMAIL_INDEX'
const SET_PAGINATOR = 'SET_PAGINATOR'
const SET_PAGINATOR_PER_EMAIL = 'SET_PAGINATOR_PER_EMAIL'
const SET_CURRENT = 'SET_CURRENT'
const SWITCH_EMAIL_REFRESH = 'SWITCH_EMAIL_REFRESH'

// for shelters
const SAGA_SAR_EMAIL_INDEX = 'SAGA_SAR_EMAIL_INDEX'
const SAGA_SAR_EMAIL_LIST_HISTORY_INDEX = 'SAGA_SAR_EMAIL_LIST_HISTORY_INDEX'
const SAGA_SAR_EMAIL_ANALYTICS = 'SAGA_SAR_EMAIL_ANALYTICS'
const SAGA_SAR_EMAIL_ANALYTICS_PER_EMAIL = 'SAGA_SAR_EMAIL_ANALYTICS_PER_EMAIL'
const SAGA_ARCHIVE_SAR_EMAIL_ANALYTICS = 'SAGA_ARCHIVE_SAR_EMAIL_ANALYTICS'
const SAGA_VERIFY_EMAILS = 'SAGA_VERIFY_EMAILS'
const SAGA_SAR_EMAIL_UPDATE = 'SAGA_SAR_EMAIL_UPDATE'
const SAGA_SAR_SCHEDULE_EMAIL_UPDATE = 'SAGA_SAR_SCHEDULE_EMAIL_UPDATE'
const SAGA_SAR_EMAIL_DELETE = 'SAGA_SAR_EMAIL_DELETE'
const SAGA_SAR_RE_VALIDATE = 'SAGA_SAR_RE_VALIDATE'

const _default = {
  SAGA_EMAIL_INDEX,
  SAGA_SAR_EMAIL_INDEX,
  SAGA_SAR_EMAIL_LIST_HISTORY_INDEX,
  SAGA_VERIFY_EMAILS,
  SAGA_SAR_EMAIL_ANALYTICS,
  SAGA_SAR_EMAIL_ANALYTICS_PER_EMAIL,
  SAGA_ARCHIVE_SAR_EMAIL_ANALYTICS,
  SET_PAGINATOR,
  SWITCH_EMAIL_REFRESH,
  SET_CURRENT,
  SAGA_SAR_EMAIL_UPDATE,
  SAGA_SAR_SCHEDULE_EMAIL_UPDATE,
  SAGA_SAR_EMAIL_DELETE,
  SAGA_SAR_RE_VALIDATE,
  SET_PAGINATOR_PER_EMAIL
}

export default _default
