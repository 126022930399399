import types from './types'

const sagaPostSignUpEmailAvailability = (email) => {
  return {
    type: types.SAGA_POST_SIGN_UP_EMAIL_AVAILABILITY,
    email
  }
}

const sagaPostSignUpSar503Verification = (ein) => {
  return {
    type: types.SAGA_POST_SIGN_UP_SAR_503_VERIFICATION,
    ein
  }
}

const sagaPostSignUp = (data, screen) => {
  return {
    type: types.SAGA_POST_SIGN_UP,
    data,
    screen
  }
}

const updateSignUpForm = (key, value) => {
  return {
    type: types.UPDATE_SIGN_UP_FORM,
    key,
    value
  }
}

const resetSignUpForm = () => {
  return {
    type: types.RESET_SIGN_UP_FORM
  }
}

const setSignUpIsLoading = (isLoading = false) => {
  return {
    type: types.SET_SIGN_UP_IS_LOADING,
    isLoading
  }
}

const setRegisterHasErrors = (registerErrors = '') => {
  return {
    type: types.SET_REGISTER_HAS_ERRORS,
    registerErrors
  }
}

const setSignUpEmailAvailability = (error = '') => {
  return {
    type: types.SET_SIGN_UP_EMAIL_AVAILABILITY,
    error
  }
}

const setSignUpSar503Verification = (data) => {
  return {
    type: types.SET_SIGN_UP_SAR_503_VERIFICATION,
    data
  }
}

const setSignUpScreen = (screen) => {
  return {
    type: types.SET_SIGN_UP_SCREEN,
    screen
  }
}

const clearFormAfterSignUp = () => {
  return {
    type: types.CLEAR_FORM_AFTER_SIGN_UP
  }
}

const signUpActions = {
  sagaPostSignUpEmailAvailability,
  sagaPostSignUpSar503Verification,
  sagaPostSignUp,
  updateSignUpForm,
  resetSignUpForm,
  setSignUpIsLoading,
  setRegisterHasErrors,
  setSignUpEmailAvailability,
  setSignUpSar503Verification,
  setSignUpScreen,
  clearFormAfterSignUp
}

export default signUpActions
