import { call, put, select, all } from 'redux-saga/effects'
import errorParser from 'util/laravel_error_parser'
import { adminOrdersActions } from '../../admin_orders'
import { generalActions } from '../../general'
import { deleteCartOrderItem } from './api'

const readAdminOrderFromStore = (state) => state.admin_order

function* doDeleteAllCartOrderItems({ callback }) {
  const { cart_order_items: cartOrderItems } = yield select(
    readAdminOrderFromStore
  )

  try {
    let _shippingAddressId
    yield all(
      cartOrderItems.map(
        ({ shipping_address_id: shippingAddressId, variant_id: variantId }) => {
          _shippingAddressId = shippingAddressId
          return call(deleteCartOrderItem, shippingAddressId, variantId)
        }
      )
    )
    yield put(
      adminOrdersActions.sagaGetOrderItems(_shippingAddressId, callback)
    )
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  }
}

export default doDeleteAllCartOrderItems
