import types from './types'

const DROPDOWN_INITIAL_STATE = [
  {
    value: '',
    label: 'Select'
  }
]

const initialState = {
  form: {
    to: '',
    subject: '',
    description: '',
    scheduleDate: new Date().setDate(new Date().getDate() + 1),
    type: 1,
    template: '',
    template_preview: '',
    schedule_hour: '',
    pet: {},
    templates: [],
    email_list: DROPDOWN_INITIAL_STATE,
    templates_list: DROPDOWN_INITIAL_STATE,
    subjects: DROPDOWN_INITIAL_STATE,
    exhausted_email_list: false
  }
}

const pantryCampaignEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PANTRY_CAMPAIGN_EMAIL_FORM: {
      const { key, value } = action

      return {
        form: {
          ...state.form,
          [key]: value
        }
      }
    }

    case types.RESET_PANTRY_CAMPAIGN_EMAIL_FORM: {
      return {
        form: {
          ...state.form,
          to: '',
          subject: '',
          description: ''
        }
      }
    }

    case types.SET_PANTRY_CAMPAIGN_EMAIL_FORM: {
      const { payload } = action

      let subjects =
        payload?.subjects.map((subject) => {
          return {
            value: subject,
            label: (
              <div className="flex items-center">
                <div className="flex flex-col whitespace-normal">
                  <span>{subject}</span>
                </div>
              </div>
            )
          }
        }) || []
      subjects = DROPDOWN_INITIAL_STATE.concat(subjects)

      let templatesList =
        payload?.templates.map(({ id: value, label }) => {
          return {
            value,
            label
          }
        }) || []
      templatesList = DROPDOWN_INITIAL_STATE.concat(templatesList)

      return {
        form: {
          ...state.form,
          ...payload,
          description: payload?.pet?.description || '',
          subjects,
          templates_list: templatesList
        }
      }
    }

    case types.SET_PANTRY_CAMPAIGN_EMAIL_FORM_LOADING: {
      return {
        form: {
          ...state.form,
          loading: action.loading
        }
      }
    }

    case types.SET_EXHAUSTED_PANTRY_CAMPAIGN_EMAIL_LIST: {
      return {
        form: {
          ...state.form,
          exhausted_email_list: action.exhausted
        }
      }
    }

    case types.UPDATE_PANTRY_CAMPAIGN_EMAIL_LIST: {
      let tmp = action?.data?.email_list || []

      if (tmp && tmp?.length === 0) {
        tmp = state.form?.email_list?.map(({ value, label }) => {
          return {
            value,
            label: `${label} (N/A)`
          }
        })
        tmp = DROPDOWN_INITIAL_STATE.concat(tmp)

        return {
          form: {
            ...state.form,
            email_list: tmp,
            exhausted_email_list: false
          }
        }
      }

      tmp = DROPDOWN_INITIAL_STATE.concat(action?.data?.email_list || [])

      return {
        form: {
          ...state.form,
          email_list: tmp,
          exhausted_email_list: false
        }
      }
    }

    default:
      return state
  }
}

export default pantryCampaignEmailReducer
