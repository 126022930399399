import types from './types'

const sagaDoLogin = (payload) => {
  return {
    type: types.SAGA_DO_LOGIN,
    payload
  }
}

const sagaDoLogout = () => {
  return {
    type: types.SAGA_DO_LOGOUT
  }
}

const setIsAuthenticated = (value, user = {}) => {
  return {
    type: types.IS_AUTHENTICATED,
    value,
    user
  }
}

const sagaCheckIsAuthenticated = () => {
  return {
    type: types.SAGA_CHECK_IS_AUTHENTICATED
  }
}

const profileUpdateForm = (key, value) => {
  return {
    type: types.PROFILE_UPDATE_FORM,
    key,
    value
  }
}

const sagaUpdateProfile = (form, withLogo, callback) => {
  return {
    type: types.SAGA_UPDATE_PROFILE,
    form,
    with_logo: withLogo,
    callback
  }
}

const profileRefreshAfterUpdate = (data) => {
  return {
    type: types.PROFILE_REFRESH_AFTER_UPDATE,
    data
  }
}

const sagaSignAgreement = (form, callback) => {
  return {
    type: types.SAGA_SIGN_AGREEMENT,
    form,
    callback
  }
}

const agreementSigned = () => {
  return {
    type: types.AGREEMENT_SIGNED
  }
}

const sagaResetPassword = (payload) => {
  return {
    type: types.SAGA_RESET_PASSWORD,
    payload
  }
}

const sagaResetLink = (payload) => {
  return {
    type: types.SAGA_RESET_LINK,
    payload
  }
}

const sagaExternalLogin = (loginString) => {
  return {
    type: types.SAGA_EXTERNAL_LOGIN,
    login_string: loginString
  }
}

const sagaPasswordUpdate = (payload, callback) => {
  return {
    type: types.SAGA_PASSWORD_UPDATE,
    payload,
    callback
  }
}

const setActAsPantry = (value) => {
  return {
    type: types.SET_ACT_AS_PANTRY,
    value
  }
}

const pantryAgreementSigned = () => {
  return {
    type: types.PANTRY_AGREEMENT_SIGNED
  }
}

const setFundraisingAgreementAddendum = (value) => {
  return {
    type: types.SET_FUNDRAISING_AGREEMENT_ADDENDUM,
    value
  }
}

const authActions = {
  sagaDoLogin,
  sagaDoLogout,
  setIsAuthenticated,
  sagaCheckIsAuthenticated,
  profileUpdateForm,
  sagaUpdateProfile,
  profileRefreshAfterUpdate,
  sagaSignAgreement,
  agreementSigned,
  sagaResetPassword,
  sagaResetLink,
  sagaExternalLogin,
  sagaPasswordUpdate,
  setActAsPantry,
  pantryAgreementSigned,
  setFundraisingAgreementAddendum
}

export default authActions
