import axios from 'axios'
import { CANCEL } from 'redux-saga'

const cancellableEndpoint = (url) => {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const request = axios.get(url, { cancelToken: source.token })
  request[CANCEL] = () => source.cancel()
  return request
}

export const postSkipEa = async ({ sku, shelter }) => {
  return await axios.post(`api/admin/order/${sku}/${shelter}/skip-ea`, {
    sku: sku?.toString(),
    shelter
  })
}

export const putFixLimbo = async ({
  origin_product_id: originProductId,
  origin_variant_id: originVariantId,
  new_product_id: newProductId,
  new_variant_id: newVariantId
}) => {
  const data = { new_product_id: newProductId, new_variant_id: newVariantId }
  return await axios.put(
    `api/admin/order/${originProductId}/${originVariantId}/replace`,
    data
  )
}

export const getShopifyVariants = async ({ product_id: productId }) => {
  return await axios.get(`api/admin/shopify/${productId}/variants`)
}

export const getShopifyProducts = async ({ text }) => {
  return await axios.get(`api/admin/shopify`, {
    params: { text: encodeURIComponent(text) }
  })
}

export const getLimboProducts = async () => {
  return await axios.get('api/admin/order/skus-in-limbo')
}

export const getPendingProducts = async ({ payload }) => {
  return await axios.get('api/admin/order/pending-products', {
    params: payload
  })
}

export const putParameters = async (data) => {
  return await axios.put('api/admin/order/parameters', { parameters: data })
}

export const getParameters = async () => {
  return await axios.get('api/admin/order/parameters')
}

export const getPendingOrders = async () => {
  return await axios.get('api/admin/order/pending-order')
}

export const getPendingOrdersProducts = ({
  shelter_id: shelterId,
  shipping_address_id: shippingAddressId
}) =>
  cancellableEndpoint(
    `api/admin/order/${shelterId}/${shippingAddressId}/pending-products`
  )

export const getInventoryEa = (sku) =>
  cancellableEndpoint(`api/admin/order/${sku}/ea-inventory`)

export const getCartOrderItems = (shippingAddressId) =>
  cancellableEndpoint(`api/admin/order/${shippingAddressId}/cart`)

export const addCartOrderItem = (shippingAddressId, variantId) =>
  axios.post(`api/admin/order/cart`, {
    shipping_address_id: shippingAddressId,
    variant_id: variantId
  })

export const deleteCartOrderItem = (shippingAddressId, variantId) =>
  axios.delete(`api/admin/order/cart`, {
    data: { shipping_address_id: shippingAddressId, variant_id: variantId }
  })

export const postCreateShopifyOrder = (shippingAddressId, notes) =>
  axios.post(`api/admin/order/shopify`, {
    shipping_address_id: shippingAddressId,
    notes
  })

export const getPendingOrdersReport = () =>
  cancellableEndpoint(`api/admin/order/pending-order-report`)

export const getLimboProductDetailsBySku = async (sku) => {
  return await axios.get(`api/admin/order/skus-in-limbo/${sku}`)
}

export const getDownloadLimboProductsReport = async () => {
  const response = await axios.get(`api/admin/order/skus-in-limbo/download`, {
    responseType: 'blob'
  })
  const downloadedAt = new Date().toISOString().slice(0, 10)
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `skus_in_limbo_${downloadedAt}.xls`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const getPendingOrdersReportDetails = async (id) => {
  return await axios.get(`api/admin/order/pending-order/${id}/details`)
}
