import { call, put, takeLatest, all } from 'redux-saga/effects'
import types from './types'
import actions from './actions'
import axios from 'axios'
import { generalActions } from '../general'
import errorParser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

// axios calls
const postSigUpEmailAvailability = async ({ email }) => {
  await axios.get('sanctum/csrf-cookie')

  return await axios.post('api/auth/check-email-availability', { email })
}

const postSigUpSar503Verification = async ({ ein }) => {
  await axios.get('sanctum/csrf-cookie')

  return await axios.post('api/auth/ein-verification', { ein })
}

const postSignUp = async ({ data }) => {
  await axios.get('sanctum/csrf-cookie')

  const formData = new FormData()

  Object.keys(data).forEach((k) => {
    formData.append(k, data[k])
  })

  return await axios({
    method: 'post',
    url: 'api/auth/register',
    data: formData,
    headers: { 'Content-Type': `multipart/form-data` }
  })
}

// doers
function* doPostSignUpEmailAvailability(payload) {
  try {
    yield put(actions.setSignUpIsLoading(true))
    yield call(postSigUpEmailAvailability, payload)
    yield put(actions.setSignUpEmailAvailability())
  } catch (error) {
    yield put(
      actions.setSignUpEmailAvailability(error?.response?.data[0]?.email[0])
    )
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setSignUpIsLoading())
  }
}

function* doPostSigUpSar503Verification(payload) {
  try {
    yield put(actions.setSignUpIsLoading(true))
    const response = yield call(postSigUpSar503Verification, payload)
    yield put(actions.setSignUpSar503Verification(response.data))
  } catch (error) {
    yield put(
      actions.setSignUpSar503Verification({
        address: '',
        city: '',
        name: '',
        zip: '',
        valid: false
      })
    )
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setSignUpIsLoading())
  }
}

function* doPostSignUp(payload) {
  try {
    yield put(actions.setSignUpIsLoading(true))
    yield call(postSignUp, payload)
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
    yield put(actions.setRegisterHasErrors(errorParser(error)))
  } finally {
    yield put(actions.setSignUpScreen(payload.screen))
    yield put(actions.setSignUpIsLoading())
  }
}

// watchers
function* watchPostSignUpEmailAvailability() {
  yield takeLatest(
    types.SAGA_POST_SIGN_UP_EMAIL_AVAILABILITY,
    safe(onError, doPostSignUpEmailAvailability)
  )
}

function* watchPostSigUpSar503Verification() {
  yield takeLatest(
    types.SAGA_POST_SIGN_UP_SAR_503_VERIFICATION,
    safe(onError, doPostSigUpSar503Verification)
  )
}

function* watchPostSignUp() {
  yield takeLatest(types.SAGA_POST_SIGN_UP, safe(onError, doPostSignUp))
}

export default function* rootSaga() {
  yield all([
    watchPostSignUpEmailAvailability(),
    watchPostSigUpSar503Verification(),
    watchPostSignUp()
  ])
}
