const SAGA_GET_WISHLIST = 'SAGA_GET_WISHLIST'
const SAGA_SELECT_CATEGORY = 'SAGA_SELECT_CATEGORY'
const SAGA_GET_PRODUCTS = 'SAGA_GET_PRODUCTS'
const SAGA_SAVE_WISHLIST = 'SAGA_SAVE_WISHLIST'
const SAGA_TOGGLE_WISHLIST_STATUS = 'SAGA_TOGGLE_WISHLIST_STATUS'
const SAGA_PUT_FAVORITES = 'SAGA_PUT_FAVORITES'

const SET_WISHLIST = 'SET_WISHLIST'
const RESET_WISHLIST = 'RESET_WISHLIST'
const SELECT_CATEGORY = 'SELECT_CATEGORY'
const SELECT_SUBCATEGORY = 'SELECT_SUBCATEGORY'
const SET_PRODUCTS = 'SET_PRODUCTS'
const RESET_CATEGORIES = 'RESET_CATEGORIES'
const SET_SEARCH = 'SET_SEARCH'
const TOGGLE_SUBCATEGORY = 'TOGGLE_SUBCATEGORY'
const TOGGLE_PRICE = 'TOGGLE_PRICE'
const TOGGLE_BRAND = 'TOGGLE_BRAND'
const TOGGLE_PET_TYPE = 'TOGGLE_PET_TYPE'
const RESET_NON_CATEGORIES_FILTER = 'RESET_NON_CATEGORIES_FILTER'
const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
const QUANTITY_UPDATE = 'QUANTITY_UPDATE'
const TOGGLE_MOST_NEEDED = 'TOGGLE_MOST_NEEDED'
const TOGGLE_UNLIMITED = 'TOGGLE_UNLIMITED'
const TOGGLE_WISHLIST_STATUS = 'TOGGLE_WISHLIST_STATUS'
const CLEAN_CART = 'CLEAN_CART'
const CLEAN_CART_DELETED = 'CLEAN_CART_DELETED'
const SET_WISHLIST_STATUS = 'SET_WISHLIST_STATUS'
const TOGGLE_FAVORITES = 'TOGGLE_FAVORITES'
const SET_WISHLIST_MODIFIED = 'SET_WISHLIST_MODIFIED'
const SET_HAS_VALID_SHIPPING_ADDRESS = 'SET_HAS_VALID_SHIPPING_ADDRESS'

export default {
  SAGA_GET_WISHLIST,
  SAGA_SELECT_CATEGORY,
  SAGA_GET_PRODUCTS,
  SAGA_SAVE_WISHLIST,
  SAGA_TOGGLE_WISHLIST_STATUS,
  SAGA_PUT_FAVORITES,
  SET_WISHLIST,
  RESET_WISHLIST,
  SELECT_CATEGORY,
  SELECT_SUBCATEGORY,
  SET_PRODUCTS,
  RESET_CATEGORIES,
  SET_SEARCH,
  TOGGLE_SUBCATEGORY,
  TOGGLE_PRICE,
  TOGGLE_BRAND,
  RESET_NON_CATEGORIES_FILTER,
  REMOVE_FROM_CART,
  QUANTITY_UPDATE,
  TOGGLE_MOST_NEEDED,
  TOGGLE_UNLIMITED,
  TOGGLE_WISHLIST_STATUS,
  TOGGLE_PET_TYPE,
  CLEAN_CART,
  CLEAN_CART_DELETED,
  SET_WISHLIST_STATUS,
  TOGGLE_FAVORITES,
  SET_WISHLIST_MODIFIED,
  SET_HAS_VALID_SHIPPING_ADDRESS
}
