const SAGA_CREATE_CAMPAIGN = 'SAGA_CREATE_CAMPAIGN'
const SAGA_STORE_CAMPAIGN = 'SAGA_STORE_CAMPAIGN'
const SAGA_EDIT_CAMPAIGN = 'SAGA_EDIT_CAMPAIGN'
const SAGA_UPDATE_CAMPAIGN = 'SAGA_UO_CAMPAIGN'
const SAGA_INDEX_CAMPAIGN_BREEDS = 'SAGA_INDEX_CAMPAIGN_BREEDS'
const SAGA_UPDATE_CAMPAIGN_STATUS = 'SAGA_UPDATE_CAMPAIGN_STATUS'

const SET_CAMPAIGN_OPTIONS = 'SET_CAMPAIGN_OPTIONS'
const SET_CAMPAIGN_BREEDS = 'SET_CAMPAIGN_BREEDS'
const RESET_CAMPAIGN_BREEDS = 'RESET_CAMPAIGN_BREEDS'
const UPDATE_CAMPAIGN_FORM = 'UPDATE_CAMPAIGN_FORM'
const SET_CAMPAIGN = 'SET_CAMPAIGN'
const RESET_CAMPAIGN = 'RESET_CAMPAIGN'
const UPDATE_CAMPAIGN_IMAGES = 'UPDATE_CAMPAIGN_IMAGES'
const REMOVE_CAMPAIGN_IMAGE = 'REMOVE_CAMPAIGN_IMAGE'
const UPDATE_CAMPAIGN_VIDEOS = 'UPDATE_CAMPAIGN_VIDEOS'
const REMOVE_CAMPAIGN_VIDEO = 'REMOVE_CAMPAIGN_VIDEO'

export default {
  SET_CAMPAIGN_OPTIONS,
  SET_CAMPAIGN_BREEDS,
  UPDATE_CAMPAIGN_FORM,
  SAGA_CREATE_CAMPAIGN,
  SAGA_UPDATE_CAMPAIGN,
  SET_CAMPAIGN,
  RESET_CAMPAIGN,
  SAGA_STORE_CAMPAIGN,
  SAGA_EDIT_CAMPAIGN,
  SAGA_INDEX_CAMPAIGN_BREEDS,
  RESET_CAMPAIGN_BREEDS,
  SAGA_UPDATE_CAMPAIGN_STATUS,
  UPDATE_CAMPAIGN_IMAGES,
  REMOVE_CAMPAIGN_IMAGE,
  UPDATE_CAMPAIGN_VIDEOS,
  REMOVE_CAMPAIGN_VIDEO
}
