import { call, put, takeLatest, all, delay } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from 'store/general'
import actions from './actions'
import errorParser from 'util/laravel_error_parser'

// Axios calls
const getPantryCampaignEmail = async ({ id }) => {
  return await axios.get(`/api/pantry/emails/campaign/${id}`)
}

const getCalculatePantryCampaignEmailList = async ({ id }) => {
  return await axios.get(
    `/api/pantry/emails/calculate-eligibility/${id}?type=4`
  )
}

const postPantryCampaignEmail = async (payload) => {
  return axios.post(`/api/pantry/emails/campaign`, payload)
}

// Doers
function* doGetPantryCampaignEmail(action) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getPantryCampaignEmail, action)
    yield put(actions.setPantryCampaignEmailForm(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doPostPantryCampaignEmail(action) {
  try {
    yield put(generalActions.isLoading())
    const reponse = yield call(postPantryCampaignEmail, action.payload)
    yield put(actions.resetPantryCampaignEmailForm())
    yield put(generalActions.setSuccess(reponse?.data?.message))

    if (action?.callback) {
      action.callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetCalculatePantryCampaignEmailList(action) {
  try {
    yield put(actions.setExhaustedPantryCampaignEmailList(true))
    yield delay(5000)
    const { data } = yield call(getCalculatePantryCampaignEmailList, action)
    yield put(actions.updatePantryCampaignEmailList(data))
  } catch (err) {
    yield put(actions.updatePantryCampaignEmailList([]))
    if (err?.response?.status) {
      yield put(generalActions.setError(errorParser(err)))
      yield put(generalActions.setUnauthorized(err))
    }
  } finally {
    yield put(actions.setExhaustedPantryCampaignEmailList(false))
  }
}

// Watchers
function* watchDoGetPantryCampaignEmail() {
  yield takeLatest(
    types.SAGA_GET_PANTRY_CAMPAIGN_EMAIL,
    doGetPantryCampaignEmail
  )
}

function* watchDoPostPantryCampaignEmail() {
  yield takeLatest(
    types.SAGA_POST_PANTRY_CAMPAIGN_EMAIL,
    doPostPantryCampaignEmail
  )
}

function* watchDoGetCalculatePantryCampaignEmailList() {
  yield takeLatest(
    types.SAGA_GET_CALCULATE_PANTRY_CAMPAIGN_EMAIL_LIST,
    doGetCalculatePantryCampaignEmailList
  )
}

export default function* rootSaga() {
  yield all([
    watchDoGetPantryCampaignEmail(),
    watchDoPostPantryCampaignEmail(),
    watchDoGetCalculatePantryCampaignEmailList()
  ])
}
