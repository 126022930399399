import types from './types'

export const SIGN_UP_SCREENS = {
  ACCOUNT_FORM: 'AccountForm',
  NON_PROFIT_VERIFICATION_FORM: 'NonProfitVerificationForm',
  ABOUT_FORM: 'AboutForm',
  VET_INFO_FORM: 'VetInfoForm',
  CONGRATS_SCREEN: 'CongratsScreen'
}

const INITIAL_STATE = {
  screen: SIGN_UP_SCREENS.ACCOUNT_FORM,
  form: {
    email: '',
    password: '',
    confirmPassword: '',
    ein_number: '',
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    role: 'shelter',
    help_type: 'fundraising',
    phone: '',
    orgurl: '',
    org_mission: '',
    logo: '',
    contact_name: '',
    title: '',
    vet_name: '',
    vet_business_name: '',
    vet_mail: '',
    vet_phone: '',
    acceptTerms: false
  },
  emailError: '',
  invalidEIN: false,
  isLoading: false,
  registerErrors: ''
}

const signUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SIGN_UP_EMAIL_AVAILABILITY: {
      return {
        ...state,
        form: {
          ...state.form
        },
        emailError: action.error
      }
    }

    case types.SET_SIGN_UP_SAR_503_VERIFICATION: {
      const { address, city, name, zip, valid } = action.data

      return {
        ...state,
        form: {
          ...state.form,
          address,
          city,
          name,
          state: action?.data?.state || '',
          zip: zip ? zip.substring(0, 5) : ''
        },
        invalidEIN: !valid,
        screen: SIGN_UP_SCREENS.NON_PROFIT_VERIFICATION_FORM
      }
    }

    case types.UPDATE_SIGN_UP_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          [action.key]: action.value
        }
      }
    }

    case types.RESET_SIGN_UP_FORM: {
      return {
        ...state,
        form: INITIAL_STATE.form,
        screen: SIGN_UP_SCREENS.ACCOUNT_FORM
      }
    }

    case types.SET_SIGN_UP_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }

    case types.SET_REGISTER_HAS_ERRORS: {
      return {
        ...state,
        registerErrors: action.registerErrors
      }
    }

    case types.SET_SIGN_UP_SCREEN: {
      return {
        ...state,
        screen: action.screen
      }
    }

    case types.CLEAR_FORM_AFTER_SIGN_UP: {
      return {
        ...state,
        form: INITIAL_STATE.form
      }
    }

    default:
      return state
  }
}

export default signUpReducer
