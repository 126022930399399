import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from 'store/general'
import actions from './actions'
import errorPaser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

const URL = 'api/pantry/reports/wishlist-donations'
const REPORT_URL = 'api/pantry/reports/'
const SHELTER_REPORT_URL = 'api/shelter/reports/'

// axios requests
const getDonations = async ({ payload }) => {
  return await axios.get(URL, { params: payload })
}

const getDonationReport = async () => {
  const response = await axios.get(`${URL}/download`, {
    responseType: 'blob'
  })
  const downloadedAt = new Date().toISOString().slice(0, 10)
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `donations_${downloadedAt}.xls`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const getDonationDetails = async ({ payload }) => {
  const { fundId, ...params } = payload

  return await axios.get(`${URL}/${fundId}`, {
    params
  })
}

const getPantryFullBowlsClub = async ({ payload }) => {
  const baseUrl = payload?.isShelter === true ? SHELTER_REPORT_URL : REPORT_URL

  return await axios.get(baseUrl + 'full-bowls-club', { params: payload })
}

const getDonorsReport = async () => {
  const response = await axios.get(`${URL}/donors/download`, {
    responseType: 'blob'
  })
  const downloadedAt = new Date().toISOString().slice(0, 10)
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `pantry_donors_${downloadedAt}.csv`)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

// doers
function* doGetDonations(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getDonations, payload)
    yield put(actions.setPaginator(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetDonationReport() {
  try {
    yield put(actions.setDownloading(true))
    yield call(getDonationReport)
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(actions.setDownloading(false))
  }
}

function* doGetDonationDetails(payload) {
  try {
    yield put(actions.setLoading(true))
    const response = yield call(getDonationDetails, payload)
    yield put(actions.setDetails(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* doGetPantryFullBowlClub(payload) {
  try {
    yield put(actions.setLoading(true))
    const response = yield call(getPantryFullBowlsClub, payload)
    yield put(actions.setFullBowlDetails(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* doGetDonorsReport() {
  try {
    yield put(actions.setDownloadingDonors(true))
    yield call(getDonorsReport)
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(actions.setDownloadingDonors(false))
  }
}

// watchers
function* watchGetDonations() {
  yield takeLatest(
    types.SAGA_GET_PANTRY_WISHLIST_DONATIONS,
    safe(onError, doGetDonations)
  )
}

function* watchGetDonationReport() {
  yield takeLatest(
    types.SAGA_GET_PANTRY_WISHLIST_DONATIONS_REPORT,
    safe(onError, doGetDonationReport)
  )
}

function* watchGetDonationDetails() {
  yield takeLatest(
    types.SAGA_GET_PANTRY_WISHLIST_DONATION_DETAILS,
    safe(onError, doGetDonationDetails)
  )
}

function* watchGetPantryFullBowlsClubReport() {
  yield takeLatest(
    types.SAGA_GET_FULL_BOWLS_CLUB_REPORT,
    safe(onError, doGetPantryFullBowlClub)
  )
}

function* watchGetDonorsReport() {
  yield takeLatest(
    types.SAGA_GET_PANTRY_DONORS_REPORT,
    safe(onError, doGetDonorsReport)
  )
}

export default function* rootSaga() {
  yield all([
    watchGetDonations(),
    watchGetDonationReport(),
    watchGetPantryFullBowlsClubReport(),
    watchGetDonationDetails(),
    watchGetDonorsReport()
  ])
}
