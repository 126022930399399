import types from './types'

const sagaGetThankYouEmail = (payload) => {
  return {
    type: types.SAGA_GET_THANK_YOU_EMAIL,
    payload
  }
}

const sagaPostThankYouEmail = (petId, email, fundId, form, isModal = false) => {
  return {
    type: types.SAGA_POST_THANK_YOU_EMAIL,
    petId,
    email,
    fundId,
    form,
    isModal
  }
}

const sagaGetCalculateThankYouEmailList = (id) => {
  return {
    type: types.SAGA_GET_CALCULATE_THANK_YOU_EMAIL_LIST,
    id
  }
}

const setThankYouEmailForm = (payload) => {
  return {
    type: types.SET_THANK_YOU_EMAIL_FORM,
    payload
  }
}

const updateThankYouEmailForm = (key, value) => {
  return {
    type: types.UPDATE_THANK_YOU_EMAIL_FORM,
    key,
    value
  }
}

const resetThankYouEmailForm = () => {
  return {
    type: types.RESET_THANK_YOU_EMAIL_FORM
  }
}

const setThankYouEmailFormLoading = (loading = true) => {
  return {
    type: types.SET_THANK_YOU_EMAIL_FORM_LOADING,
    loading
  }
}

const setExhaustedThankYouEmailList = (fetching) => {
  return {
    type: types.SET_EXHAUSTED_THANK_YOU_EMAIL_LIST,
    fetching
  }
}

const updateThankYouEmailList = (data) => {
  return {
    type: types.UPDATE_THANK_YOU_EMAIL_LIST,
    data
  }
}

export default {
  sagaGetThankYouEmail,
  sagaPostThankYouEmail,
  sagaGetCalculateThankYouEmailList,
  setThankYouEmailForm,
  updateThankYouEmailForm,
  resetThankYouEmailForm,
  setThankYouEmailFormLoading,
  setExhaustedThankYouEmailList,
  updateThankYouEmailList
}
