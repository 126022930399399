import { call, all, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import actions from './actions'
import { generalActions } from 'store/general'
import errorParser from 'util/laravel_error_parser'

// Axios calls
const postThankYouEmail = async ({
  petId = null,
  email,
  fundId = null,
  form = { to: '', subject: '', message: '' }
}) => {
  if (email) {
    form.to = email
  }

  if (fundId) {
    form.fundId = fundId
  }

  const url = petId
    ? `/api/shelter/${petId}/thank-you`
    : '/api/shelter/donation/thank-you'

  return await axios.post(url, form)
}

const getThankYouEmail = async ({ payload }) => {
  const { shelterId, campaignId, avoidEmailList } = payload
  const donationType = campaignId ? 'pet' : 'shelter'
  const id = donationType === 'pet' ? campaignId : shelterId
  let avoidEmailListUrl = ''

  if (avoidEmailList) {
    avoidEmailListUrl = 'avoidEmailList=true'
  }

  const url =
    avoidEmailListUrl.length > 0
      ? `api/shelter/${donationType}-donation/thank-you?id=${id}&${avoidEmailListUrl}`
      : `api/shelter/${donationType}-donation/thank-you?id=${id}`

  return await axios.get(url)
}

const getCalculateThankYouEmailList = async ({ id }) => {
  return await axios.get(
    `/api/shelter/emails/calculate-eligibility/${id}?type=2`
  )
}

// Doers
function* doPostThankYouEmail(action) {
  try {
    if (!action.isModal) {
      yield put(generalActions.isLoading())
    }

    const response = yield call(postThankYouEmail, action)
    yield put(actions.resetThankYouEmailForm())
    yield put(generalActions.setSuccess(response.data.message))
    setTimeout(() => {
      window.location.href = '/'
    }, 800)
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetThankYouEmail(action) {
  try {
    if (!action.payload.avoidEmailList) {
      yield put(generalActions.isLoading())
    }

    const response = yield call(getThankYouEmail, action)
    yield put(actions.setThankYouEmailForm(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
    yield put(actions.setThankYouEmailFormLoading(false))
  }
}

function* doGetCalculateThankYouEmailList(action) {
  try {
    yield put(actions.setExhaustedThankYouEmailList(true))
    const { data } = yield call(getCalculateThankYouEmailList, action)
    yield put(actions.updateThankYouEmailList(data))
  } catch (err) {
    yield put(actions.updateThankYouEmailList([]))
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(actions.setExhaustedThankYouEmailList(false))
  }
}

// Watchers
function* watchDoPostThankYouEmail() {
  yield takeLatest(types.SAGA_POST_THANK_YOU_EMAIL, doPostThankYouEmail)
}

function* watchDoGetThankYouEmail() {
  yield takeLatest(types.SAGA_GET_THANK_YOU_EMAIL, doGetThankYouEmail)
}

function* watchDoGetCalculateThankYouEmailList() {
  yield takeLatest(
    types.SAGA_GET_CALCULATE_THANK_YOU_EMAIL_LIST,
    doGetCalculateThankYouEmailList
  )
}

export default function* rootSaga() {
  yield all([
    watchDoPostThankYouEmail(),
    watchDoGetThankYouEmail(),
    watchDoGetCalculateThankYouEmailList()
  ])
}
