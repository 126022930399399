import types from './types'

const sagaGetCampaignUpdates = (userType, campaignId, payload) => ({
  type: types.SAGA_GET_CAMPAIGN_UPDATES,
  userType,
  campaignId,
  payload
})

const sagaGetCampaignUpdate = (userType, campaignId, updateId = null) => ({
  type: types.SAGA_GET_CAMPAIGN_UPDATE,
  userType,
  campaignId,
  updateId
})

const sagaPostCampaignUpdate = (
  userType,
  campaignId,
  formData,
  callback,
  uploadPhoto = false
) => ({
  type: types.SAGA_POST_CAMPAIGN_UPDATE,
  userType,
  campaignId,
  formData,
  callback,
  uploadPhoto
})

const sagaDeleteCampaignUpdate = (userType, payload, callback) => ({
  type: types.SAGA_DELETE_CAMPAIGN_UPDATE,
  userType,
  payload,
  callback
})

const sagaGetCampaignUpdatePreviewEmail = (userType, data, callback, templateId, updateId) => ({
  type: types.SAGA_GET_CAMPAIGN_UPDATE_PREVIEW_EMAIL,
  userType,
  data,
  callback,
  templateId,
  updateId
})

const sagaGetCalculateUpdateEmailList = (userType, id) => {
  return {
    type: types.SAGA_GET_CALCULATE_UPDATE_EMAIL_LIST,
    userType,
    id
  }
}

const setCampaignUpdates = (paginator) => ({
  type: types.SET_CAMPAIGN_UPDATES,
  paginator
})

const setCampaignUpdateForm = (data) => ({
  type: types.SET_CAMPAIGN_UPDATE_FORM,
  data
})

const updateCampaignUpdateForm = (form) => ({
  type: types.UPDATE_CAMPAIGN_UPDATE_FORM,
  form
})

const resetCampaignUpdateForm = () => ({
  type: types.RESET_CAMPAIGN_UPDATE_FORM
})

const setCampaignUpdatePreviewEmail = (preview) => ({
  type: types.SET_CAMPAIGN_UPDATE_PREVIEW_EMAIL,
  preview
})

const setExhaustedCampaignUpdateEmailList = (fetching) => ({
  type: types.EXHAUSTED_CAMPAIGN_UPDATE_EMAIL_LIST,
  fetching
})

const updateEmailList = (data) => ({
  type: types.UPDATE_CAMPAIGN_UPDATE_EMAIL_LIST,
  data
})

const setCampaignUpdateDeleting = (deleting) => ({
  type: types.SET_CAMPAIGN_UPDATE_DELETING,
  deleting
})

const actions = {
  sagaGetCampaignUpdates,
  sagaGetCampaignUpdate,
  sagaPostCampaignUpdate,
  sagaDeleteCampaignUpdate,
  sagaGetCampaignUpdatePreviewEmail,
  sagaGetCalculateUpdateEmailList,
  setCampaignUpdates,
  setCampaignUpdateForm,
  updateCampaignUpdateForm,
  resetCampaignUpdateForm,
  setCampaignUpdatePreviewEmail,
  setExhaustedCampaignUpdateEmailList,
  updateEmailList,
  setCampaignUpdateDeleting
}

export default actions
