// Sagas
const SAGA_GET_PANTRY_STATS = 'SAGA_GET_PANTRY_STATS'

// getters and setters
const SET_PANTRY_STATS = 'SET_PANTRY_STATS'

export default {
  SAGA_GET_PANTRY_STATS,
  SET_PANTRY_STATS
}
