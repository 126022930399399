import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from 'store/general'
import actions from './actions'
import errorParser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

const getURL = (userType, id = '') => {
  const type = userType === 'rescue' ? 'shelter' : userType

  return `api/${type}/campaigns/${id}/updates`
}

const getCampaignUpdates = async ({ userType, campaignId, payload }) => {
  const url = getURL(userType, campaignId)

  return await axios.get(url, { params: payload })
}

const getCampaignUpdate = ({ userType, campaignId, updateId }) => {
  let url = getURL(userType, campaignId)
  const queryText = userType === 'pantry' ? '?isPantry=true' : '?isPantry=false'

  url = updateId
    ? `${url}/${updateId}/edit${queryText}`
    : `${url}/create${queryText}`

  return axios.get(url)
}

const deleteCamUpdate = async (userType, payload) => {
  const { id, campaignId } = payload
  const url = getURL(userType, campaignId)

  return await axios.delete(`${url}/${id}`)
}

const getCalculateUpdateEmailList = async ({ userType, id }) => {
  const type = userType === 'rescue' ? 'shelter' : userType

  return await axios.get(
    `/api/${type}/emails/calculate-eligibility/${id}?type=3`
  )
}

const postCampaignUpdate = async ({
  userType,
  campaignId,
  formData,
  uploadPhoto
}) => {
  const {
    id = false,
    short_description: shortDescription,
    email_subject: emailSubject,
    send_list: sendList,
    should_email: shouldEmail,
    templateId,
    type,
    scheduleDate
  } = formData

  const bodyFormData = new FormData()

  if (uploadPhoto) {
    uploadPhoto.forEach((file) => {
      bodyFormData.append('photos[]', file)
    })
  }

  bodyFormData.append(
    'short_description',
    shortDescription === '<p><br></p>' ? '' : shortDescription
  )
  bodyFormData.append('should_email', shouldEmail ? 1 : 0)
  bodyFormData.append('email_subject', shouldEmail ? emailSubject : '')
  bodyFormData.append('send_list', shouldEmail ? sendList : '')
  bodyFormData.append('user_type', userType)
  bodyFormData.append('templateId', templateId)
  bodyFormData.append('type', type)

  if (scheduleDate) {
    bodyFormData.append('scheduleDate', scheduleDate)
  }

  const url = getURL(userType, campaignId)

  const axiosObject = {
    method: 'post',
    url: `${url}${id ? '/' + id : ''}`,
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' }
  }

  if (id) {
    bodyFormData.append('_method', 'put')
  }

  return await axios(axiosObject)
}

const getPreviewEmail = async ({ userType, data, templateId, updateId }) => {
  let type = userType === 'rescue' ? 'shelter' : userType

  // this means to get regular update email but a different template
  if (templateId === 3) {
    type = 'pantry'
  } else if (templateId === 4) {
    data.templateId = templateId
  }

  return await axios({
    method: 'post',
    url: `api/${type}/templates/preview`,
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

function* doGetCampaignUpdates(action) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getCampaignUpdates, action)
    yield put(actions.setCampaignUpdates(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetCampaignUpdate(action) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(getCampaignUpdate, action)
    yield put(actions.setCampaignUpdateForm(data))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doDeleteCampaignUpdate({ userType, payload, callback }) {
  try {
    yield put(actions.setCampaignUpdateDeleting(true))
    const { data } = yield call(deleteCamUpdate, userType, payload)
    yield put(generalActions.setSuccess(data.message))

    if (callback) {
      callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  } finally {
    yield put(actions.setCampaignUpdateDeleting(false))
  }
}

function* doGetCalculateUpdateEmailList(action) {
  try {
    yield put(actions.setExhaustedCampaignUpdateEmailList(true))
    const { data } = yield call(getCalculateUpdateEmailList, action)
    yield put(actions.updateEmailList(data))
  } catch (err) {
    yield put(actions.updateEmailList([]))
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(actions.setExhaustedCampaignUpdateEmailList(false))
  }
}

function* doPostCampaignUpdate(action) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(postCampaignUpdate, action)

    if (action?.callback) {
      action.callback()
    }
    yield put(generalActions.setSuccess(response.data.message))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetPreviewEmail(action) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getPreviewEmail, action)

    if (action?.callback) {
      action.callback(response.data.preview)
    }
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* watchDoGetCampaignUpdates() {
  yield takeLatest(
    types.SAGA_GET_CAMPAIGN_UPDATES,
    safe(onError, doGetCampaignUpdates)
  )
}

function* watchDoGetCampaignUpdate() {
  yield takeLatest(
    types.SAGA_GET_CAMPAIGN_UPDATE,
    safe(onError, doGetCampaignUpdate)
  )
}

function* watchDoDeleteCampaignUpdate() {
  yield takeLatest(
    types.SAGA_DELETE_CAMPAIGN_UPDATE,
    safe(onError, doDeleteCampaignUpdate)
  )
}

function* watchDoGetCalculateEmailList() {
  yield takeLatest(
    types.SAGA_GET_CALCULATE_UPDATE_EMAIL_LIST,
    doGetCalculateUpdateEmailList
  )
}

function* watchDoPostCampaignUpdate() {
  yield takeLatest(
    types.SAGA_POST_CAMPAIGN_UPDATE,
    safe(onError, doPostCampaignUpdate)
  )
}

function* watchDoGetPreviewEmail() {
  yield takeLatest(
    types.SAGA_GET_CAMPAIGN_UPDATE_PREVIEW_EMAIL,
    safe(onError, doGetPreviewEmail)
  )
}

export default function* rootSaga() {
  yield all([
    watchDoGetCampaignUpdates(),
    watchDoGetCampaignUpdate(),
    watchDoDeleteCampaignUpdate(),
    watchDoGetCalculateEmailList(),
    watchDoPostCampaignUpdate(),
    watchDoGetPreviewEmail()
  ])
}
