import types from './types'

const INITIAL_STATE = {
  stats: {
    campaigns: {
      active: 0,
      inactive: 0
    },
    donations: {
      products: 0,
      meals: 0
    },
    donors: {
      new: 0,
      repeat: 0
    },
    fullbowls: {
      subscribers: 0,
      amount: 0
    }
  }
}

const pantryStatsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PANTRY_STATS: {
      return {
        ...state,
        stats: action.pantryStats
      }
    }

    default:
      return state
  }
}

export default pantryStatsReducer
