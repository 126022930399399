import types from './types'

const INITIAL_STATE = {
  loading: false,
  metrics: {
    connected: {
      paypal_payments: 0,
      stripe_payments: 0
    },
    non_connected: {
      paypal_payments: 0,
      stripe_payments: 0
    },
    ecommerce: {
      paypal_payments: 0,
      stripe_payments: 0
    }
  },
  paypalPayoutAmount: 0,
  stripePayoutAmount: 0,
  refunds: [],
  metricsByType: {
    avg: 0,
    total: 0,
    data: []
  }
}

export default function generalMetricsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_GENERAL_METRICS: {
      const {
        connected,
        non_connected: nonConnected,
        ecommerce
      } = action.payload

      return {
        ...state,
        metrics: {
          connected: connected[0] || {},
          non_connected: nonConnected[0] || {},
          ecommerce: ecommerce[0] || {}
        }
      }
    }

    case types.SET_GENERAL_METRICS_BY_TYPE: {
      const { avg, total, data } = action.payload

      return {
        ...state,
        metricsByType: {
          total,
          avg,
          data
        }
      }
    }

    case types.SET_GENERAL_METRICS_BY_TYPE_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }

    case types.UPDATE_PAYPAL_FORM: {
      const { value } = action
      return {
        ...state,
        paypalPayoutAmount: value
      }
    }

    case types.UPDATE_STRIPE_FORM: {
      const { value } = action
      return {
        ...state,
        stripePayoutAmount: value
      }
    }

    default:
      return state
  }
}
