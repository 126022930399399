// sagas
const SAGA_POST_SIGN_UP_EMAIL_AVAILABILITY =
  'SAGA_POST_SIGN_UP_EMAIL_AVAILABILITY'
const SAGA_POST_SIGN_UP_SAR_503_VERIFICATION =
  'SAGA_POST_SIGN_UP_SAR_503_VERIFICATION'
const SAGA_POST_SIGN_UP = 'SAGA_POST_SIGN_UP'

// getters and setters
const UPDATE_SIGN_UP_FORM = 'UPDATE_SIGN_UP_FORM'
const RESET_SIGN_UP_FORM = 'RESET_SIGN_UP_FORM'
const SET_SIGN_UP_IS_LOADING = 'SET_SIGN_UP_IS_LOADING'
const SET_REGISTER_HAS_ERRORS = 'SET_REGISTER_HAS_ERRORS'
const SET_SIGN_UP_EMAIL_AVAILABILITY = 'SET_SIGN_UP_EMAIL_AVAILABILITY'
const SET_SIGN_UP_SAR_503_VERIFICATION = 'SET_SIGN_UP_SAR_503_VERIFICATION'
const SET_SIGN_UP_SCREEN = 'SET_SIGN_UP_SCREEN'
const CLEAR_FORM_AFTER_SIGN_UP = 'CLEAR_FORM_AFTER_SIGN_UP'

export default {
  SAGA_POST_SIGN_UP_EMAIL_AVAILABILITY,
  SAGA_POST_SIGN_UP_SAR_503_VERIFICATION,
  SAGA_POST_SIGN_UP,
  UPDATE_SIGN_UP_FORM,
  RESET_SIGN_UP_FORM,
  SET_SIGN_UP_IS_LOADING,
  SET_REGISTER_HAS_ERRORS,
  SET_SIGN_UP_EMAIL_AVAILABILITY,
  SET_SIGN_UP_SAR_503_VERIFICATION,
  SET_SIGN_UP_SCREEN,
  CLEAR_FORM_AFTER_SIGN_UP
}
