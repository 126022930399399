import { call, put } from 'redux-saga/effects'
import errorPaser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { getParameters } from './api'
import actions from '../actions'

function* doGetParameters(payload) {
  yield put(generalActions.isLoading())
  try {
    const response = yield call(getParameters)
    yield put(actions.setParameters(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

export default doGetParameters
