const IS_LOADING = 'IS_LOADING'
const IS_NOT_LOADING = 'IS_NOT_LOADING'
const SET_ERROR = 'SET_ERROR'
const SET_SUCCESS = 'SET_SUCCESS'
const SET_ALERT = 'SET_ALERT'
const SET_UNAUTHORIZED = 'SET_UNAUTHORIZED'
const SET_IS_NEW_VERSION = 'SET_IS_NEW_VERSION'

const _default = {
  IS_LOADING,
  IS_NOT_LOADING,
  SET_ERROR,
  SET_SUCCESS,
  SET_ALERT,
  SET_UNAUTHORIZED,
  SET_IS_NEW_VERSION
}

export default _default
