import types from './types'

const sagaEmailIndex = (payload) => {
  return {
    type: types.SAGA_EMAIL_INDEX,
    payload
  }
}

const sagaSarEmailIndex = (payload) => {
  return {
    type: types.SAGA_SAR_EMAIL_INDEX,
    payload
  }
}

const sagaSarEmailListHistoryReportingIndex = (payload) => {
  return {
    type: types.SAGA_SAR_EMAIL_LIST_HISTORY_INDEX,
    payload
  }
}

const sagaVerifyEmails = (payload, callback) => {
  return {
    type: types.SAGA_VERIFY_EMAILS,
    payload,
    callback
  }
}

const sagaSarEmailAnalytics = (payload, userType) => {
  return {
    type: types.SAGA_SAR_EMAIL_ANALYTICS,
    payload,
    userType
  }
}

const sagaSarEmailAnalyticsPerEmail = (payload, userType) => {
  return {
    type: types.SAGA_SAR_EMAIL_ANALYTICS_PER_EMAIL,
    payload,
    userType
  }
}

const sagaArchiveSarEmailAnalytics = (payload) => {
  return {
    type: types.SAGA_ARCHIVE_SAR_EMAIL_ANALYTICS,
    payload
  }
}

const setPaginator = (paginator) => {
  return {
    type: types.SET_PAGINATOR,
    paginator
  }
}

const setPaginatorPerEmail = (paginator) => {
  return {
    type: types.SET_PAGINATOR_PER_EMAIL,
    paginatorPerEmail: paginator
  }
}

const setCurrent = (current) => {
  return {
    type: types.SET_CURRENT,
    current
  }
}

const sagaSarEmailUpdate = (payload, callback) => {
  return {
    type: types.SAGA_SAR_EMAIL_UPDATE,
    payload,
    callback
  }
}

const sagaScheduleEmailUpdate = (payload) => {
  return {
    type: types.SAGA_SAR_SCHEDULE_EMAIL_UPDATE,
    payload
  }
}

const sagaSarEmailDelete = (payload, callback) => {
  return {
    type: types.SAGA_SAR_EMAIL_DELETE,
    payload,
    callback
  }
}

const sagaRevalidate = (payload) => {
  return {
    type: types.SAGA_SAR_RE_VALIDATE,
    payload
  }
}

const emailActions = {
  sagaEmailIndex,
  setPaginator,
  sagaSarEmailListHistoryReportingIndex,
  sagaVerifyEmails,
  sagaSarEmailAnalytics,
  sagaSarEmailAnalyticsPerEmail,
  sagaArchiveSarEmailAnalytics,
  sagaSarEmailIndex,
  sagaRevalidate,
  setCurrent,
  sagaSarEmailUpdate,
  sagaScheduleEmailUpdate,
  sagaSarEmailDelete,
  setPaginatorPerEmail
}

export default emailActions
