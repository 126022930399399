import { call, all, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import actions from './actions'
import { generalActions } from 'store/general'
import errorParser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

const URL = '/api/pantry/emails/thank-you'

// Axios calls
const getPantryThankYouEmail = async ({ payload }) => {
  const { campaignId } = payload

  return await axios.get(`${URL}/${campaignId}`)
}

const postPantryThankYouEmail = async (payload) => {
  return await axios.post(URL, payload)
}

const getCalculatePantryThankYouEmailList = async ({ id }) => {
  return await axios.get(
    `/api/pantry/emails/calculate-eligibility/${id}?type=2`
  )
}

// Doers
function* doGetPantryThankYouEmail(action) {
  try {
    yield put(actions.setPantryThankYouEmailFormLoading(true))
    const response = yield call(getPantryThankYouEmail, action)
    yield put(actions.setPantryThankYouEmailForm(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
    yield put(actions.setPantryThankYouEmailFormLoading(false))
  }
}

function* doPostPantryThankYouEmail(action) {
  const { payload } = action

  try {
    const response = yield call(postPantryThankYouEmail, payload)
    yield put(generalActions.setSuccess(response.data.message))

    if (payload?.callback) {
      payload.callback(payload.fundId)
    }
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    if (payload?.callback) {
      payload.callback(null)
    }
  }
}

function* doGetCalculatePantryThankYouEmailList(action) {
  try {
    yield put(actions.setExhaustedPantryThankYouEmailList(true))
    const { data } = yield call(getCalculatePantryThankYouEmailList, action)
    yield put(actions.updatePantryThankYouEmailList(data))
  } catch (err) {
    yield put(actions.updatePantryThankYouEmailList([]))
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(actions.setExhaustedPantryThankYouEmailList(false))
  }
}

// Watchers
function* watchDoGetPantryThankYouEmail() {
  yield takeLatest(
    types.SAGA_GET_PANTRY_THANK_YOU_EMAIL,
    safe(onError, doGetPantryThankYouEmail)
  )
}

function* watchDoPostPantryThankYouEmail() {
  yield takeLatest(
    types.SAGA_POST_PANTRY_THANK_YOU_EMAIL,
    safe(onError, doPostPantryThankYouEmail)
  )
}

function* watchDoGetCalculatePantryThankYouEmailList() {
  yield takeLatest(
    types.SAGA_GET_CALCULATE_PANTRY_THANK_YOU_EMAIL_LIST,
    doGetCalculatePantryThankYouEmailList
  )
}

export default function* rootSaga() {
  yield all([
    watchDoGetPantryThankYouEmail(),
    watchDoPostPantryThankYouEmail(),
    watchDoGetCalculatePantryThankYouEmailList()
  ])
}
