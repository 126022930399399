import PropTypes from 'prop-types'
import { memo } from 'react'
import { SpinnerIcon } from 'components/common/icons'

const SpinnerLoader = ({ widthClass, heightClass }) => {
  return (
    <div
      className={`bg-white flex items-center justify-center ${widthClass} ${heightClass}`}
    >
      <SpinnerIcon className="animate-spin inline-block -ml-1 mr-3 h-6 w-6 text-cuddly-500" />
    </div>
  )
}

SpinnerLoader.propTypes = {
  widthClass: PropTypes.string,
  heightClass: PropTypes.string
}

SpinnerLoader.defaultProps = {
  widthClass: 'w-full',
  heightClass: 'h-screen'
}
export default memo(SpinnerLoader)
