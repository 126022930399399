import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from 'store/general'
import { authActions } from 'store/auth'
import actions from './actions'
import errorPaser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

const URL = 'api/pantry/agreements'

// requests
const postPantryAgreement = async ({ data }) => {
  return await axios.post(URL, data)
}

// doers
function* doPostPantryAgreement(action) {
  try {
    yield put(actions.setPantryAgreementLoading(true))
    const { data } = yield call(postPantryAgreement, action)
    yield put(authActions.pantryAgreementSigned())
    yield put(generalActions.setSuccess(data.message))

    if (action?.callback) {
      action.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(errorPaser(error)))
  } finally {
    yield put(actions.setPantryAgreementLoading(false))
  }
}

// watchers
function* watchPostPantryAgreement() {
  yield takeLatest(
    types.SAGA_POST_PANTRY_AGREEMENT,
    safe(onError, doPostPantryAgreement)
  )
}

export default function* rootSaga() {
  yield all([watchPostPantryAgreement()])
}
