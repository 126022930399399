import types from './types'

const INITIAL_STATE = {
  form: {
    is_active: false,
    is_draft: true,
    name: '',
    wish_name: '',
    wish_amount: '',
    photos: [],
    videos: [],
    description: '',
    removed_photos: [],
    removed_videos: [],
    weekly_wish: false,
    urgent_needs: false
  },
  campaigns: [],
  currentPage: 1,
  lastPage: 1,
  nextPage: 2,
  loading: false,
  canLoadMore: false,
  active: 0,
  drafts: 0,
  inactive: 0,
  defaultTab: 'draft'
}

const pantryCampaingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PANTRY_CAMPAIGN: {
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data
        }
      }
    }

    case types.SET_PANTRY_CAMPAIGN_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          [action.key]: action.value
        }
      }
    }

    case types.RESET_PANTRY_CAMPAIGN_FORM: {
      return {
        ...state,
        form: {
          ...INITIAL_STATE.form
        }
      }
    }

    case types.SET_REMOVE_PANTRY_CAMPAIGN_PHOTO: {
      const tmp = [...state.form.removed_photos]
      tmp.push(action.id)

      return {
        ...state,
        form: {
          ...state.form,
          removed_photos: [...new Set(tmp)]
        }
      }
    }

    case types.SET_REMOVE_PANTRY_CAMPAIGN_VIDEO: {
      const tmp = [...state.form.removed_videos]
      tmp.push(action.id)

      return {
        ...state,
        form: {
          ...state.form,
          removed_videos: [...new Set(tmp)]
        }
      }
    }

    case types.SET_PANTRY_CAMPAIGNS: {
      const result = action.data
      const currentPage = result?.meta?.current_page || 1
      const lastPage = result?.meta?.last_page || 1
      const nextPage = (result?.meta?.current_page || 1) + 1
      const canLoadMore = currentPage < lastPage
      const tmp = [...state.campaigns]
      const currentCampaignIds = new Set(tmp.map(({ id }) => id))
      const campaigns = [
        ...tmp,
        ...result?.data?.filter(
          (campaign) => !currentCampaignIds.has(campaign.id)
        )
      ]

      return {
        ...state,
        campaigns,
        currentPage,
        lastPage,
        nextPage: nextPage > lastPage ? lastPage : nextPage,
        canLoadMore,
        active: result?.active || 0,
        drafts: result?.drafts || 0,
        inactive: result?.inactive || 0,
        defaultTab: result?.drafts > 0 ? 'draft' : 'active'
      }
    }

    case types.SET_PANTRY_CAMPAIGNS_LOADING: {
      return {
        ...state,
        loading: action.loading
      }
    }

    case types.RESET_PANTRY_CAMPAIGNS: {
      return {
        ...state,
        campaigns: [],
        currentPage: 1,
        lastPage: 0,
        nextPage: 2,
        canLoadMore: false
      }
    }

    default:
      return state
  }
}

export default pantryCampaingReducer
