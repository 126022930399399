const SAGA_GET_ORDERS_REPORT = 'SAGA_GET_ORDERS_REPORT'
const SAGA_GET_ORDERS_REPORT_DETAILS = 'SAGA_GET_ORDERS_REPORT_DETAILS'
const SAGA_GET_ORDERS_REPORT_DOWNLOAD = 'SAGA_GET_ORDERS_REPORT_DOWNLOAD'

const IS_LOADING_ORDERS_REPORT = 'IS_LOADING_ORDERS_REPORT'
const IS_LOADING_ORDERS_REPORT_DETAILS = 'IS_LOADING_ORDERS_REPORT_DETAILS'
const IS_CREATING_ORDERS_REPORT = 'IS_CREATING_ORDERS_REPORT'
const SET_ORDERS_REPORT = 'SET_ORDERS_REPORT'
const SET_ORDERS_REPORT_DETAILS = 'SET_ORDERS_REPORT_DETAILS'
const RESET_ORDERS_REPORT = 'RESET_ORDERS_REPORT'
const RESET_ORDERS_REPORT_DETAILS = 'RESET_ORDERS_REPORT_DETAILS'

export default {
  SAGA_GET_ORDERS_REPORT,
  SAGA_GET_ORDERS_REPORT_DETAILS,
  SAGA_GET_ORDERS_REPORT_DOWNLOAD,
  IS_LOADING_ORDERS_REPORT,
  IS_LOADING_ORDERS_REPORT_DETAILS,
  IS_CREATING_ORDERS_REPORT,
  SET_ORDERS_REPORT,
  SET_ORDERS_REPORT_DETAILS,
  RESET_ORDERS_REPORT,
  RESET_ORDERS_REPORT_DETAILS
}
