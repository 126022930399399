/* eslint-disable no-unused-vars */
import { call, put, select, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from '../general'
import actions from './actions'
import errorPaser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'
import { toastr } from 'react-redux-toastr'

const readWishlistFromStore = (state) => state.wishlist

/**
 * AXIOS Calls
 */

const getToggleWishlistStatus = async ({ id, userType }) => {
  const type = userType === 'rescue' ? 'shelter' : userType

  return await axios.get(`api/${type}/wishlist/${id}/toggle_status`)
}

const getWishlist = async (
  { id, page, firstTime, userType, shippingAddressId },
  {
    selectedCategory,
    selectedSubcategories,
    search,
    selectedPrices,
    selectedBrands,
    selectedPetTypes,
    onlyFavorites
  }
) => {
  const obj = {}
  const type = userType === 'rescue' ? 'shelter' : userType

  if (selectedCategory.id) {
    obj.category_id = selectedCategory.id
  }

  if (search) {
    obj.query_text = search
  }

  if (selectedSubcategories.length > 0) {
    obj.subcategories_id = selectedSubcategories.join(',')
  }

  if (selectedPrices.length > 0) {
    obj.prices_index = selectedPrices.join(',')
  }

  if (selectedBrands.length > 0) {
    obj.brand_names = selectedBrands.join(',')
  }

  if (selectedPetTypes.length > 0) {
    obj.pet_types = selectedPetTypes.join(',')
  }

  if (page >= 0) {
    obj.page = page
  }

  if (firstTime === true) {
    obj.first_time = true
  }

  if (onlyFavorites) {
    obj.only_favorites = onlyFavorites
  }

  obj.shipping_address_id = shippingAddressId

  return await axios.get(`api/${type}/wishlist/${id}`, { params: obj })
}

const postWishlist = async (
  { id, userType },
  { shippingAddressId, cart, cartDelete }
) => {
  const type = userType === 'rescue' ? 'shelter' : userType
  const items = cart.map(
    ({
      id: variantId, // eslint-disable-next-line camelcase
      item_id = false,
      cart: quantity,
      most_needed: mostNeeded,
      unlimited,
      product
    }) => {
      return {
        item_id,
        variant_id: variantId,
        quantity,
        most_needed: mostNeeded,
        unlimited,
        product_id: product.id
      }
    }
  )

  const obj = {
    pet_id: id,
    items,
    cart_delete: cartDelete,
    shipping_address_id: shippingAddressId
  }

  return await axios.post(`api/${type}/wishlist`, obj)
}

const putFavorites = async ({ productId, isFavorite, userType }) => {
  const type = userType === 'rescue' ? 'shelter' : userType

  return await axios.put(`api/${type}/wishlist/favorites`, {
    product_id: productId,
    is_favorite: isFavorite
  })
}
/**
 * Doers
 */
function* doGetWishlist(action) {
  yield put(generalActions.isLoading())
  yield put(actions.setProducts({}))
  const wishlist = yield select(readWishlistFromStore)

  try {
    const response = yield call(getWishlist, action, wishlist)

    yield put(actions.setWishlist(response.data, action.firstTime))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  }
}

function* doPostWishlist(action) {
  yield put(generalActions.isLoading())
  const wishlist = yield select(readWishlistFromStore)
  wishlist.shippingAddressId = action.shippingAddressId

  try {
    const response = yield call(postWishlist, action, wishlist)

    if (action.callback) {
      action.callback()
    }

    yield put(actions.cleanCartDeleted())
    yield put(generalActions.setSuccess(response.data.message))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    if (err?.response?.status === 422) {
      toastr.error('', err?.response?.data?.errors)
    }

    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doGetToggleWishlistStatus(action) {
  try {
    yield call(getToggleWishlistStatus, action)
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doPutFavorites(action) {
  try {
    yield call(putFavorites, action)
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

/** Watchers **/
function* watchDoGetWishlist() {
  yield takeLatest(types.SAGA_GET_WISHLIST, doGetWishlist)
}

function* watchDoPostWishlist() {
  yield takeLatest(types.SAGA_SAVE_WISHLIST, doPostWishlist)
}

function* watchDoGetToggleWishlistStatus() {
  yield takeLatest(types.SAGA_TOGGLE_WISHLIST_STATUS, doGetToggleWishlistStatus)
}

function* watchDoPutFavorites() {
  yield takeLatest(types.SAGA_PUT_FAVORITES, safe(onError, doPutFavorites))
}

/**
 * Exporting all watchers
 */
export default function* rootSaga() {
  yield all([
    watchDoGetWishlist(),
    watchDoPostWishlist(),
    watchDoGetToggleWishlistStatus(),
    watchDoPutFavorites()
  ])
}
