import types from './types'

const initialState = {
  thankYouForm: {
    to: '',
    subject: '',
    message: '',
    subjects: [],
    loading: false,
    exhaustedEmailList: false,
    emailList: []
  }
}

const thankYouEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PANTRY_THANK_YOU_EMAIL_FORM: {
      const { key, value } = action

      return {
        thankYouForm: {
          ...state.thankYouForm,
          [key]: value
        }
      }
    }

    case types.RESET_PANTRY_THANK_YOU_EMAIL_FORM: {
      return {
        thankYouForm: {
          ...state.thankYouForm,
          to: '',
          subject: '',
          message: ''
        }
      }
    }

    case types.SET_PANTRY_THANK_YOU_EMAIL_FORM: {
      return {
        thankYouForm: {
          ...state.thankYouForm,
          ...action.payload,
          message: action.payload.message || ''
        }
      }
    }

    case types.SET_PANTRY_THANK_YOU_EMAIL_FORM_LOADING: {
      return {
        thankYouForm: {
          ...state.thankYouForm,
          loading: action.loading
        }
      }
    }

    case types.SET_EXHAUSTED_PANTRY_THANK_YOU_EMAIL_LIST: {
      return {
        thankYouForm: {
          ...state.thankYouForm,
          exhaustedEmailList: action.exhausted
        }
      }
    }

    case types.UPDATE_PANTRY_THANK_YOU_EMAIL_LIST: {
      let tmp = action?.data?.email_list || []

      if (tmp && tmp?.length === 0) {
        tmp = state.thankYouForm?.email_list?.map(({ value, label }) => {
          return {
            value,
            label: `${label} (N/A)`
          }
        })

        return {
          thankYouForm: {
            ...state.thankYouForm,
            emailList: tmp,
            exhaustedEmailList: false
          }
        }
      }

      return {
        thankYouForm: {
          ...state.thankYouForm,
          emailList: action?.data?.email_list || [],
          exhaustedEmailList: false
        }
      }
    }

    default:
      return state
  }
}

export default thankYouEmailReducer
