import { call, put, takeLatest, all } from 'redux-saga/effects'
import types from './types'
import actions from './actions'
import axios from 'axios'
import { generalActions } from 'store/general'
import errorParser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'
import { toastr } from 'react-redux-toastr'

const URL = '/api/admin/users'

// Axios calls
const getUsers = async ({ payload }) => {
  return await axios.get(URL, { params: payload })
}

const patchUsers = async ({ payload }) => {
  const { id, ...rest } = payload

  return await axios.patch(`${URL}/${id}`, rest)
}

// Doers
function* doGetUsers(payload) {
  try {
    yield put(actions.setUsersLoading(true))
    const { data } = yield call(getUsers, payload)
    yield put(actions.setUsers(data))
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setUsersLoading(false))
  }
}

function* doPatchUsers(payload) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(patchUsers, payload)
    toastr.success('', data?.message || 'User updated successfully.')

    if (payload?.callback) {
      payload.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

// Watchers
function* watchGetUsers() {
  yield takeLatest(types.SAGA_GET_USERS, safe(onError, doGetUsers))
}

function* watchPatchUsers() {
  yield takeLatest(types.SAGA_PATCH_USER, safe(onError, doPatchUsers))
}

export default function* rootSaga() {
  yield all([watchGetUsers(), watchPatchUsers()])
}
