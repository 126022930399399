import types from './types'

const INITIAL_STATE = {
  selectedCategory: {},
  selectedSubcategories: [],
  search: '',
  selectedPrices: [],
  selectedBrands: [],
  selectedPetTypes: [],
  cart: [],
  cartDelete: [],
  categories: [],
  petTypes: [],
  brands: [],
  prices: [],
  products: {},
  wishlist: {},
  onlyFavorites: 0,
  modified: false,
  hasValidShippingAddress: false
}

const wishlistReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_WISHLIST: {
      const {
        pet_types: petTypes,
        brands,
        prices,
        products,
        species,
        wishlist,
        shipping_address: shippingAddress
      } = action.data

      let cart = JSON.parse(JSON.stringify(state.cart))
      let hasValidShippingAddress = state.hasValidShippingAddress

      if (action?.first_time && wishlist?.items) {
        cart = wishlist.items
      }

      let selectedPetTypes = state.selectedPetTypes

      if (action.first_time) {
        selectedPetTypes = [species]
        hasValidShippingAddress =
          !wishlist?.shipping_address?.is_deleted || true
      }

      if (wishlist && shippingAddress) {
        wishlist.shipping_address_id = +shippingAddress
      }

      return {
        ...state,
        categories:
          action.data.categories.length > 0
            ? action.data.categories
            : state.categories,
        petTypes,
        brands,
        prices,
        products,
        cart,
        selectedCategory: state.selectedCategory.id
          ? state.selectedCategory
          : INITIAL_STATE.selectedCategory,
        selectedSubcategories:
          state.selectedSubcategories.length > 0
            ? state.selectedSubcategories
            : INITIAL_STATE.selectedSubcategories,
        selectedPetTypes,
        wishlist: wishlist || INITIAL_STATE.wishlist,
        hasValidShippingAddress
      }
    }

    case types.RESET_WISHLIST: {
      return INITIAL_STATE
    }

    case types.SELECT_CATEGORY: {
      let selectedCategory = {}

      for (let i = 0; i < state.categories.length; i++) {
        if (state.categories[i].id === action.id) {
          selectedCategory = state.categories[i]
        }
      }

      return {
        ...state,
        selectedCategory,
        selectedSubcategories: INITIAL_STATE.selectedSubcategories
      }
    }

    case types.SET_PRODUCTS: {
      return {
        ...state,
        products: action.data
      }
    }

    case types.RESET_CATEGORIES: {
      return {
        ...state,
        selectedCategory: INITIAL_STATE.selectedCategory,
        selectedSubcategories: INITIAL_STATE.selectedSubcategories
      }
    }

    case types.SET_SEARCH: {
      return {
        ...state,
        search: action.search
      }
    }

    case types.TOGGLE_SUBCATEGORY: {
      const id = action.id
      const selectedSubcategories = JSON.parse(
        JSON.stringify(state.selectedSubcategories)
      )

      if (selectedSubcategories.find((e) => e === id)) {
        selectedSubcategories.remove(id)
      } else {
        selectedSubcategories.push(id)
      }

      return {
        ...state,
        selectedSubcategories
      }
    }

    case types.TOGGLE_PRICE: {
      const index = action.id
      const selectedPrices = state.selectedPrices

      if (selectedPrices.find((e) => e === index) !== undefined) {
        selectedPrices.remove(index)
      } else {
        selectedPrices.push(index)
      }

      return {
        ...state,
        selectedPrices
      }
    }

    case types.TOGGLE_BRAND: {
      const name = action.name
      const selectedBrands = state.selectedBrands

      if (selectedBrands.find((e) => e === name) !== undefined) {
        selectedBrands.remove(name)
      } else {
        selectedBrands.push(name)
      }

      return {
        ...state,
        selectedBrands
      }
    }

    case types.TOGGLE_PET_TYPE: {
      const name = action.name
      const selectedPetTypes = state.selectedPetTypes

      if (selectedPetTypes.find((e) => e === name) !== undefined) {
        selectedPetTypes.remove(name)
      } else {
        selectedPetTypes.push(name)
      }

      return {
        ...state,
        selectedPetTypes
      }
    }

    case types.RESET_NON_CATEGORIES_FILTER: {
      return {
        ...state,
        search: INITIAL_STATE.search,
        selectedPrices: INITIAL_STATE.selectedPrices,
        selectedBrands: INITIAL_STATE.selectedBrands
      }
    }

    case types.REMOVE_FROM_CART: {
      const itemToRemove = state.cart.find((item) => item.id === action.id)
      const cartDelete = itemToRemove
        ? [...state.cartDelete, itemToRemove]
        : [...state.cartDelete]
      const cart = state.cart.filter((item) => item.id !== action.id)

      return {
        ...state,
        cart,
        cartDelete
      }
    }

    case types.QUANTITY_UPDATE: {
      const cart = JSON.parse(JSON.stringify(state.cart))
      const { product, variant, quantity } = action
      const index = cart.findIndex((e) => e.id === variant.id)
      if (index >= 0) {
        cart[index].cart = quantity
      } else {
        variant.cart = quantity
        variant.product = {
          id: product.id,
          product_id: product.product_id,
          title: product.title,
          vendor: product.vendor,
          tags: product.tags,
          image: product.product_image ? product.product_image : false
        }
        cart.push(variant)
      }

      return {
        ...state,
        cart
      }
    }
    case types.TOGGLE_MOST_NEEDED: {
      const cart = JSON.parse(JSON.stringify(state.cart))
      const index = cart.findIndex((e) => e.id === action.id)

      if (index >= 0) {
        cart[index].most_needed =
          cart[index].most_needed !== undefined
            ? !cart[index].most_needed
            : true
      }

      return {
        ...state,
        cart
      }
    }

    case types.TOGGLE_UNLIMITED: {
      const cart = JSON.parse(JSON.stringify(state.cart))
      const index = cart.findIndex((e) => e.id === action.id)

      if (index >= 0) {
        cart[index].unlimited =
          cart[index].unlimited !== undefined ? !cart[index].unlimited : true
      }

      return {
        ...state,
        cart
      }
    }

    case types.TOGGLE_WISHLIST_STATUS: {
      const newStatus =
        state.wishlist?.status === 'active' ? 'inactive' : 'active'

      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          status: newStatus
        }
      }
    }

    case types.CLEAN_CART: {
      return {
        ...state,
        cart: []
      }
    }

    case types.CLEAN_CART_DELETED: {
      return {
        ...state,
        cartDelete: []
      }
    }

    case types.SET_WISHLIST_STATUS: {
      const { status } = action

      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          status
        }
      }
    }

    case types.TOGGLE_FAVORITES: {
      return {
        ...state,
        onlyFavorites: state.onlyFavorites === 0 ? 1 : 0
      }
    }

    case types.SET_WISHLIST_MODIFIED: {
      return {
        ...state,
        modified: action.modified
      }
    }

    case types.SET_HAS_VALID_SHIPPING_ADDRESS: {
      return {
        ...state,
        hasValidShippingAddress: action.hasValidShippingAddress
      }
    }

    default:
      return state
  }
}

export default wishlistReducer
