import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from 'store/general'
import actions from './actions'
import errorPaser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

// requests
const getPantryStats = async () => {
  return await axios.get('api/pantry/statistics')
}

// doers
function* doGetPantryStats() {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getPantryStats)
    yield put(actions.setPantryStats(response.data))
  } catch (error) {
    yield put(generalActions.setError(errorPaser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

// watchers
function* watchDoGetPantryStats() {
  yield takeLatest(types.SAGA_GET_PANTRY_STATS, safe(onError, doGetPantryStats))
}

export default function* rootSaga() {
  yield all([watchDoGetPantryStats()])
}
