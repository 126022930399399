import types from './types'

const sagaGetOrdersReport = (userType, payload) => ({
  type: types.SAGA_GET_ORDERS_REPORT,
  userType,
  payload
})

const sagaGetOrdersReportDetails = (payload) => ({
  type: types.SAGA_GET_ORDERS_REPORT_DETAILS,
  payload
})

const sagaGetOrdersReportDownload = (userType) => ({
  type: types.SAGA_GET_ORDERS_REPORT_DOWNLOAD,
  userType
})

const isLoadingOrdersReport = (isLoading = false) => ({
  type: types.IS_LOADING_ORDERS_REPORT,
  isLoading
})

const isLoadingOrdersReportDetails = (isLoading = false) => ({
  type: types.IS_LOADING_ORDERS_REPORT_DETAILS,
  isLoadingDetails: isLoading
})

const isCreatingOrdersReport = (isCreating = false) => ({
  type: types.IS_CREATING_ORDERS_REPORT,
  isCreating
})

const setOrdersReport = (data) => ({
  type: types.SET_ORDERS_REPORT,
  data
})

const setOrdersReportDetails = (data) => ({
  type: types.SET_ORDERS_REPORT_DETAILS,
  data
})

const resetOrdersReport = () => ({
  type: types.RESET_ORDERS_REPORT
})

const resetOrdersReportDetails = () => ({
  type: types.RESET_ORDERS_REPORT_DETAILS
})

export default {
  sagaGetOrdersReport,
  sagaGetOrdersReportDetails,
  sagaGetOrdersReportDownload,
  isLoadingOrdersReport,
  isLoadingOrdersReportDetails,
  isCreatingOrdersReport,
  setOrdersReport,
  setOrdersReportDetails,
  resetOrdersReport,
  resetOrdersReportDetails
}
