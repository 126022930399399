import { call, put } from 'redux-saga/effects'
import errorPaser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { getPendingOrders } from './api'
import actions from '../actions'

function* doGetPendingOrders() {
  yield put(generalActions.isLoading())
  try {
    const response = yield call(getPendingOrders)
    yield put(actions.putPendingOrders(response.data.data))
    yield put(actions.setNextRun(response.data.next_run))
    yield put(generalActions.setSuccess(response.data.message))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  }
}

export default doGetPendingOrders
