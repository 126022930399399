import { post, put, get, destroy } from 'api/apiConfig'

const CAMPAIGNS_ENDPOINT = '/sar/campaigns'

export const postCampaign = async (payload) => {
  return await post(CAMPAIGNS_ENDPOINT, payload)
}

export const putCampaign = async (id, payload) => {
  return await put(`${CAMPAIGNS_ENDPOINT}/${id}`, payload)
}

export const getCampaign = async (id) => {
  return await get(`${CAMPAIGNS_ENDPOINT}/${id}`)
}

export const getCampaignOptions = async () => {
  return await get(`${CAMPAIGNS_ENDPOINT}/options`)
}

export const getCampaignBreeds = async (species) => {
  return await get(`${CAMPAIGNS_ENDPOINT}/breeds/${species}`)
}

export const deleteCampaign = async (id) => {
  return await destroy(`${CAMPAIGNS_ENDPOINT}/${id}`)
}
