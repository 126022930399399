import types from './types'

const CAMPAIGN_CATEGORY_INITIAL_STATE = {
  list: null,
  fullyLoaded: false,
  isLoading: false
}

const INITIAL_STATE = {
  paginator: {},
  current: {},
  refresh_to_dashboard: null,

  stripe_form: {
    accountNumber: '',
    routingNumber: '',
    fullSsn: '',
    businessName: '',
    businessWebsite: '',
    taxId: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    forceEdit: false
  },

  vet_info: {
    vetEmail: 'test',
    vetName: 'test name',
    vetPhone: '123',
    vetVerified: '',
    vetBusinessName: 'test buss name'
  },

  shelter_dashboard_stats: {},

  campaigns: {
    active: { ...CAMPAIGN_CATEGORY_INITIAL_STATE },
    inactive: { ...CAMPAIGN_CATEGORY_INITIAL_STATE },
    drafts: { ...CAMPAIGN_CATEGORY_INITIAL_STATE },
    has_wishlist: { ...CAMPAIGN_CATEGORY_INITIAL_STATE },
    no_wishlist: { ...CAMPAIGN_CATEGORY_INITIAL_STATE }
  },
  stripeStatus: {
    isLoading: false,
    result: 'Checking...'
  },
  signedAgencyAgreement: null,
  signedPantryAgreement: null,
  tabs: [
    {
      title: 'Active',
      value: 'active',
      total: 0
    },
    {
      title: 'Inactive',
      value: 'inactive',
      total: 0
    },
    {
      title: 'Drafts',
      value: 'drafts',
      total: 0
    },
    {
      title: 'Has Wish List',
      value: 'has_wishlist',
      total: 0
    },
    {
      title: 'No Wish List',
      value: 'no_wishlist',
      total: 0
    }
  ],
  defaultTab: 'drafts'
}

const shelterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PAGINATOR: {
      return {
        ...state,
        paginator: action.paginator
      }
    }

    case types.SAGA_SHELTER_ACCOUNTS: {
      return {
        ...state,
        stripe_form: action.stripe_form
      }
    }

    case types.SET_STRIPE_FORM: {
      return {
        ...state,
        stripe_form: action.stripe_form
      }
    }

    case types.SET_CURRENT: {
      return {
        ...state,
        current: action.current
      }
    }

    case types.SWITCH_SHELTER_REFRESH: {
      return {
        ...state,
        refresh_to_dashboard: action.value
      }
    }

    case types.SET_SHELTER_STATS: {
      return {
        ...state,
        shelter_dashboard_stats: {
          ...action.shelter_dashboard_stats
        }
      }
    }

    case types.SET_SHELTER_CAMPAIGNS: {
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.campaignsCategory]: {
            loading: false,
            fullyLoaded:
              action.pageToLoad >= action.shelterCampaigns?.meta?.last_page,
            list:
              action.pageToLoad === 1
                ? action.shelterCampaigns.data
                : [
                    ...state.campaigns[action.campaignsCategory].list,
                    ...action.shelterCampaigns.data
                  ]
          }
        },
        tabs: [
          {
            title: 'Active',
            value: 'active',
            total: action.shelterCampaigns?.active ?? 0
          },
          {
            title: 'Inactive',
            value: 'inactive',
            total: action.shelterCampaigns?.inactive ?? 0
          },
          {
            title: 'Drafts',
            value: 'drafts',
            total: action.shelterCampaigns?.drafts ?? 0
          },
          {
            title: 'Has Wish List',
            value: 'has_wishlist',
            total: action.shelterCampaigns?.has_wishlist ?? 0
          },
          {
            title: 'No Wish List',
            value: 'no_wishlist',
            total: action.shelterCampaigns?.no_wishlist ?? 0
          }
        ],
        defaultTab: action.shelterCampaigns?.drafts > 0 ? 'drafts' : 'active'
      }
    }

    case types.SET_LOADING_SHELTER_CAMPAIGNS: {
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          [action.campaignsCategory]: {
            ...state.campaigns[action.campaignsCategory],
            isLoading: action.isLoading
          }
        }
      }
    }

    case types.UPDATE_STRIPE_FORM: {
      const { key, value } = action
      const tmp = JSON.parse(JSON.stringify(state.stripe_form))
      tmp[key] = value
      return {
        ...state,
        stripe_form: tmp
      }
    }

    case types.SET_STRIPE_STATUS: {
      return {
        ...state,
        stripeStatus: {
          isLoading: action.isLoading,
          result: action.result
        }
      }
    }

    case types.SET_VET_INFO: {
      return {
        ...state,
        vet_info: {
          isLoading: action.isLoading,
          result: action.result
        }
      }
    }

    case types.SET_SIGNED_AGENCY_AGREEMENT: {
      return {
        ...state,
        signedAgencyAgreement: action.signedAgencyAgreement
      }
    }

    case types.SET_SIGNED_PANTRY_AGREEMENT: {
      return {
        ...state,
        signedPantryAgreement: action.signedPantryAgreement
      }
    }

    case types.RESET_CAMPAIGN_TABS: {
      return {
        ...state,
        campaigns: INITIAL_STATE.campaigns,
        tabs: INITIAL_STATE.tabs
      }
    }

    default:
      return state
  }
}

export default shelterReducer
