const IS_AUTHENTICATED = 'IS_AUTHENTICATED'
const SAGA_DO_LOGIN = 'SAGA_DO_LOGIN'
const SAGA_DO_LOGOUT = 'SAGA_DO_LOGOUT'
const SAGA_CHECK_IS_AUTHENTICATED = 'SAGA_CHECK_IS_AUTHENTICATED'
const PROFILE_UPDATE_FORM = 'PROFILE_UPDATE_FORM'
const SAGA_UPDATE_PROFILE = 'SAGA_UPDATE_PROFILE'
const PROFILE_REFRESH_AFTER_UPDATE = 'PROFILE_REFRESH_AFTER_UPDATE'
const SAGA_SIGN_AGREEMENT = 'SAGA_SIGN_AGREEMENT'
const AGREEMENT_SIGNED = 'AGREEMENT_SIGNED'
const SAGA_RESET_PASSWORD = 'SAGA_RESET_PASSWORD'
const SAGA_RESET_LINK = 'SAGA_RESET_LINK'
const SAGA_EXTERNAL_LOGIN = 'SAGA_EXTERNAL_LOGIN'
const SAGA_PASSWORD_UPDATE = 'SAGA_PASSWORD_UPDATE'
const SET_ACT_AS_PANTRY = 'SET_ACT_AS_PANTRY'
const PANTRY_AGREEMENT_SIGNED = 'PANTRY_AGREEMENT_SIGNED'
const SET_FUNDRAISING_AGREEMENT_ADDENDUM = 'SET_FUNDRAISING_AGREEMENT_ADDENDUM'

const authActionTypes = {
  IS_AUTHENTICATED,
  SAGA_DO_LOGIN,
  SAGA_DO_LOGOUT,
  SAGA_CHECK_IS_AUTHENTICATED,
  PROFILE_UPDATE_FORM,
  SAGA_UPDATE_PROFILE,
  PROFILE_REFRESH_AFTER_UPDATE,
  SAGA_SIGN_AGREEMENT,
  AGREEMENT_SIGNED,
  SAGA_RESET_PASSWORD,
  SAGA_RESET_LINK,
  SAGA_EXTERNAL_LOGIN,
  SAGA_PASSWORD_UPDATE,
  SET_ACT_AS_PANTRY,
  PANTRY_AGREEMENT_SIGNED,
  SET_FUNDRAISING_AGREEMENT_ADDENDUM
}

export default authActionTypes
