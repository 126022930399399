import {
  select,
  all,
  fork,
  join,
  call,
  cancel,
  take,
  put
} from 'redux-saga/effects'
import { getInventoryEa } from './api'
import actions from '../actions'
import types from '../types'

const readAdminOrderFromStore = (state) => state.admin_order

function* doGetEaInventory() {
  yield put(actions.resetEaInventory())
  const { pending_orders_products: pendingOrdersProducts } = yield select(
    readAdminOrderFromStore
  )
  const tasks = yield all(
    pendingOrdersProducts.map(({ sku }) => fork(getInventoryEa, sku))
  )
  yield all([call(cancelTask, tasks), call(joinTask, tasks)])
}

function* cancelTask(tasks) {
  yield take(types.CANCEL_SAGA_GET_PENDING_ORDERS_PRODUCTS)
  yield cancel([...tasks])
}

function* joinTask(tasks) {
  yield all(
    tasks.map(function* individualTask(task, index) {
      const response = yield join(task)
      yield put(actions.updateEaInventory(index, response.data.quantity))
      return task
    })
  )
}

export default doGetEaInventory
