import { Suspense, useEffect } from 'react'
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import SpinnerLoader from 'components/loaders/SpinnerLoader/SpinnerLoader'
import { getRoutes, routesConfig } from 'containers/routes/routesConfig'
import AuthenticatedRoute from 'components/AuthenticatedRoute'
import { getUserType } from 'util/user'
import { authActions } from './store/auth'

const redirectRoutes = () => {
  const redirects = routesConfig.filter((route) => route?.from)

  return redirects.flatMap(({ from, to }) =>
    [].concat(from).map((path) => (
      <Route key={path} exact path={path}>
        {({ match }) => (
          <Redirect
            to={Object.keys(match.params).reduce(
              (acc, param) => acc.replace(`:${param}`, match.params[param]),
              to
            )}
          />
        )}
      </Route>
    ))
  )
}

// this noop or empty function is used to disable the default prompt
const userConfirmation = () => {}

export default function Routes() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth) || {}
  const { registered_as: role = '' } = user || {}
  const userType = getUserType(role)
  const userRoutes = getRoutes(userType)

  useEffect(() => {
    dispatch(authActions.sagaCheckIsAuthenticated())
  }, [])

  return (
    <Suspense fallback={<SpinnerLoader />}>
      <BrowserRouter getUserConfirmation={userConfirmation}>
        <Switch>
          {[
            ...redirectRoutes(),
            ...userRoutes.map(
              ({
                requiresAuth,
                to,
                exact,
                component: Component,
                contextWrappers = []
              }) => {
                const RouteWrapper = requiresAuth ? AuthenticatedRoute : Route
                const WrappedComponent = contextWrappers.reduce(
                  (acc, Context) => {
                    return <Context>{acc}</Context>
                  },
                  <Component />
                )

                return (
                  <RouteWrapper key={to} path={to} exact={exact || false}>
                    {WrappedComponent}
                  </RouteWrapper>
                )
              }
            )
          ]}
        </Switch>
      </BrowserRouter>
    </Suspense>
  )
}
