import PropTypes from 'prop-types'
import { DEFAULT_API_ERROR_MESSAGE } from 'constants/messages'

const ErrorFallback = ({ error }) => {
  const resetErrorBoundary = () => {
    window.sessionStorage.removeItem('persist:root')
    window.location.reload()
  }

  return (
    <div className="flex flex-col min-w-min min-h-screen items-center align-middle justify-center bg-gray-200">
      <span className="font-semibold text-2xl my-4">
        {DEFAULT_API_ERROR_MESSAGE}
      </span>
      <span className="text-center text-gray-700 p-4 w-full lg:w-2/3">
        {error?.message?.includes('.chunk.js') ||
        error?.message?.includes('.chunk.css')
          ? 'The platform is being updated, wait a few minutes before trying again.'
          : error?.message || 'Something went wrong.'}
      </span>

      <button
        type="button"
        className="flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-cuddly-600 hover:bg-cuddly-500 focus:border-cuddly-700 focus:outline-none  focus:shadow-outline-cuddly active:bg-cuddly-700 transition duration-150 ease-in-out"
        onClick={resetErrorBoundary}
      >
        Try again
      </button>
    </div>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default ErrorFallback
