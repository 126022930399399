import types from './types'

const INITIAL_STATE = {
  isLoading: false,
  paginator: {},
  details: {},
  isLoadingDetails: false,
  isCreating: false
}

const ordersReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.IS_LOADING_ORDERS_REPORT: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }

    case types.SET_ORDERS_REPORT: {
      return {
        ...state,
        isLoading: false,
        paginator: action?.data ?? {}
      }
    }

    case types.IS_LOADING_ORDERS_REPORT_DETAILS: {
      return {
        ...state,
        isLoadingDetails: action.isLoadingDetails
      }
    }

    case types.SET_ORDERS_REPORT_DETAILS: {
      return {
        ...state,
        isLoadingDetails: false,
        details: action?.data ?? {}
      }
    }

    case types.RESET_ORDERS_REPORT: {
      return {
        ...state,
        isLoading: false,
        isLoadingDetails: false,
        paginator: {},
        details: {},
        isCreating: false
      }
    }

    case types.RESET_ORDERS_REPORT_DETAILS: {
      return {
        ...state,
        isLoadingDetails: false,
        details: {}
      }
    }

    case types.IS_CREATING_ORDERS_REPORT: {
      return {
        ...state,
        isCreating: action.isCreating
      }
    }

    default:
      return state
  }
}

export default ordersReportReducer
