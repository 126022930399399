const SAGA_GET_THANK_YOU_EMAIL = 'SAGA_GET_THANK_YOU_EMAIL'
const SAGA_POST_THANK_YOU_EMAIL = 'SAGA_POST_THANK_YOU_EMAIL'
const SAGA_GET_CALCULATE_THANK_YOU_EMAIL_LIST =
  'SAGA_GET_CALCULATE_THANK_YOU_EMAIL_LIST'
const UPDATE_THANK_YOU_EMAIL_FORM = 'UPDATE_THANK_YOU_EMAIL_FORM'
const RESET_THANK_YOU_EMAIL_FORM = 'RESET_THANK_YOU_EMAIL_FORM'
const SET_THANK_YOU_EMAIL_FORM = 'SET_THANK_YOU_EMAIL_FORM'
const SET_THANK_YOU_EMAIL_FORM_LOADING = 'SET_THANK_YOU_EMAIL_FORM_LOADING'
const SET_EXHAUSTED_THANK_YOU_EMAIL_LIST = 'SET_EXHAUSTED_THANK_YOU_EMAIL_LIST'
const UPDATE_THANK_YOU_EMAIL_LIST = 'UPDATE_THANK_YOU_EMAIL_LIST'

export default {
  SAGA_GET_THANK_YOU_EMAIL,
  SAGA_POST_THANK_YOU_EMAIL,
  SAGA_GET_CALCULATE_THANK_YOU_EMAIL_LIST,
  UPDATE_THANK_YOU_EMAIL_FORM,
  RESET_THANK_YOU_EMAIL_FORM,
  SET_THANK_YOU_EMAIL_FORM,
  SET_THANK_YOU_EMAIL_FORM_LOADING,
  SET_EXHAUSTED_THANK_YOU_EMAIL_LIST,
  UPDATE_THANK_YOU_EMAIL_LIST
}
