import types from './types'

const sagaCreateCampaign = () => {
  return {
    type: types.SAGA_CREATE_CAMPAIGN
  }
}

const sagaCampaignBreeds = (specie) => {
  return {
    type: types.SAGA_INDEX_CAMPAIGN_BREEDS,
    specie
  }
}

const sagaStoreCampaign = (data, callback) => {
  return {
    type: types.SAGA_STORE_CAMPAIGN,
    data,
    callback
  }
}

const sagaEditCampaign = (id) => {
  return {
    type: types.SAGA_EDIT_CAMPAIGN,
    id
  }
}

const sagaUpdateCampaign = (id, data, callback) => {
  return {
    type: types.SAGA_UPDATE_CAMPAIGN,
    id,
    data,
    callback
  }
}

const sagaUpdateCampaignStatus = (id, status, callback) => {
  return {
    type: types.SAGA_UPDATE_CAMPAIGN_STATUS,
    id,
    status,
    callback
  }
}

const setCampaignOptions = (options) => {
  return {
    type: types.SET_CAMPAIGN_OPTIONS,
    options
  }
}

const setCampaignBreeds = (breeds) => {
  return {
    type: types.SET_CAMPAIGN_BREEDS,
    breeds
  }
}

const updateCampaignForm = (key, value) => {
  return {
    type: types.UPDATE_CAMPAIGN_FORM,
    key,
    value
  }
}

const resetCampaignBreeds = () => {
  return {
    type: types.RESET_CAMPAIGN_BREEDS
  }
}

const resetCampaign = () => {
  return {
    type: types.RESET_CAMPAIGN
  }
}

const setCampaign = (data) => {
  return {
    type: types.SET_CAMPAIGN,
    data
  }
}

const updateCampaignImages = (images) => {
  return {
    type: types.UPDATE_CAMPAIGN_IMAGES,
    images
  }
}

const removeCampaignImage = (id) => {
  return {
    type: types.REMOVE_CAMPAIGN_IMAGE,
    id
  }
}

const updateCampaignVideos = (videos) => {
  return {
    type: types.UPDATE_CAMPAIGN_VIDEOS,
    videos
  }
}

const removeCampaignVideo = (id) => {
  return {
    type: types.REMOVE_CAMPAIGN_VIDEO,
    id
  }
}

const campaignActions = {
  sagaCreateCampaign,
  sagaCampaignBreeds,
  sagaStoreCampaign,
  sagaEditCampaign,
  sagaUpdateCampaign,
  sagaUpdateCampaignStatus,
  setCampaignOptions,
  setCampaignBreeds,
  updateCampaignForm,
  setCampaign,
  resetCampaign,
  resetCampaignBreeds,
  updateCampaignImages,
  removeCampaignImage,
  updateCampaignVideos,
  removeCampaignVideo
}

export default campaignActions
