import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from '../general'
import actions from './actions'
import errorParser from 'util/laravel_error_parser'

// Axios calls
const getCampaignEmail = async ({ id }) => {
  return await axios.get(`/api/shelter/${id}/campaign-email`)
}

const getCalculateEmailList = async ({ id }) => {
  return await axios.get(
    `/api/shelter/emails/calculate-eligibility/${id}?type=4`
  )
}

const postCampaignEmail = async ({ id, ...data }) => {
  return axios.post(`/api/shelter/${id}/campaign-email`, data)
}

const postPreviewCampaignEmail = async (data) => {
  return await axios({
    method: 'post',
    url: '/api/shelter/templates/preview',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

// Doers
function* doGetCampaignEmail(action) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getCampaignEmail, action)
    yield put(actions.setCampaignEmailForm(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doPostCampaignEmail(action) {
  try {
    yield put(generalActions.isLoading())
    const reponse = yield call(postCampaignEmail, action.data)
    yield put(actions.resetCampaignEmailForm())
    yield put(generalActions.setSuccess(reponse.data.message))

    if (action.callback) {
      action.callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doPostPreviewCampaignEmail({ data, callback }) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(postPreviewCampaignEmail, data)

    if (callback) {
      callback(response.data.preview)
    }
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetCalculateEmailList(action) {
  try {
    yield put(actions.setExhaustedCampaignEmailList(true))
    const { data } = yield call(getCalculateEmailList, action)
    yield put(actions.updateEmailList(data))
  } catch (err) {
    yield put(actions.updateEmailList([]))

    if (err?.response?.status) {
      yield put(generalActions.setError(errorParser(err)))
      yield put(generalActions.setUnauthorized(err))
    }
  } finally {
    yield put(actions.setExhaustedCampaignEmailList(false))
  }
}

// Watchers
function* watchDoGetCampaignEmail() {
  yield takeLatest(types.SAGA_GET_CAMPAIGN_EMAIL, doGetCampaignEmail)
}

function* watchDoPostCampaignEmail() {
  yield takeLatest(types.SAGA_POST_CAMPAIGN_EMAIL, doPostCampaignEmail)
}

function* watchDoPostPreviewCampaignEmail() {
  yield takeLatest(
    types.SAGA_POST_PREVIEW_CAMPAIGN_EMAIL,
    doPostPreviewCampaignEmail
  )
}

function* watchDoGetCalculateEmailList() {
  yield takeLatest(types.SAGA_GET_CALCULATE_EMAIL_LIST, doGetCalculateEmailList)
}

export default function* rootSaga() {
  yield all([
    watchDoGetCampaignEmail(),
    watchDoPostCampaignEmail(),
    watchDoPostPreviewCampaignEmail(),
    watchDoGetCalculateEmailList()
  ])
}
