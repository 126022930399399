import types from './types'

const INITIAL_STATE = {
  loading_skus: false,
  parameters: [],
  pending_products: {},
  limbo_products: [],

  fix_product: {},
  fix_variants: [],
  fix_message: false,

  pending_orders: [],
  pending_orders_products: [],

  cart_order_items: [],
  next_run: false,
  report_items: [],

  details_by_sku: [],
  waiting_skip_ea: false,
  downloading: false,

  pending_order_details: [],
  pending_order_details_loading: false
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_LOADING_SKUS: {
      return {
        ...state,
        loading_skus: action.loading
      }
    }

    case types.SET_CART_ORDER_ITEMS: {
      return {
        ...state,
        cart_order_items: action.cart_order_items
      }
    }

    case types.SET_PARAMETERS: {
      return {
        ...state,
        parameters: action.parameters
      }
    }

    case types.UPDATE_VALUE: {
      const _tmp = [...state.parameters]
      const { key, value } = action
      const index = _tmp.findIndex((e) => e.key === key)
      _tmp[index].value = value

      return {
        ...state,
        parameters: _tmp
      }
    }

    case types.RESET_EA_INVENTORY: {
      const _tmp = state.pending_orders_products.map((e) => {
        return { ...e, inventory: false }
      })

      return {
        ...state,
        pending_orders_products: [..._tmp]
      }
    }

    case types.UPDATE_EA_INVENTORY: {
      const _tmp = [...state.pending_orders_products]
      _tmp[action.index].inventory = action.inventory

      return {
        ...state,
        pending_orders_products: [..._tmp]
      }
    }

    case types.SET_PENDING_PRODUCTS: {
      return {
        ...state,
        pending_products: action.pending_products
      }
    }

    case types.PUT_PENDING_ORDERS: {
      return {
        ...state,
        pending_orders: action.pending_orders
      }
    }

    case types.PUT_PENDING_ORDERS_PRODUCTS: {
      return {
        ...state,
        pending_orders_products: action.pending_orders_products
      }
    }

    case types.SET_LIMBO_PRODUCTS: {
      return {
        ...state,
        limbo_products: action.limbo_products
      }
    }

    case types.SET_SKIP_EA: {
      const { id } = action
      const tmp = state.details_by_sku?.map((e) => {
        if (e.id === id) {
          return { ...e, status: 'skip_ea' }
        }

        return e
      })

      return {
        ...state,
        details_by_sku: [...tmp]
      }
    }

    case types.SET_FIX_VARIANTS: {
      return {
        ...state,
        fix_variants: action.fix_variants,
        fix_product: action.fix_product,
        fix_message: false
      }
    }

    case types.SET_FIX_MESSAGE: {
      return {
        ...state,
        fix_variants: INITIAL_STATE.fix_variants,
        fix_product: INITIAL_STATE.fix_product,
        fix_message: action.fix_message
      }
    }

    case types.SET_NEXT_RUN: {
      return {
        ...state,
        next_run: action.next_run
      }
    }

    case types.SET_PENDING_ORDERS_REPORT: {
      return {
        ...state,
        report_items: action.report_items
      }
    }

    case types.RESET_FIX: {
      return {
        ...state,
        fix_variants: INITIAL_STATE.fix_variants,
        fix_message: INITIAL_STATE.fix_message,
        fix_product: INITIAL_STATE.fix_product
      }
    }

    case types.SET_LIMBO_PRODUCT_DETAILS_BY_SKU: {
      return {
        ...state,
        details_by_sku: action.details_by_sku
      }
    }

    case types.SET_WAITING_SKIP_EA: {
      return {
        ...state,
        waiting_skip_ea: action.waiting
      }
    }

    case types.SET_DOWNLOADING: {
      return {
        ...state,
        downloading: action.downloading
      }
    }

    case types.SET_PENDING_ORDERS_REPORT_DETAILS: {
      return {
        ...state,
        pending_order_details: action?.details?.data || []
      }
    }

    case types.SET_LOADING_PENDING_ORDERS_REPORT_DETAILS: {
      return {
        ...state,
        pending_order_details_loading: action.loading
      }
    }

    case types.RESET_PENDING_ORDERS_REPORT_DETAILS: {
      return {
        ...state,
        pending_order_details: INITIAL_STATE.pending_order_details
      }
    }

    default:
      return state
  }
}

export default reducer
