import { call, put } from 'redux-saga/effects'
import errorParser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { postCreateShopifyOrder } from './api'

function* doPostShopifyOrder({
  shipping_address_id: shippingAddressId,
  notes,
  callback
}) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(
      postCreateShopifyOrder,
      shippingAddressId,
      notes
    )
    yield put(generalActions.setSuccess(response.data.message))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    if (callback) callback()
  }
}

export default doPostShopifyOrder
