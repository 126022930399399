import axios from 'axios'
import { getImpersonatedUser } from 'util/impersonation'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
})

api.interceptors.request.use((request) => {
  if (
    localStorage.getItem('token') &&
    localStorage.getItem('token').length > 0
  ) {
    request.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    const cuddlyUser = getImpersonatedUser()

    if (cuddlyUser) {
      request.headers['X-Cuddly-User'] = cuddlyUser
    }
  }

  return request
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status === 500) {
      const error = new Error('Sorry, something went wrong. Please try again.')

      return Promise.reject(error)
    }

    if (error.response && error.response.status === 403) {
      const error = new Error('Unauthorized')
      error.status = 403

      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export const get = async (url, config = {}) => {
  try {
    const { data } = await api.get(url, config)

    return data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const post = async (url, body, config = {}) => {
  try {
    const { data } = await api.post(url, body, {
      ...config
    })

    return data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const put = async (url, body, config = {}) => {
  try {
    const { data } = await api.post(url, body, {
      ...config,
      method: 'PUT'
    })

    return data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const destroy = async (url, config = {}) => {
  try {
    const { data } = await api.delete(url, config)

    return data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export default api
