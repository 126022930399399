import { call, put, takeLatest, all } from 'redux-saga/effects'
import types from './types'
import actions from './actions'
import axios from 'axios'
import { generalActions } from '../general'
import errorParser from 'util/laravel_error_parser'

const getURL = (userType, id = '') => {
  const type = userType === 'rescue' ? 'shelter' : userType

  return `api/${type}/shipping-address/${id}`
}

// Axios calls
const getShippingAddressList = async ({ userType, payload }) => {
  return await axios.get(getURL(userType), { params: payload })
}

const postShippingAddress = async (userType, data) => {
  return await axios.post(getURL(userType), data)
}

const putShippingAddress = async (userType, id, data) => {
  return await axios.put(getURL(userType, id), data)
}

const deleteShippingAddress = async ({ userType, payload }) => {
  return await axios.delete(getURL(userType, payload))
}

// Doers
function* doGetShippingAddressList(payload) {
  try {
    yield put(actions.setShippingAddressIsLoading(true))
    const response = yield call(getShippingAddressList, payload)
    yield put(actions.setShippingAddressList(response.data))
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setShippingAddressIsLoading())
  }
}

function* doPostShippingAddress(action) {
  try {
    yield put(actions.setShippingAddressIsLoading(true))
    const response = yield call(
      postShippingAddress,
      action.userType,
      action.payload
    )
    yield put(actions.resetShippingAddress())
    yield put(generalActions.setSuccess(response.data.message))

    if (action?.callback) {
      action.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setShippingAddressIsLoading())
  }
}

function* doPutShippingAddress(action) {
  try {
    yield put(actions.setShippingAddressIsLoading(true))
    const { payload } = action
    const response = yield call(
      putShippingAddress,
      action.userType,
      payload.id,
      payload
    )
    yield put(actions.resetShippingAddress())
    yield put(generalActions.setSuccess(response.data.message))

    if (action?.callback) {
      action.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setShippingAddressIsLoading())
  }
}

function* doDeleteShippingAddress(action) {
  try {
    yield put(actions.setShippingAddressIsLoading(true))
    const response = yield call(deleteShippingAddress, action)
    yield put(actions.resetShippingAddress())
    yield put(generalActions.setSuccess(response.data.message))

    if (action?.callback) {
      action.callback()
    }
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(actions.setShippingAddressIsLoading())
  }
}

// Watchers
function* watchGetShippingAddressList() {
  yield takeLatest(
    types.SAGA_GET_SHIPPING_ADDRESS_LIST,
    doGetShippingAddressList
  )
}

function* watchPostShippingAddress() {
  yield takeLatest(types.SAGA_POST_SHIPPING_ADDRESS, doPostShippingAddress)
}

function* watchPutShippingAddress() {
  yield takeLatest(types.SAGA_PUT_SHIPPING_ADDRESS, doPutShippingAddress)
}

function* watchDelteShippingAddress() {
  yield takeLatest(types.SAGA_DELETE_SHIPPING_ADDRESS, doDeleteShippingAddress)
}

export default function* rootSaga() {
  yield all([
    watchGetShippingAddressList(),
    watchPostShippingAddress(),
    watchPutShippingAddress(),
    watchDelteShippingAddress()
  ])
}
