import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from '../general'
import { authActions } from '../auth'
import actions from './actions'
import errorParser from 'util/laravel_error_parser'
import { removeImpersonatedUser, setImpersonatedUser } from 'util/impersonation'
import { onError, safe } from 'util/sagas_handler'

const getShelterStats = async () => {
  return await axios.get('api/shelter')
}

const getShelterCampaigns = async (campaignsCategory, pageToLoad) => {
  return await axios.get(
    `api/shelter/campaigns/${campaignsCategory}?page=${pageToLoad}`
  )
}

const getShelterAccountsInfo = async () => {
  return await axios.get('api/shelter/accounts')
}

const postAssignFc = async ({ payload }) => {
  return await axios.post('/api/admin/shelter/assign-fc', payload)
}

const postChangeSarStatus = async ({ payload }) => {
  return await axios.post('/api/admin/shelter/status', payload)
}

const postDeletePayPalAccountAsAdmin = async ({ shelterId }) => {
  return await axios.post('/api/admin/shelter/paypal/delete', { shelterId })
}

const postCreateOrUpdateStripeAccount = async ({ payload }) => {
  return await axios.post('/api/shelter/stripe', payload)
}

const postSaveVetInfo = async ({ payload }) => {
  return await axios.post('/api/shelter/profile/vet', payload)
}

const getVetInfo = async ({ payload }) => {
  return await axios.get('/api/shelter/profile/vet', payload)
}

const postCreatePaypal = async ({ payload }) => {
  return await axios.post('/api/shelter/paypal', payload)
}

const getShelters = async ({ payload }) => {
  return await axios.get('api/admin/shelter', { params: payload })
}

const postSwitchShelters = async ({ id }) => {
  return await axios.post(`api/admin/shelter/${id}/switch`)
}

const deleteSwitchShelters = async () => {
  return await axios.delete(`api/admin/shelter/switch`)
}

const deletePaypalAccount = async () => {
  return await axios.delete(`api/shelter/paypal`)
}

const getStripeStatus = async () => {
  return await axios.get('api/shelter/stripe/status')
}

const getSignedAgencyAgreement = async () => {
  return await axios.get('api/shelter/signed-agreement')
}

const getSignedPantryAgreement = async () => {
  return await axios.get('api/pantry/agreements')
}

const postFundraisingAgreementAddendum = async () => {
  return await axios.post('api/shelter/fundraising-agreement-addendums')
}

const getPayPalReferralUrl = async () => {
  return await axios.get('api/shelter/paypal/referral-url')
}

function* doPostAssignFc(payload) {
  yield put(generalActions.isLoading())
  const response = yield call(postAssignFc, payload)
  const { data } = yield call(getShelters, { page: payload.page })
  yield put(actions.setPaginator(data))
  yield put(generalActions.setSuccess(response.data.message))
  yield put(generalActions.isNotLoading())
}

function* doPostChangeSarStatus(payload) {
  yield put(generalActions.isLoading())

  const response = yield call(postChangeSarStatus, payload)
  payload = payload.page

  const { data } = yield call(getShelters, { payload })

  yield put(actions.setPaginator(data))
  yield put(generalActions.setSuccess(response.data.message))
  yield put(generalActions.isNotLoading())
}

function* doPostCreateOrUpdateStripeAccount(payload) {
  yield put(generalActions.isLoading())
  const response = yield call(postCreateOrUpdateStripeAccount, payload)
  yield put(generalActions.setSuccess(response.data.message))
  yield put(generalActions.isNotLoading())
}

function* doPostSaveVetInfo(payload) {
  yield put(generalActions.isLoading())
  const response = yield call(postSaveVetInfo, payload)
  yield put(authActions.profileRefreshAfterUpdate(response.data.user_resource))
  yield put(generalActions.setSuccess(response.data.message))
  yield put(generalActions.isNotLoading())
}

function* doPostCreatePaypal(payload) {
  yield put(generalActions.isLoading())
  const response = yield call(postCreatePaypal, payload)
  yield put(generalActions.setSuccess(response.data.message))
  yield put(generalActions.isNotLoading())
}

function* doPostSwitchShelters(action) {
  yield put(generalActions.isLoading())
  const response = yield call(postSwitchShelters, action)
  yield put(generalActions.setSuccess(response.data.message))
  const cuddlyUser = response.data?.user?.id
  setImpersonatedUser(cuddlyUser)
  axios.defaults.headers.common['X-Cuddly-User'] = cuddlyUser
  yield put(authActions.setIsAuthenticated(true, response.data.user))
  yield put(actions.switchShelterRefresh(true))
  setTimeout(() => {
    window.location.href = '/'
  }, 100)
}

function* doGetShelters(payload) {
  yield put(generalActions.isLoading())
  const response = yield call(getShelters, payload)
  yield put(actions.setPaginator(response.data))
  yield put(generalActions.isNotLoading())
}

function* doDeleteSwitchShelters({ redirectAfterSwitch }) {
  yield put(generalActions.isLoading())
  const response = yield call(deleteSwitchShelters)
  if (redirectAfterSwitch) {
    yield put(actions.switchShelterRefresh(false))
  }
  removeImpersonatedUser()
  delete axios.defaults.headers.common['X-Cuddly-User']
  yield put(authActions.setIsAuthenticated(true, response.data.user))
  yield put(generalActions.isNotLoading())
}

function* doDeletePaypalAccount() {
  yield put(generalActions.isLoading())
  const response = yield call(deletePaypalAccount)
  yield put(generalActions.setSuccess(response.data.message))
  yield put(generalActions.isNotLoading())
}

function* doGetShelterAccountsInfo() {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(getShelterAccountsInfo)

    yield put(actions.setStripeForm(response.data))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.isNotLoading())
  }
}

function* doGetShelterStats() {
  yield put(generalActions.isLoading())
  const response = yield call(getShelterStats)
  yield put(actions.setShelterStats(response.data))
  yield put(generalActions.isNotLoading())
}

function* doGetShelterCampaigns({ campaignsCategory, pageToLoad }) {
  yield put(actions.setLoadingShelterCampaigns(campaignsCategory, true))
  const response = yield call(
    getShelterCampaigns,
    campaignsCategory,
    pageToLoad
  )
  yield put(
    actions.setShelterCampaigns(campaignsCategory, pageToLoad, response.data)
  )
  yield put(actions.setLoadingShelterCampaigns(campaignsCategory, false))
}

function* doGetStripeStatus() {
  try {
    const response = yield call(getStripeStatus)
    yield put(actions.setStripeStatus({ result: response.data }))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  }
}

function* doGetVetInfo() {
  try {
    const response = yield call(getVetInfo)
    yield put(actions.setVetInfo({ result: response.data }))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  }
}

function* doGetSignedAgencyAgreement() {
  try {
    const response = yield call(getSignedAgencyAgreement)
    yield put(
      actions.setSignedAgencyAgreement({
        signedAgencyAgreement: response.data.document_id
      })
    )
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  }
}

function* doGetSignedPantryAgreement() {
  try {
    const response = yield call(getSignedPantryAgreement)
    yield put(
      actions.setSignedPantryAgreement({
        signedPantryAgreement: response.data.document_id
      })
    )
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  }
}

function* doPostFundraisingAgreementAddendum(action) {
  try {
    const response = yield call(postFundraisingAgreementAddendum)
    yield put(generalActions.setSuccess(response.data.message))

    if (action?.callback) {
      action.callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  }
}

function* doGetPayPalReferralUrl(action) {
  try {
    const { data } = yield call(getPayPalReferralUrl)

    if (action?.callback && data?.url) {
      action.callback(data.url)
    }
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  }
}

function* doDeletePayPalAccountAsAdmin(payload) {
  try {
    const response = yield call(postDeletePayPalAccountAsAdmin, payload)
    yield put(generalActions.setSuccess(response.data.message))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
  }
}

/** Watchers **/
function* watchDoGetShelterAccountsInfo() {
  yield takeLatest(types.SAGA_SHELTER_ACCOUNTS, doGetShelterAccountsInfo)
}

function* watchDoGetShelters() {
  yield takeLatest(types.SAGA_SHELTER_INDEX, safe(onError, doGetShelters))
}

function* watchDoPostAssignFc() {
  yield takeLatest(types.SAGA_ASSIGN_FC, safe(onError, doPostAssignFc))
}

function* watchDoPostChangeSarStatus() {
  yield takeLatest(
    types.SAGA_CHANGE_SAR_STATUS,
    safe(onError, doPostChangeSarStatus)
  )
}

function* watchDoPostCreateOrUpdateStripeAccount() {
  yield takeLatest(
    types.SET_STRIPE_ACCOUNT,
    safe(onError, doPostCreateOrUpdateStripeAccount)
  )
}

function* watchDoPostSaveVetInfo() {
  yield takeLatest(types.SAGA_SAVE_VET_INFO, safe(onError, doPostSaveVetInfo))
}

function* watchDoPostCreatePaypal() {
  yield takeLatest(types.SAGA_CREATE_PAYPAL, safe(onError, doPostCreatePaypal))
}

function* watchDoPostSwitchShelters() {
  yield takeLatest(
    types.SAGA_SWITCH_SHELTER,
    safe(onError, doPostSwitchShelters)
  )
}

function* watchDoDeleteSwitchShelters() {
  yield takeLatest(
    types.SAGA_SWITCH_SHELTER_BACK,
    safe(onError, doDeleteSwitchShelters)
  )
}

function* watchDoDeletePaypalAccount() {
  yield takeLatest(
    types.SAGA_DELETE_PAYPAL_ACCOUNT,
    safe(onError, doDeletePaypalAccount)
  )
}

function* watchDoGetShelterStats() {
  yield takeLatest(types.SAGA_SHELTER_STATS, safe(onError, doGetShelterStats))
}

function* watchDoGetShelterCampaigns() {
  yield takeLatest(
    types.SAGA_SHELTER_CAMPAIGNS,
    safe(onError, doGetShelterCampaigns)
  )
}

function* watchDoGetStripeStatus() {
  yield takeLatest(
    types.SAGA_GET_STRIPE_STATUS,
    safe(onError, doGetStripeStatus)
  )
}

function* watchDoGetVetInfo() {
  yield takeLatest(types.SAGA_GET_VET_INFO, safe(onError, doGetVetInfo()))
}

function* watchDoGetSignedAgencyAgreement() {
  yield takeLatest(
    types.SAGA_GET_SIGNED_AGENCY_AGREEMENT,
    safe(onError, doGetSignedAgencyAgreement)
  )
}

function* watchDoGetSignedPantryAgreement() {
  yield takeLatest(
    types.SAGA_GET_SIGNED_PANTRY_AGREEMENT,
    safe(onError, doGetSignedPantryAgreement)
  )
}

function* watchDoPostFundraisingAgreementAddendum() {
  yield takeLatest(
    types.SAGA_POST_FUNDRAISING_AGREEMENT_ADDENDUM,
    safe(onError, doPostFundraisingAgreementAddendum)
  )
}

function* watchDoGetPayPalReferralUrl() {
  yield takeLatest(
    types.SAGA_GET_PAYPAL_REFERRAL_URL,
    safe(onError, doGetPayPalReferralUrl)
  )
}

function* watchDoDeletePayPalAccountAsAdmin() {
  yield takeLatest(
    types.SAGA_DELETE_PAYPAL_ACCOUNT_AS_ADMIN,
    safe(onError, doDeletePayPalAccountAsAdmin)
  )
}

/**
 * Exporting all watchers
 */
export default function* rootSaga() {
  yield all([
    watchDoGetShelters(),
    watchDoPostSwitchShelters(),
    watchDoDeleteSwitchShelters(),
    watchDoGetShelterStats(),
    watchDoGetShelterCampaigns(),
    watchDoPostAssignFc(),
    watchDoPostChangeSarStatus(),
    watchDoDeletePaypalAccount(),
    watchDoPostCreatePaypal(),
    watchDoGetShelterAccountsInfo(),
    watchDoPostCreateOrUpdateStripeAccount(),
    watchDoPostSaveVetInfo(),
    watchDoGetStripeStatus(),
    watchDoGetVetInfo(),
    watchDoGetSignedAgencyAgreement(),
    watchDoGetSignedPantryAgreement(),
    watchDoPostFundraisingAgreementAddendum(),
    watchDoGetPayPalReferralUrl(),
    watchDoDeletePayPalAccountAsAdmin()
  ])
}
