const SAGA_GET_EMAIL_SUBJECTS = 'SAGA_GET_EMAIL_SUBJECTS'
const SAGA_POST_EMAIL_SUBJECTS = 'SAGA_POST_EMAIL_SUBJECTS'
const SAGA_PUT_EMAIL_SUBJECTS = 'SAGA_PUT_EMAIL_SUBJECTS'
const SAGA_PATCH_EMAIL_SUBJECTS = 'SAGA_PATCH_EMAIL_SUBJECTS'
const SET_EMAIL_SUBJECTS = 'SET_EMAIL_SUBJECTS'
const UPDATE_EMAIL_SUBJECTS_FORM = 'UPDATE_EMAIL_SUBJECTS_FORM'
const SET_EMAIL_SUBJECTS_MODAL_OPEN = 'SET_EMAIL_SUBJECTS_MODAL_OPEN'
const SET_EMAIL_SUBJECTS_IS_LOADING = 'SET_EMAIL_SUBJECTS_IS_LOADING'

const emailSubjectsTypes = {
  SAGA_GET_EMAIL_SUBJECTS,
  SAGA_POST_EMAIL_SUBJECTS,
  SAGA_PUT_EMAIL_SUBJECTS,
  SAGA_PATCH_EMAIL_SUBJECTS,
  SET_EMAIL_SUBJECTS,
  UPDATE_EMAIL_SUBJECTS_FORM,
  SET_EMAIL_SUBJECTS_MODAL_OPEN,
  SET_EMAIL_SUBJECTS_IS_LOADING
}

export default emailSubjectsTypes
