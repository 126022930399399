import types from './types'

const sagaShelterIndex = (payload) => {
  return {
    type: types.SAGA_SHELTER_INDEX,
    payload
  }
}

const sagaAssignFc = (payload, page) => {
  return {
    type: types.SAGA_ASSIGN_FC,
    payload,
    page
  }
}

const sagaChangeSaRStatus = (payload, page) => {
  return {
    type: types.SAGA_CHANGE_SAR_STATUS,
    payload,
    page
  }
}

const sagaCreatePaypal = (payload) => {
  return {
    type: types.SAGA_CREATE_PAYPAL,
    payload
  }
}

const sagaSaveOrUpdateStripe = (payload) => {
  return {
    type: types.SET_STRIPE_ACCOUNT,
    payload
  }
}

const sagaSaveVetInfo = (payload) => {
  return {
    type: types.SAGA_SAVE_VET_INFO,
    payload
  }
}

const setPaginator = (paginator) => {
  return {
    type: types.SET_PAGINATOR,
    paginator
  }
}

const setCurrent = (current) => {
  return {
    type: types.SET_CURRENT,
    current
  }
}

const sagaSwitchShelter = (id) => {
  return {
    type: types.SAGA_SWITCH_SHELTER,
    id
  }
}

const sagaSwitchShelterBack = (redirectAfterSwitch = true) => {
  return {
    type: types.SAGA_SWITCH_SHELTER_BACK,
    redirectAfterSwitch
  }
}

const sagaDeletePaypalAccount = () => {
  return {
    type: types.SAGA_DELETE_PAYPAL_ACCOUNT
  }
}

const switchShelterRefresh = (value) => {
  return {
    type: types.SWITCH_SHELTER_REFRESH,
    value
  }
}

const sagaShelterStats = () => {
  return {
    type: types.SAGA_SHELTER_STATS
  }
}

const sagaShelterCampaigns = (campaignsCategory, pageToLoad) => {
  return {
    type: types.SAGA_SHELTER_CAMPAIGNS,
    campaignsCategory,
    pageToLoad
  }
}

const sagaShelterAccountsInfo = (stripeForm) => {
  return {
    type: types.SAGA_SHELTER_ACCOUNTS,
    stripe_form: stripeForm
  }
}

const setStripeForm = (stripeForm) => {
  return {
    type: types.SET_STRIPE_FORM,
    stripe_form: stripeForm
  }
}

const setShelterStats = (shelterDashboardStats) => {
  return {
    type: types.SET_SHELTER_STATS,
    shelter_dashboard_stats: shelterDashboardStats
  }
}

const setShelterCampaigns = (
  campaignsCategory,
  pageToLoad,
  shelterCampaigns
) => {
  return {
    type: types.SET_SHELTER_CAMPAIGNS,
    campaignsCategory,
    pageToLoad,
    shelterCampaigns
  }
}

const setLoadingShelterCampaigns = (campaignsCategory, isLoading) => {
  return {
    type: types.SET_LOADING_SHELTER_CAMPAIGNS,
    campaignsCategory,
    isLoading
  }
}

const updateStripeForm = (key, value) => {
  return {
    type: types.UPDATE_STRIPE_FORM,
    key,
    value
  }
}

const sagaGetStripeStatus = () => {
  return {
    type: types.SAGA_GET_STRIPE_STATUS
  }
}

const sagaGetVetInfo = () => {
  return {
    type: types.SAGA_GET_VET_INFO
  }
}

const setStripeStatus = ({ isLoading = false, result = 'Checking...' }) => {
  return {
    type: types.SET_STRIPE_STATUS,
    isLoading,
    result
  }
}

const setVetInfo = ({ isLoading = false, result = 'Checking...' }) => {
  return {
    type: types.SET_VET_INFO,
    isLoading,
    result
  }
}

const sagaGetSignedAgencyAgreement = () => {
  return {
    type: types.SAGA_GET_SIGNED_AGENCY_AGREEMENT
  }
}

const sagaGetSignedPantryAgreement = () => {
  return {
    type: types.SAGA_GET_SIGNED_PANTRY_AGREEMENT
  }
}

const setSignedAgencyAgreement = ({ signedAgencyAgreement = null }) => {
  return {
    type: types.SET_SIGNED_AGENCY_AGREEMENT,
    signedAgencyAgreement
  }
}

const setSignedPantryAgreement = ({ signedPantryAgreement = null }) => {
  return {
    type: types.SET_SIGNED_PANTRY_AGREEMENT,
    signedPantryAgreement
  }
}

const resetCampaignTabs = () => {
  return {
    type: types.RESET_CAMPAIGN_TABS
  }
}

const sagaPostFundraisingAgreementAddedum = (callback) => {
  return {
    type: types.SAGA_POST_FUNDRAISING_AGREEMENT_ADDENDUM,
    callback
  }
}

const sagaGetPayPalReferralUrl = (callback = () => {}) => {
  return {
    type: types.SAGA_GET_PAYPAL_REFERRAL_URL,
    callback
  }
}

const setDownloadingAgencyAgreement = (isDownloading) => {
  return {
    type: types.SET_DOWNLOADING_AGENCY_AGREEMENT,
    isDownloading
  }
}

const setDownloadingPantryAgreement = (isDownloading) => {
  return {
    type: types.SET_DOWNLOADING_PANTRY_AGREEMENT,
    isDownloading
  }
}

const sagaDeletePaypalAccountAsAdmin = (shelterId) => {
  return {
    type: types.SAGA_DELETE_PAYPAL_ACCOUNT_AS_ADMIN,
    shelterId
  }
}

export default {
  sagaShelterIndex,
  sagaDeletePaypalAccount,
  setPaginator,
  updateStripeForm,
  sagaSaveOrUpdateStripe,
  sagaShelterAccountsInfo,
  setCurrent,
  sagaCreatePaypal,
  sagaSwitchShelter,
  sagaSwitchShelterBack,
  switchShelterRefresh,
  sagaShelterStats,
  sagaShelterCampaigns,
  setShelterStats,
  setShelterCampaigns,
  setLoadingShelterCampaigns,
  setStripeForm,
  sagaAssignFc,
  sagaChangeSaRStatus,
  sagaSaveVetInfo,
  sagaGetVetInfo,
  sagaGetStripeStatus,
  setStripeStatus,
  setVetInfo,
  sagaGetSignedAgencyAgreement,
  sagaGetSignedPantryAgreement,
  setSignedAgencyAgreement,
  setSignedPantryAgreement,
  resetCampaignTabs,
  sagaPostFundraisingAgreementAddedum,
  sagaGetPayPalReferralUrl,
  setDownloadingAgencyAgreement,
  setDownloadingPantryAgreement,
  sagaDeletePaypalAccountAsAdmin
}
