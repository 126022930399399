import types from './types'

const initialState = {
  data: [], // used to generate the report in xlsx
  report: {
    data: [] // used to generate the report in the table
  },
  year: new Date().getFullYear(),
  years: [
    {
      value: new Date().getFullYear(),
      label: new Date().getFullYear()
    }
  ]
}

const paymentReconsolidationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PAYMENT_RECONSOLIDATION_REPORT: {
      const { report, years } = action.data
      const yearsAsOptions = years.map((year) => ({
        value: year,
        label: year
      }))

      const rows = []

      return {
        ...state,
        data: report,
        report: {
          data: rows
        },
        years: yearsAsOptions.length === 0 ? state.years : yearsAsOptions
      }
    }

    default:
      return state
  }
}

export default paymentReconsolidationReducer
