import { call, put } from 'redux-saga/effects'
import errorPaser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { getPendingOrdersReportDetails } from './api'
import actions from '../actions'

function* doGetPendingOrdersReportDetails({ id }) {
  try {
    yield put(actions.setLoadingPendingOrdersReportDetails(true))
    const { data } = yield call(getPendingOrdersReportDetails, id)
    yield put(actions.setPendingOrdersReportDetails(data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(actions.setLoadingPendingOrdersReportDetails(false))
  }
}

export default doGetPendingOrdersReportDetails
