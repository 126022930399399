export const getImpersonatedUser = () => {
  const tabId = sessionStorage.getItem('tabId')
  const userKey = `cuddly_user_${tabId}`
  const cuddlyUser = localStorage.getItem(userKey) ?? undefined

  return cuddlyUser
}

export const setImpersonatedUser = (userId) => {
  let tabId = sessionStorage.getItem('tabId')

  if (!tabId) {
    tabId = Date.now()
    sessionStorage.setItem('tabId', tabId)
  }

  const userKey = `cuddly_user_${tabId}`
  localStorage.setItem(userKey, userId)
}

export const removeImpersonatedUser = () => {
  const tabId = sessionStorage.getItem('tabId')
  const userKey = `cuddly_user_${tabId}`
  const userId = localStorage.getItem(userKey)
  const pantryKey = `act_as_pantry_${userId}`
  localStorage.removeItem(userKey)
  localStorage.removeItem(pantryKey)
}
