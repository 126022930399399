import types from './types'

const form = {
  id: '',
  name: '',
  species: '',
  breed_id: '',
  allow_wishlist: true,
  wish_name: '',
  wish_amount: '',
  description: '',
  age: '',
  sex: '',
  color: '',
  size: '',
  is_active: 0,
  type_of_case: [],
  has_multiple_pets: '',
  has_graphic_images: '',
  location: '',
  status: 'Draft',
  weekly_wish: false,
  urgent_needs: false,
  is_legacy: true
}

const initialState = {
  options: {},
  breeds: [],
  form,
  images: [],
  videos: [],
  removed_images: [],
  removed_videos: []
}

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CAMPAIGN: {
      return {
        ...state,
        form: { ...form },
        images: [],
        videos: []
      }
    }

    case types.SET_CAMPAIGN_OPTIONS: {
      const { options } = action

      return {
        ...state,
        ...options
      }
    }

    case types.SET_CAMPAIGN_BREEDS: {
      const { breeds } = action

      return {
        ...state,
        breeds: breeds?.data || []
      }
    }

    case types.RESET_CAMPAIGN_BREEDS: {
      return {
        ...state,
        breeds: []
      }
    }

    case types.UPDATE_CAMPAIGN_FORM: {
      const { key, value } = action
      const form = state.form
      form[key] = value

      return {
        ...state,
        form
      }
    }

    case types.UPDATE_CAMPAIGN_IMAGES: {
      const { images } = action

      return {
        ...state,
        images: images ?? []
      }
    }

    case types.REMOVE_CAMPAIGN_IMAGE: {
      const tmp = [...(state.removed_images ?? [])]
      tmp.push(action.id)

      return {
        ...state,
        removed_images: [...new Set(tmp)]
      }
    }

    case types.UPDATE_CAMPAIGN_VIDEOS: {
      const { videos } = action

      return {
        ...state,
        videos: videos ?? []
      }
    }

    case types.REMOVE_CAMPAIGN_VIDEO: {
      const tmp = [...(state.removed_videos ?? [])]
      tmp.push(action.id)

      return {
        ...state,
        removed_videos: [...new Set(tmp)]
      }
    }

    case types.SET_CAMPAIGN: {
      const { campaign: form, options, photos: images, videos } = action.data
      form?.ailment?.forEach((ailment) => {
        form[`ailment_${ailment}`] = true
      })
      delete form.ailment

      return {
        ...state,
        options: { ...options },
        form,
        videos,
        images
      }
    }

    default:
      return state
  }
}

export default campaignReducer
