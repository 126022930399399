// sagas
const SAGA_GET_CAMPAIGN_UPDATES = 'SAGA_GET_CAMPAIGN_UPDATES' // all campaign updates
const SAGA_GET_CAMPAIGN_UPDATE = 'SAGA_GET_CAMPAIGN_UPDATE' // single campaign update
const SAGA_POST_CAMPAIGN_UPDATE = 'SAGA_POST_CAMPAIGN_UPDATE'
const SAGA_DELETE_CAMPAIGN_UPDATE = 'SAGA_DELETE_CAMPAIGN_UPDATE'
const SAGA_GET_CAMPAIGN_UPDATE_PREVIEW_EMAIL =
  'SAGA_GET_CAMPAIGN_UPDATE_PREVIEW_EMAIL'
const SAGA_GET_CALCULATE_UPDATE_EMAIL_LIST =
  'SAGA_GET_CALCULATE_UPDATE_EMAIL_LIST'

// getters and setters
const SET_CAMPAIGN_UPDATES = 'SET_CAMPAIGN_UPDATES'
const SET_CAMPAIGN_UPDATE_FORM = 'SET_CAMPAIGN_UPDATE_FORM'
const UPDATE_CAMPAIGN_UPDATE_FORM = 'UPDATE_CAMPAIGN_UPDATE_FORM'
const RESET_CAMPAIGN_UPDATE_FORM = 'RESET_CAMPAIGN_UPDATE_FORM'
const SET_CAMPAIGN_UPDATE_PREVIEW_EMAIL = 'SET_CAMPAIGN_UPDATE_PREVIEW_EMAIL'
const EXHAUSTED_CAMPAIGN_UPDATE_EMAIL_LIST =
  'EXHAUSTED_CAMPAIGN_UPDATE_EMAIL_LIST'
const UPDATE_CAMPAIGN_UPDATE_EMAIL_LIST = 'UPDATE_CAMPAIGN_UPDATE_EMAIL_LIST'
const SET_CAMPAIGN_UPDATE_DELETING = 'SET_CAMPAIGN_UPDATE_DELETING'

export default {
  SAGA_GET_CAMPAIGN_UPDATES,
  SAGA_GET_CAMPAIGN_UPDATE,
  SAGA_POST_CAMPAIGN_UPDATE,
  SAGA_DELETE_CAMPAIGN_UPDATE,
  SAGA_GET_CAMPAIGN_UPDATE_PREVIEW_EMAIL,
  SAGA_GET_CALCULATE_UPDATE_EMAIL_LIST,
  SET_CAMPAIGN_UPDATES,
  SET_CAMPAIGN_UPDATE_FORM,
  UPDATE_CAMPAIGN_UPDATE_FORM,
  RESET_CAMPAIGN_UPDATE_FORM,
  SET_CAMPAIGN_UPDATE_PREVIEW_EMAIL,
  EXHAUSTED_CAMPAIGN_UPDATE_EMAIL_LIST,
  UPDATE_CAMPAIGN_UPDATE_EMAIL_LIST,
  SET_CAMPAIGN_UPDATE_DELETING
}
