let isDatadogLoaded = false
const ALLOWED_ENVS = ['production', 'release', 'development']

export const initDatadog = () => {
  if (isDatadogLoaded) {
    return
  }

  isDatadogLoaded = true

  if (!ALLOWED_ENVS.includes(process.env.REACT_APP_ENV)) {
    return
  }

  ;(function (h, o, u, n, d) {
    h = h[d] = h[d] || {
      q: [],
      onReady: function (c) {
        h.q.push(c)
      }
    }
    d = o.createElement(u)
    d.async = 1
    d.src = n
    n = o.getElementsByTagName(u)[0]
    n.parentNode.insertBefore(d, n)
  })(
    window,
    document,
    'script',
    'https://www.datadoghq-browser-agent.com/us3/v4/datadog-logs.js',
    'DD_LOGS'
  )
  window.DD_LOGS.onReady(function () {
    window.DD_LOGS.init({
      clientToken: process.env.REACT_APP_DATADOG_PUBLIC_TOKEN,
      site: 'us3.datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: `admin.cuddly.com:${process.env.REACT_APP_ENV}`
    })
  })
}
