import { call, put } from 'redux-saga/effects'
import errorPaser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { getLimboProducts } from './api'
import actions from '../actions'

function* doGetLimboProducts() {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getLimboProducts)
    yield put(actions.setLimboProducts(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

export default doGetLimboProducts
