import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from '../general'
import actions from './actions'
import errorPaser from 'util/laravel_error_parser'

const getEmails = async ({ payload }) => {
  return await axios.get('api/admin/emails', { params: payload })
}

const postVerifyEmails = async ({ payload }) => {
  const url = `api/common/verify-emails`

  return await axios({
    method: 'post',
    url,
    data: payload,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

const reValidateEmails = async ({ payload }) => {
  return await axios.post(`api/common/revalidate`, { params: payload })
}

const getSarEmails = async ({ payload }) => {
  return await axios.get(`api/common/emails`, { params: payload })
}

const getSarEmailListHistoryReporting = async ({ payload }) => {
  return await axios.get(`api/common/email-list-reporting`, {
    params: payload
  })
}

const getSarAnalyticsEmails = async ({ payload, userType }) => {
  const userTypeUrl = userType === 'pantry' ? 'pantry' : 'shelter'

  return await axios.get(`api/${userTypeUrl}/emails-analytics`, {
    params: payload
  })
}

const getSarAnalyticsEmailsPerEmail = async ({ payload, userType }) => {
  const userTypeUrl = userType === 'pantry' ? 'pantry' : 'shelter'

  return await axios.get(`api/${userTypeUrl}/emails-analytics/${payload.id}`, {
    params: payload
  })
}

const getArchiveSarAnalyticsEmails = async ({ payload }) => {
  return await axios.get('api/shelter/archive-emails-analytics', {
    params: payload
  })
}

const updateSarEmail = async ({ payload }) => {
  return await axios.put(`api/common/emails/${payload.id}`, payload)
}

const updateScheduleEmail = async ({ payload }) => {
  // send queuedEmailId
  return await axios.put(`api/shelter/emails`, payload)
}

const deleteSarEmail = async ({ payload }) => {
  return await axios.delete(`api/common/emails/${payload.id}`)
}

function* doPostVerifyEmails(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(postVerifyEmails, payload)
    yield put(generalActions.setSuccess(response.data.message))

    if (payload?.callback) {
      payload.callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doReValidateEmails(payload) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(reValidateEmails, payload)
    yield put(generalActions.setSuccess(response.data.message))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doGetEmails(payload) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(getEmails, payload)
    yield put(actions.setPaginator(response.data))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doGetSarEmails(payload) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(getSarEmails, payload)
    yield put(actions.setPaginator(response.data))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doGetSarEmailListHistoryReporting(payload) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(getSarEmailListHistoryReporting, payload)
    yield put(actions.setPaginator(response.data))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doGetSarAnalyticsEmails(payload) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(getSarAnalyticsEmails, payload)
    yield put(actions.setPaginator(response.data))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doGetSarAnalyticsEmailsPerEmail(payload) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(getSarAnalyticsEmailsPerEmail, payload)
    yield put(actions.setPaginatorPerEmail(response.data))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doGetArchiveSarAnalyticsEmails(payload) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(getArchiveSarAnalyticsEmails, payload)
    yield put(actions.setPaginator(response.data))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doUpdateSarEmail(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(updateSarEmail, payload)
    yield put(generalActions.setSuccess(response?.data?.message))

    if (payload?.callback) {
      payload.callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doUpdateScheduleEmail(payload) {
  yield put(generalActions.isLoading())

  try {
    const response = yield call(updateScheduleEmail, payload)
    yield put(generalActions.setSuccess(response.data.message))
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

function* doDeleteSarEmail(payload) {
  yield put(generalActions.isLoading())

  try {
    yield call(deleteSarEmail, payload)
    yield put(generalActions.isNotLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  }
}

/** Watchers **/
function* watchDoGetEmails() {
  yield takeLatest(types.SAGA_EMAIL_INDEX, doGetEmails)
}

function* watchDoPostVerifyEmails() {
  yield takeLatest(types.SAGA_VERIFY_EMAILS, doPostVerifyEmails)
}

function* watchDoReValidateEmails() {
  yield takeLatest(types.SAGA_SAR_RE_VALIDATE, doReValidateEmails)
}

function* watchDoGetSarEmails() {
  yield takeLatest(types.SAGA_SAR_EMAIL_INDEX, doGetSarEmails)
}

function* watchDoGetSarEmailListHistoryReporting() {
  yield takeLatest(
    types.SAGA_SAR_EMAIL_LIST_HISTORY_INDEX,
    doGetSarEmailListHistoryReporting
  )
}

function* watchDoGetSarAnalyticsEmails() {
  yield takeLatest(types.SAGA_SAR_EMAIL_ANALYTICS, doGetSarAnalyticsEmails)
}

function* watchDoGetSarAnalyticsEmailsPerEmail() {
  yield takeLatest(
    types.SAGA_SAR_EMAIL_ANALYTICS_PER_EMAIL,
    doGetSarAnalyticsEmailsPerEmail
  )
}

function* watchDoGetArchiveSarAnalyticsEmails() {
  yield takeLatest(
    types.SAGA_ARCHIVE_SAR_EMAIL_ANALYTICS,
    doGetArchiveSarAnalyticsEmails
  )
}

function* watchDoUpdateSarEmail() {
  yield takeLatest(types.SAGA_SAR_EMAIL_UPDATE, doUpdateSarEmail)
}

function* watchDoUpdateScheduleEmail() {
  yield takeLatest(types.SAGA_SAR_SCHEDULE_EMAIL_UPDATE, doUpdateScheduleEmail)
}

function* watchDoDeleteSarEmail() {
  yield takeLatest(types.SAGA_SAR_EMAIL_DELETE, doDeleteSarEmail)
}

/**
 * Exporting all watchers
 */
export default function* rootSaga() {
  yield all([
    watchDoGetEmails(),
    watchDoGetSarAnalyticsEmails(),
    watchDoGetSarAnalyticsEmailsPerEmail(),
    watchDoPostVerifyEmails(),
    watchDoReValidateEmails(),
    watchDoGetArchiveSarAnalyticsEmails(),
    watchDoGetSarEmails(),
    watchDoGetSarEmailListHistoryReporting(),
    watchDoUpdateSarEmail(),
    watchDoUpdateScheduleEmail(),
    watchDoDeleteSarEmail()
  ])
}
