import types from './types'

const EMAIL_SUBJECT = {
  id: null,
  subject: '',
  email_type: '',
  is_pantry: false
}

const INITIAL_STATE = {
  paginator: [],
  emailSubjectForm: EMAIL_SUBJECT,
  isModalOpen: false,
  isLoading: false
}

const emailSubjectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_EMAIL_SUBJECTS: {
      return {
        ...state,
        paginator: action.payload
      }
    }

    case types.UPDATE_EMAIL_SUBJECTS_FORM: {
      const { key, value } = action
      return {
        ...state,
        emailSubjectForm: {
          ...state.emailSubjectForm,
          [key]: value
        }
      }
    }

    case types.RESET_EMAIL_SUBJECTS: {
      return {
        ...state,
        emailSubjectForm: { ...EMAIL_SUBJECT }
      }
    }

    case types.SET_EMAIL_SUBJECTS_MODAL_OPEN: {
      return {
        ...state,
        isModalOpen: action.open
      }
    }

    case types.SET_EMAIL_SUBJECTS_IS_LOADING: {
      return {
        ...state,
        isLoading: action.loading
      }
    }

    default:
      return state
  }
}

export default emailSubjectReducer
