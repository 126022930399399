import { call, put } from 'redux-saga/effects'
import errorPaser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { getDownloadLimboProductsReport } from './api'
import actions from '../actions'

function* doGetDownloadLimboProductsReport() {
  try {
    yield put(actions.setDownloading(true))
    yield call(getDownloadLimboProductsReport)
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(actions.setDownloading(false))
  }
}

export default doGetDownloadLimboProductsReport
