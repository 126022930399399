import heic2any from 'heic2any'

export const blobToBase64 = async (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const base64ToBlobUrl = async (base64String) => {
  const response = await fetch(base64String)
  const blob = await response.blob()

  return URL.createObjectURL(blob)
}

export const fetchImageAsBlob = async (url) => {
  try {
    const response = await fetch(url, { mode: 'cors' })

    if (!response.ok) {
      throw new Error(
        `Failed to fetch image: ${response.status} ${response.statusText}`
      )
    }

    return await response.blob()
  } catch (error) {
    console.error(error)

    return null
  }
}

export const convertToBlobUrl = async (data) => {
  if (typeof data === 'string' && data.startsWith('blob:')) {
    return data
  } else if (data instanceof Blob) {
    return URL.createObjectURL(data)
  } else if (typeof data === 'string' && data.startsWith('data:')) {
    const blob = await fetchImageAsBlob(data)

    return URL.createObjectURL(blob)
  }

  return null
}

export const heic2Jpeg = async (image) => {
  const base64 = await blobToBase64(image)
  const blob = await fetchImageAsBlob(base64)

  return await heic2any({ blob, toType: 'image/jpeg' })
}

export const getConvertedImages = async (image) => {
  const { cropped, original } = image

  let small = cropped?.url || image.small

  if (cropped?.url.startsWith('blob:')) {
    small = await fetchImageAsBlob(cropped.url)
  }

  if (cropped?.url.startsWith('data:image/')) {
    small = await base64ToBlobUrl(cropped.url)
  }

  let large = original?.url || image.large

  if (original?.url.startsWith('blob:')) {
    large = await fetchImageAsBlob(original.url)
  }

  return { small, large }
}
