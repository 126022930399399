const SAGA_GET_CAMPAIGN_EMAIL = 'SAGA_GET_CAMPAIGN_EMAIL'
const SAGA_GET_CALCULATE_EMAIL_LIST = 'SAGA_GET_CALCULATE_EMAIL_LIST'
const SAGA_POST_CAMPAIGN_EMAIL = 'SAGA_POST_CAMPAIGN_EMAIL'
const SAGA_POST_PREVIEW_CAMPAIGN_EMAIL = 'SAGA_POST_PREVIEW_CAMPAIGN_EMAIL'
const SET_CAMPAIGN_EMAIL_FORM = 'SET_CAMPAIGN_EMAIL_FORM'
const UPDATE_CAMPAIGN_EMAIL_FORM = 'UPDATE_CAMPAIGN_EMAIL_FORM'
const RESET_CAMPAIGN_EMAIL_FORM = 'RESET_CAMPAIGN_EMAIL_FORM'
const EXHAUSTED_CAMPAIGN_EMAIL_LIST = 'EXHAUSTED_CAMPAIGN_EMAIL_LIST'
const UPDATE_CAMPAIGN_EMAIL_LIST = 'UPDATE_CAMPAIGN_EMAIL_LIST'

export default {
  SAGA_GET_CAMPAIGN_EMAIL,
  SAGA_GET_CALCULATE_EMAIL_LIST,
  SAGA_POST_CAMPAIGN_EMAIL,
  SAGA_POST_PREVIEW_CAMPAIGN_EMAIL,
  SET_CAMPAIGN_EMAIL_FORM,
  UPDATE_CAMPAIGN_EMAIL_FORM,
  RESET_CAMPAIGN_EMAIL_FORM,
  EXHAUSTED_CAMPAIGN_EMAIL_LIST,
  UPDATE_CAMPAIGN_EMAIL_LIST
}
