import types from './types'

// Sagas
const sagaGetPantryStats = () => {
  return {
    type: types.SAGA_GET_PANTRY_STATS
  }
}

// getters and setters
const setPantryStats = (pantryStats) => {
  return {
    type: types.SET_PANTRY_STATS,
    pantryStats
  }
}

export default {
  sagaGetPantryStats,
  setPantryStats
}
