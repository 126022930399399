import { lazy } from 'react'

export const pantryRoutes = [
  {
    to: '/legacy/campaign/create',
    from: '/pantry/campaign/new',
    component: lazy(() => import('containers/pantry/PantryCampaign')),
    requiresAuth: true,
    exact: true
  },

  {
    to: '/legacy/campaign/:id/edit',
    from: ['/pantry/campaign/:id/edit', '/campaign/:id/edit'],
    component: lazy(() => import('containers/pantry/PantryCampaign')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/legacy/campaign/:id/wishlist',
    from: ['/pantry/campaign/:id/wishlist', '/campaign/:id/wishlist'],
    component: lazy(() => import('containers/campaigns/Wishlist')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/donations',
    from: [
      '/pantry/reports/wish_list_donations',
      '/pantry/reports/wishlist_donations'
    ],
    label: 'Donations',
    component: lazy(() =>
      import('containers/pantry/reports/WishlistDonationsReport')
    ),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/campaign/:campaignId/thank-you',
    component: lazy(() => import('containers/pantry/ThankYouEmail')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/campaign/:id/email',
    from: '/pantry/campaign/:id/campaign_email',
    component: lazy(() => import('containers/pantry/PantryCampaignEmail')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/orders',
    from: '/pantry/reports/orders',
    label: 'Orders',
    component: lazy(() => import('containers/reports/Orders')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/stories',
    from: '/pantry/stories/list',
    label: 'Stories',
    component: lazy(() => import('containers/common/SuccessStories')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/campaign/:id/wishlist',
    from: '/pantry/campaign/:id/wishlist',
    component: lazy(() => import('containers/campaigns/Wishlist')),
    requiresAuth: true,
    exact: true
  }
]

export default pantryRoutes
