// sagas
const SAGA_GET_PANTRY_WISHLIST_DONATIONS = 'SAGA_GET_PANTRY_WISHLIST_DONATIONS'
const SAGA_GET_PANTRY_WISHLIST_DONATION_DETAILS =
  'SAGA_GET_PANTRY_WISHLIST_DONATION_DETAILS'
const SAGA_GET_PANTRY_WISHLIST_DONATIONS_REPORT =
  'SAGA_GET_PANTRY_WISHLIST_DONATIONS_REPORT'
const SAGA_GET_FULL_BOWLS_CLUB_REPORT = 'SAGA_GET_FULL_BOWLS_CLUB_REPORT'
const SAGA_GET_PANTRY_DONORS_REPORT = 'SAGA_GET_PANTRY_DONORS_REPORT'

// setters and getters
const SET_PAGINATOR = 'SET_PAGINATOR'
const SET_CURRENT = 'SET_CURRENT'
const SET_DETAILS = 'SET_DETAILS'
const SET_FULL_BOWL_DETAIL = 'SET_FULL_BOWL_DETAIL'
const SET_LOADING = 'SET_LOADING'
const SET_DOWNLOADING = 'SET_DOWNLOADING'
const SET_DOWNLOADING_DONORS = 'SET_DOWNLOADING_DONORS'

export default {
  SAGA_GET_PANTRY_WISHLIST_DONATIONS,
  SAGA_GET_PANTRY_WISHLIST_DONATION_DETAILS,
  SAGA_GET_PANTRY_WISHLIST_DONATIONS_REPORT,
  SAGA_GET_FULL_BOWLS_CLUB_REPORT,
  SAGA_GET_PANTRY_DONORS_REPORT,
  SET_PAGINATOR,
  SET_CURRENT,
  SET_DETAILS,
  SET_FULL_BOWL_DETAIL,
  SET_LOADING,
  SET_DOWNLOADING,
  SET_DOWNLOADING_DONORS
}
