import types from './types'

const sagaGetPantryThankYouEmail = (payload) => {
  return {
    type: types.SAGA_GET_PANTRY_THANK_YOU_EMAIL,
    payload
  }
}

const sagaPostPantryThankYouEmail = (payload) => {
  return {
    type: types.SAGA_POST_PANTRY_THANK_YOU_EMAIL,
    payload
  }
}

const setPantryThankYouEmailForm = (payload) => {
  return {
    type: types.SET_PANTRY_THANK_YOU_EMAIL_FORM,
    payload
  }
}

const updatePantryThankYouEmailForm = (key, value) => {
  return {
    type: types.UPDATE_PANTRY_THANK_YOU_EMAIL_FORM,
    key,
    value
  }
}

const resetPantryThankYouEmailForm = () => {
  return {
    type: types.RESET_PANTRY_THANK_YOU_EMAIL_FORM
  }
}

const setPantryThankYouEmailFormLoading = (loading = true) => {
  return {
    type: types.SET_PANTRY_THANK_YOU_EMAIL_FORM_LOADING,
    loading
  }
}

const setExhaustedPantryThankYouEmailList = (exhausted = true) => {
  return {
    type: types.SET_EXHAUSTED_PANTRY_THANK_YOU_EMAIL_LIST,
    exhausted
  }
}

const updatePantryThankYouEmailList = (data) => {
  return {
    type: types.UPDATE_PANTRY_THANK_YOU_EMAIL_LIST,
    data
  }
}

const sagaGetCalculatePantryThankYouEmailList = (id) => {
  return {
    type: types.SAGA_GET_CALCULATE_PANTRY_THANK_YOU_EMAIL_LIST,
    id
  }
}

export default {
  sagaGetPantryThankYouEmail,
  sagaPostPantryThankYouEmail,
  sagaGetCalculatePantryThankYouEmailList,
  setPantryThankYouEmailForm,
  updatePantryThankYouEmailForm,
  resetPantryThankYouEmailForm,
  setPantryThankYouEmailFormLoading,
  setExhaustedPantryThankYouEmailList,
  updatePantryThankYouEmailList
}
