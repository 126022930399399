import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from 'store/general'
import actions from './actions'
import errorPaser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

const URL = 'api/pantry/campaigns'

// requests
const getPantryCampaign = async (id) => {
  return await axios.get(`${URL}/${id}/edit`)
}

const postPantryCampaign = async (data) => {
  return await axios.post(URL, data)
}

const putPantryCampaign = async (id, data) => {
  return await axios.post(`${URL}/${id}`, data)
}

const getPantryCampaigns = async (type, page) => {
  return await axios.get(`${URL}?page=${page}&type=${type}`)
}

// doers
function* doGetPantryCampaign(action) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(getPantryCampaign, action.id)
    yield put(actions.setPantryCampaign(data))
  } catch (error) {
    yield put(generalActions.setError(errorPaser(error)))
    yield put(generalActions.setUnauthorized(error))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doPostPantryCampaign(action) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(postPantryCampaign, action.data)
    yield put(generalActions.setSuccess(data.message))
    const status = action.data.get('status')
    const isActive = parseInt(action.data.get('is_active')) === 1

    if (action?.callback) {
      action.callback(data.id, status, isActive)
    }
  } catch (error) {
    yield put(generalActions.setError(errorPaser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doPutPantryCampaign(action) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(putPantryCampaign, action.id, action.data)
    yield put(generalActions.setSuccess(data.message))
    const status = action.data.get('status')
    const isActive = parseInt(action.data.get('is_active')) === 1

    if (action?.callback) {
      action.callback(data.id, status, isActive)
    }
  } catch (error) {
    yield put(generalActions.setError(errorPaser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetPantryCampaigns(action) {
  try {
    yield put(actions.setPantryCampaignsLoading(true))
    const { data } = yield call(
      getPantryCampaigns,
      action.campaignType,
      action.page
    )
    yield put(actions.setPantryCampaigns(data))
  } catch (error) {
    yield put(generalActions.setError(errorPaser(error)))
    yield put(generalActions.setUnauthorized(error))
  } finally {
    yield put(actions.setPantryCampaignsLoading(false))
  }
}

// watchers
function* watchDoGetPantryCampaign() {
  yield takeLatest(
    types.SAGA_GET_PANTRY_CAMPAIGN,
    safe(onError, doGetPantryCampaign)
  )
}

function* watchDoPostPantryCampaign() {
  yield takeLatest(
    types.SAGA_POST_PANTRY_CAMPAIGN,
    safe(onError, doPostPantryCampaign)
  )
}

function* watchDoPutPantryCampaign() {
  yield takeLatest(
    types.SAGA_PUT_PANTRY_CAMPAIGN,
    safe(onError, doPutPantryCampaign)
  )
}

function* watchDoGetPantryCampaigns() {
  yield takeLatest(
    types.SAGA_GET_PANTRY_CAMPAIGNS,
    safe(onError, doGetPantryCampaigns)
  )
}

export default function* rootSaga() {
  yield all([
    watchDoGetPantryCampaign(),
    watchDoPostPantryCampaign(),
    watchDoPutPantryCampaign(),
    watchDoGetPantryCampaigns()
  ])
}
