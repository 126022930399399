import { call, put } from '@redux-saga/core/effects'
import laravelErrorParser from './laravel_error_parser'
import { generalActions } from 'store/general'
import { authActions } from 'store/auth'

export const safe = (handler = null, saga, ...args) =>
  function* (action) {
    try {
      yield saga(action, ...args, action)
    } catch (error) {
      yield call(handler, error, error)
    }
  }

export function* onError(error) {
  if (error?.respnse?.status === 401) {
    yield put(authActions.setIsAuthenticated(false))
    yield put({ type: 'RESET_APP_STATE' })
  } else {
    yield put(generalActions.setError(laravelErrorParser(error)))
  }
}
