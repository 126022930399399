import { call, all, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import actions from './actions'
import { generalActions } from 'store/general'
import errorParser from 'util/laravel_error_parser'

// Axios calls
const getPaymentReconsolidation = async (year) => {
  const query = year ? `?year=${year}` : ''
  const url = `/api/shelter/reports/payment-reconsolidation${query}`

  return await axios.get(url)
}

// Doers
function* doGetPaymentReconsolidation(action) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(getPaymentReconsolidation, action.year)
    yield put(actions.setPaymentReconsolidationReport(data))
  } catch (error) {
    yield put(generalActions.setError(errorParser(error)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

// Watchers
function* watchGetPaymentReconsolidation() {
  yield takeLatest(
    types.SAGA_GET_PAYMENT_RECONSOLIDATION_REPORT,
    doGetPaymentReconsolidation
  )
}

export default function* rootSaga() {
  yield all([watchGetPaymentReconsolidation()])
}
