import types from './types'

const sagaGetShippingAddressList = (userType, payload = {}) => {
  return {
    type: types.SAGA_GET_SHIPPING_ADDRESS_LIST,
    userType,
    payload
  }
}

const sagaPostShippingAddress = (userType, payload, callback) => {
  return {
    type: types.SAGA_POST_SHIPPING_ADDRESS,
    userType,
    payload,
    callback
  }
}

const sagaPutShippingAddress = (userType, payload, callback) => {
  return {
    type: types.SAGA_PUT_SHIPPING_ADDRESS,
    userType,
    payload,
    callback
  }
}

const sagaDeleteShippingAddress = (userType, payload, callback) => {
  return {
    type: types.SAGA_DELETE_SHIPPING_ADDRESS,
    userType,
    payload,
    callback
  }
}

const setShippingAddressList = (payload) => {
  return {
    type: types.SET_SHIPPING_ADDRESS_LIST,
    payload
  }
}

const updateShippingAddressForm = (key, value) => {
  return {
    type: types.UPDATE_SHIPPING_ADDRESS_FORM,
    key,
    value
  }
}

const resetShippingAddress = () => {
  return {
    type: types.RESET_SHIPPING_ADDRESS
  }
}

const setCurrentShippingAddress = (payload) => {
  return {
    type: types.SET_CURRENT_SHIPPING_ADDRESS,
    payload
  }
}

const setShippingAddressForm = (payload) => {
  return {
    type: types.SET_SHIPPING_ADDRESS_FORM,
    payload
  }
}

const setShippingAddressModalOpen = (open) => {
  return {
    type: types.SET_SHIPPING_ADDRESS_MODAL_OPEN,
    open
  }
}

const setShippingAddressIsLoading = (loading = false) => {
  return {
    type: types.SET_SHIPPING_ADDRESS_IS_LOADING,
    loading
  }
}

const setShippingAddressId = (id) => {
  return {
    type: types.SET_SHIPPING_ADDRESS_ID,
    id
  }
}

const shippingAddressActions = {
  sagaGetShippingAddressList,
  sagaPostShippingAddress,
  sagaPutShippingAddress,
  sagaDeleteShippingAddress,
  setShippingAddressList,
  updateShippingAddressForm,
  resetShippingAddress,
  setCurrentShippingAddress,
  setShippingAddressForm,
  setShippingAddressModalOpen,
  setShippingAddressIsLoading,
  setShippingAddressId
}

export default shippingAddressActions
