import { call, put } from 'redux-saga/effects'
import errorParser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { getPendingOrdersReport } from './api'
import actions from '../actions'

function* doGetPendingOrdersReport() {
  yield put(generalActions.isLoading())
  try {
    const response = yield call(getPendingOrdersReport)
    yield put(actions.setReport(response.data.data))
    yield put(generalActions.setSuccess(response.data.message))
  } catch (err) {
    yield put(generalActions.setError(errorParser(err)))
    yield put(generalActions.setUnauthorized(err))
  }
}

export default doGetPendingOrdersReport
