import types from './types'

const sagaGetUsers = (payload) => {
  return {
    type: types.SAGA_GET_USERS,
    payload
  }
}

const sagaPatchUser = (payload, callback) => {
  return {
    type: types.SAGA_PATCH_USER,
    payload,
    callback
  }
}

const setUsers = (payload) => {
  return {
    type: types.SET_USERS,
    payload
  }
}

const setUsersLoading = (loading = false) => {
  return {
    type: types.SET_USERS_LOADING,
    loading
  }
}

const setSignPantryAgreement = (payload) => {
  return {
    type: types.SET_SIGN_PANTRY_AGREEMENT,
    payload
  }
}

const userActions = {
  sagaGetUsers,
  sagaPatchUser,
  setUsers,
  setUsersLoading,
  setSignPantryAgreement
}

export default userActions
