import { AnalyticsBrowser } from '@segment/analytics-next'

const analytics = AnalyticsBrowser.load({ 
    writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
 })


 analytics.ready().then(() => {
    console.log('Segment Analytics is Ready')
 })
 
export default analytics
