import { call, put } from 'redux-saga/effects'
import errorPaser from 'util/laravel_error_parser'
import { adminOrdersActions } from '../../admin_orders'
import { generalActions } from '../../general'
import { getCartOrderItems } from './api'

function* doGetCartOrderItems({
  shipping_address_id: shippingAddressId,
  callback
}) {
  try {
    const response = yield call(getCartOrderItems, shippingAddressId)
    yield put(adminOrdersActions.setOrderItems(response.data.data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    if (callback) callback()
  }
}

export default doGetCartOrderItems
