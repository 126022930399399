import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from 'store/general'
import actions from './actions'
import errorPaser from 'util/laravel_error_parser'
import { onError, safe } from 'util/sagas_handler'

const URL = 'api/common/documentation'

// axios requests
const getDocumentation = async ({ payload }) => {
  return await axios.get(URL, { params: payload })
}

const getDocumentationByCategory = async ({ payload }) => {
  return await axios.get(URL, { params: payload })
}

const getDocumentationVersionsById = async ({ payload }) => {
  return await axios.get(URL, { params: payload })
}

const postDocumentation = async ({ payload }) => {
  const formData = new FormData()

  Object.keys(payload).forEach((k) => {
    formData.append(k, payload[k])
  })

  return await axios({
    method: 'post',
    url: URL,
    data: formData,
    headers: { 'Content-Type': `multipart/form-data` }
  })
}

const putDocumentation = async ({ payload }) => {
  payload._method = 'PUT'
  const formData = new FormData()

  Object.keys(payload).forEach((k) => {
    formData.append(k, payload[k])
  })

  return await axios({
    method: 'post',
    url: `${URL}/${payload.id}`,
    data: formData,
    headers: { 'Content-Type': `multipart/form-data` }
  })
}

const downloadDocumentation = async ({ id, name }) => {
  const response = await axios.get(`${URL}/download/${id}`, {
    responseType: 'blob'
  })
  const filename = name || response.headers['x-filename'] || 'untitled'
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

// doers
function* doGetDocumentation(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getDocumentation, payload)
    yield put(actions.setDocumentation(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetDocumentationByCategory(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getDocumentationByCategory, payload)
    yield put(actions.setDocumentationByCategory(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doGetDocumentationVersionsById(payload) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(getDocumentationVersionsById, payload)
    yield put(actions.setDocumentationVersionsById(response.data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doPostDocumentation(payload) {
  try {
    yield put(actions.setDocumentLoading(true))
    const response = yield call(postDocumentation, payload)
    yield put(generalActions.setSuccess(response?.data?.message))
    yield put(actions.setDocumentationReload(true))

    if (payload?.callback) {
      payload.callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(actions.setDocumentLoading(false))
  }
}

function* doPutDocumentation(payload) {
  try {
    yield put(actions.setDocumentLoading(true))
    yield call(putDocumentation, payload)
    yield put(actions.setDocumentationReload(true))

    if (payload?.callback) {
      payload.callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(actions.setDocumentLoading(false))
  }
}

function* doDownloadDocumentation(payload) {
  try {
    yield put(actions.setDocumentDownloading(true))
    yield call(downloadDocumentation, payload)
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(actions.setDocumentDownloading(false))
  }
}

// watchers
function* watchGetDocumentation() {
  yield takeLatest(
    types.SAGA_GET_DOCUMENTATION,
    safe(onError, doGetDocumentation)
  )
}

function* watchGetDocumentationByCategory() {
  yield takeLatest(
    types.SAGA_GET_DOCUMENTATION_BY_CATEGORY,
    safe(onError, doGetDocumentationByCategory)
  )
}

function* watchGetDocumentationVersionsById() {
  yield takeLatest(
    types.SAGA_GET_DOCUMENTATION_VERSIONS_BY_ID,
    safe(onError, doGetDocumentationVersionsById)
  )
}

function* watchPostDocumentation() {
  yield takeLatest(
    types.SAGA_POST_DOCUMENTATION,
    safe(onError, doPostDocumentation)
  )
}

function* watchPutDocumentation() {
  yield takeLatest(
    types.SAGA_PUT_DOCUMENTATION,
    safe(onError, doPutDocumentation)
  )
}

function* watchDownloadDocumentation() {
  yield takeLatest(
    types.SAGA_DOWNLOAD_DOCUMENTATION,
    safe(onError, doDownloadDocumentation)
  )
}

export default function* rootSaga() {
  yield all([
    watchGetDocumentation(),
    watchGetDocumentationByCategory(),
    watchGetDocumentationVersionsById(),
    watchPostDocumentation(),
    watchPutDocumentation(),
    watchDownloadDocumentation()
  ])
}
