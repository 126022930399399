import types from './types'

const sagaGetPaymentReconsolidationReport = (year) => ({
  type: types.SAGA_GET_PAYMENT_RECONSOLIDATION_REPORT,
  year
})

const setPaymentReconsolidationReport = (data) => ({
  type: types.SET_PAYMENT_RECONSOLIDATION_REPORT,
  data
})

export default {
  sagaGetPaymentReconsolidationReport,
  setPaymentReconsolidationReport
}
