import PropTypes from 'prop-types'
import {
  createContext,
  useState,
  useContext,
  isValidElement,
  useEffect
} from 'react'
import Toastr from 'components/v2/shared/Toastr'

const ToastrContext = createContext(null)

export const ToastrProvider = ({ children }) => {
  const [toastr, setToastr] = useState(null)
  const [toastrKey, setToastrKey] = useState(0)

  const clearToastr = () => {
    setToastr(null)
    setToastrKey((prev) => prev + 1)
  }

  useEffect(() => {
    if (toastr) {
      const timer = setTimeout(() => {
        clearToastr()
      }, toastr.duration || 3000)

      return () => clearTimeout(timer)
    }
  }, [toastr, toastrKey])

  return (
    <ToastrContext.Provider value={{ toastr, setToastr, clearToastr }}>
      {children}
      {toastr ? <Toastr {...toastr} /> : null}
    </ToastrContext.Provider>
  )
}

ToastrProvider.propTypes = {
  children: PropTypes.node
}

export const useToastr = () => {
  const { toastr, setToastr, clearToastr } = useContext(ToastrContext)

  if (!setToastr) {
    throw new Error('useToastr must be used within ToastrProvider')
  }

  const show = (type, content, options = {}) => {
    clearToastr()

    let config = {
      timestamp: Date.now()
    }

    if (typeof content === 'string' || isValidElement(content)) {
      config = {
        type,
        children:
          typeof content === 'string' ? <span>{content}</span> : content,
        ...options
      }
    } else if (typeof content === 'object' && !isValidElement(content)) {
      config = {
        type,
        ...content,
        ...options
      }
    } else {
      throw new Error('Invalid content type')
    }

    setToastr(config)
  }

  return {
    toastr,
    show
  }
}
