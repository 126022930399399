import { lazy } from 'react'
import { CampaignStepperProvider } from 'contexts/campaign-stepper-context'
import { WishListProvider } from 'contexts/wish-list-context'

export const shelterRoutes = [
  {
    to: '/donations',
    from: '/shelter/reports/:type',
    label: 'Donations',
    component: lazy(() => import('containers/sar/Donations')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/orders',
    from: '/shelter/reports/orders',
    label: 'Orders',
    component: lazy(() => import('containers/sar/Orders')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/profile/veterinarian-information',
    from: '/shelter/profile/veterinarian_information',
    component: lazy(() =>
      import('containers/shelters/VeterinarianInformation')
    ),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/campaign/create',
    from: '/campaign/new',
    component: lazy(() => import('containers/campaigns/CampaignForm')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/campaign/:id/email',
    from: '/campaign/:id/campaign_email',
    component: lazy(() => import('containers/campaigns/CampaignEmailForm')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/legacy/campaign/:id/edit',
    from: ['/shelter/campaign/:id/edit', '/campaign/:id/edit'],
    component: lazy(() => import('containers/campaigns/CampaignForm')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/legacy/campaign/:id/wishlist',
    from: ['/shelter/campaign/:id/wishlist', '/campaign/:id/wishlist'],
    component: lazy(() => import('containers/campaigns/Wishlist')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/campaign',
    from: '/campaign/new',
    component: lazy(() => import('containers/sar/CampaignBuilder')),
    requiresAuth: true,
    exact: true,
    contextWrappers: [CampaignStepperProvider, WishListProvider]
  },
  {
    to: '/campaign/:id?',
    requiresAuth: true,
    component: lazy(() => import('containers/sar/CampaignBuilder')),
    exact: true,
    contextWrappers: [CampaignStepperProvider, WishListProvider]
  },
  {
    to: '/multiple-campaign-emails',
    from: '/campaign/multiple_campaigns_email',
    component: lazy(() =>
      import('containers/campaigns/MultipleCampaignsEmailForm')
    ),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/campaign/:campaignId/thank-you',
    component: lazy(() => import('containers/campaigns/ThankYouForm')),
    requiresAuth: true,
    exact: true
  },
  {
    to: '/payouts',
    label: 'Payouts',
    from: '/shelter/reports/payouts_to_paypal',
    component: lazy(() => import('containers/reports/PaymentReconsolidation')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/stories',
    from: '/shelter/stories/list',
    label: 'Stories',
    component: lazy(() => import('containers/common/SuccessStories')),
    requiresAuth: true,
    exact: true,
    isNavbar: true
  },
  {
    to: '/paypal-referral',
    component: lazy(() => import('containers/shelters/PaypalProfile')),
    requiresAuth: true,
    exact: true
  }
]

export default shelterRoutes
