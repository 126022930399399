const SAGA_INDEX_SUCCESS_STORY = 'SAGA_INDEX_SUCCESS_STORY'
const SAGA_STORE_SUCCESS_STORY = 'SAGA_STORE_SUCCESS_STORY'
const SAGA_EDIT_SUCCESS_STORY = 'SAGA_EDIT_SUCCESS_STORY'
const SAGA_UPDATE_SUCCESS_STORY = 'SAGA_UPDATE_SUCCESS_STORY'
const SAGA_UPDATE_SUCCESS_STORY_STATUS = 'SAGA_UPDATE_SUCCESS_STORY_STATUS'
const SAGA_GET_SUCCESS_STORY_CAMPAIGNS = 'SAGA_GET_SUCCESS_STORY_CAMPAIGNS'
const SAGA_GET_SUCCESS_STORY_CALCULATE_EMAIL_LIST =
  'SAGA_GET_SUCCESS_STORY_CALCULATE_EMAIL_LIST'
const SAGA_GET_SUCCESS_STORY_SUBJECTS = 'SAGA_GET_SUCCESS_STORY_SUBJECTS'
const SAGA_POST_PREVIEW_SUCCESS_STORY = 'SAGA_POST_PREVIEW_SUCCESS_STORY'

const SET_SUCCESS_STORY_LIST = 'SET_SUCCESS_STORY_LIST'
const SET_SUCCESS_STORY_FORM = 'SET_SUCCESS_STORY_FORM'
const UPDATE_SUCCESS_STORY_FORM = 'UPDATE_SUCCESS_STORY_FORM'
const RESET_SUCCESS_STORY_FORM = 'RESET_SUCCESS_STORY_FORM'
const RESET_SUCCESS_STORY_LIST = 'RESET_SUCCESS_STORY_LIST'
const UPDATE_SUCCESS_STORY_IMAGES = 'UPDATE_SUCCESS_STORY_IMAGES'
const REMOVE_SUCCESS_STORY_IMAGE = 'REMOVE_SUCCESS_STORY_IMAGE'
const SET_EXHAUSTED_STORIES_EMAIL_LIST = 'SET_EXHAUSTED_STORIES_EMAIL_LIST'
const UPDATE_STORIES_EMAIL_LIST = 'UPDATE_STORIES_EMAIL_LIST'
const SET_SUCCESS_STORY_CAMPAIGNS = 'SET_SUCCESS_STORY_CAMPAIGNS'
const RESET_SUCCESS_STORY_EMAIL_LIST = 'RESET_SUCCESS_STORY_EMAIL_LIST'
const SET_SUCCESS_STORY_SUBJECTS = 'SET_SUCCESS_STORY_SUBJECTS'

export default {
  SAGA_INDEX_SUCCESS_STORY,
  SAGA_STORE_SUCCESS_STORY,
  SAGA_EDIT_SUCCESS_STORY,
  SAGA_UPDATE_SUCCESS_STORY,
  SAGA_UPDATE_SUCCESS_STORY_STATUS,
  SAGA_GET_SUCCESS_STORY_CAMPAIGNS,
  SAGA_GET_SUCCESS_STORY_CALCULATE_EMAIL_LIST,
  SAGA_GET_SUCCESS_STORY_SUBJECTS,
  SAGA_POST_PREVIEW_SUCCESS_STORY,
  SET_SUCCESS_STORY_LIST,
  SET_SUCCESS_STORY_FORM,
  UPDATE_SUCCESS_STORY_FORM,
  RESET_SUCCESS_STORY_FORM,
  RESET_SUCCESS_STORY_LIST,
  UPDATE_SUCCESS_STORY_IMAGES,
  REMOVE_SUCCESS_STORY_IMAGE,
  SET_EXHAUSTED_STORIES_EMAIL_LIST,
  UPDATE_STORIES_EMAIL_LIST,
  SET_SUCCESS_STORY_CAMPAIGNS,
  RESET_SUCCESS_STORY_EMAIL_LIST,
  SET_SUCCESS_STORY_SUBJECTS
}
