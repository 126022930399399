import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from 'store/auth'
import SpinnerLoader from './loaders/SpinnerLoader'
import GitInfo from 'react-git-info/macro'
import { generalActions } from 'store/general'
import { getIsPantry, getIsShelter } from 'util/user'

const AuthenticatedRoute = ({ children, ...rest }) => {
  const { pathname, search } = useLocation()
  const history = useHistory()
  const { user, is_authenticated: isAuthenticated } = useSelector(
    (state) => state.auth
  )
  const {
    pantry_agreement_signed: pantryAgreementSigned,
    days_for_sign: daysForSign,
    registered_as: registeredAs,
    needs_to_sign_agreement: needsToSignAgreement,
    has_valid_ein: hasValidEin,
    shelter_verified: shelterVerified
  } = user
  const dispatch = useDispatch()
  const gitInfo = GitInfo()
  const isCreateCampaignRoute = pathname === '/campaign/create'
  const isPantry = getIsPantry(registeredAs)
  const isShelter = getIsShelter(registeredAs)
  const shelterCannotCreateCampaign =
    isShelter &&
    (!hasValidEin || !shelterVerified || needsToSignAgreement) &&
    daysForSign === 0
  const pantryCannotCreateCampaign =
    isPantry &&
    (!hasValidEin || !shelterVerified || !pantryAgreementSigned) &&
    daysForSign === 0

  useEffect(() => {
    dispatch(authActions.sagaCheckIsAuthenticated())
  }, [])

  useEffect(() => {
    const lastGitHash = localStorage.getItem('lastVersion')
    const hash = gitInfo.commit.shortHash

    if (!lastGitHash || lastGitHash !== hash) {
      localStorage.removeItem('persist:root')
      localStorage.removeItem('dashboardUpdate')
      localStorage.setItem('lastVersion', hash)
      dispatch(generalActions.setIsNewVersion(true))
    }
  }, [pathname])

  if (isCreateCampaignRoute && pantryCannotCreateCampaign) {
    history.push('/')
  }

  if (isCreateCampaignRoute && shelterCannotCreateCampaign) {
    history.push('/')
  }

  if (isAuthenticated === null) {
    return <SpinnerLoader />
  }

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        children
      ) : (
        <Redirect to={`/login?redirect=${pathname}${search}`} />
      )}
    </Route>
  )
}

AuthenticatedRoute.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthenticatedRoute
