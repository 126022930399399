import types from './types'

const sagaGetWishlist = ({
  id,
  page,
  userType,
  firstTime = false,
  shippingAddressId = undefined
}) => {
  return {
    type: types.SAGA_GET_WISHLIST,
    id,
    page,
    userType,
    firstTime,
    shippingAddressId
  }
}

const sagaGetProducts = (id, queryText, userType) => {
  return {
    type: types.SAGA_GET_PRODUCTS,
    id,
    query_text: queryText,
    userType
  }
}

const sagaSaveWishlist = (id, shippingAddressId, callback, userType) => {
  return {
    type: types.SAGA_SAVE_WISHLIST,
    id,
    shippingAddressId,
    callback,
    userType
  }
}

const sagaToggleWishlistStatus = (id, userType) => {
  return {
    type: types.SAGA_TOGGLE_WISHLIST_STATUS,
    id,
    userType
  }
}

const sagaPutFavorites = (productId, isFavorite, userType) => {
  return {
    type: types.SAGA_PUT_FAVORITES,
    productId,
    isFavorite,
    userType
  }
}

const setWishlist = (data, firstTime) => {
  return {
    type: types.SET_WISHLIST,
    data,
    first_time: firstTime
  }
}

const resetWishlist = () => {
  return {
    type: types.RESET_WISHLIST
  }
}

const selectCategory = (id) => {
  return {
    type: types.SELECT_CATEGORY,
    id
  }
}

const selectSubCategory = (id) => {
  return {
    type: types.SELECT_SUBCATEGORY,
    id
  }
}

const setProducts = (data) => {
  return {
    type: types.SET_PRODUCTS,
    data
  }
}

const resetCategories = () => {
  return {
    type: types.RESET_CATEGORIES
  }
}

const setSearch = (search) => {
  return {
    type: types.SET_SEARCH,
    search
  }
}

const toggleSubcategory = (id) => {
  return {
    type: types.TOGGLE_SUBCATEGORY,
    id
  }
}

const togglePrice = (id) => {
  return {
    type: types.TOGGLE_PRICE,
    id
  }
}

const toggleBrand = (name) => {
  return {
    type: types.TOGGLE_BRAND,
    name
  }
}

const resetNonCategoriesFilter = () => {
  return {
    type: types.RESET_NON_CATEGORIES_FILTER
  }
}

const removeFromCart = (id) => {
  return {
    type: types.REMOVE_FROM_CART,
    id
  }
}

const quantityUpdate = (product, variant, quantity = 1) => {
  return {
    type: types.QUANTITY_UPDATE,
    product,
    variant,
    quantity
  }
}

const toggleMostNeeded = (id) => {
  return {
    type: types.TOGGLE_MOST_NEEDED,
    id
  }
}

const toggleUnlimited = (id) => {
  return {
    type: types.TOGGLE_UNLIMITED,
    id
  }
}

const toggleWishlistStatus = () => {
  return {
    type: types.TOGGLE_WISHLIST_STATUS
  }
}

const togglePetType = (name) => {
  return {
    type: types.TOGGLE_PET_TYPE,
    name
  }
}

const cleanCart = () => {
  return {
    type: types.CLEAN_CART
  }
}

const cleanCartDeleted = () => {
  return {
    type: types.CLEAN_CART_DELETED
  }
}

const setWishlistStatus = (status) => {
  return {
    type: types.SET_WISHLIST_STATUS,
    status
  }
}

const toggleFavorites = () => {
  return {
    type: types.TOGGLE_FAVORITES
  }
}

const setWishlistModified = (modified) => {
  return {
    type: types.SET_WISHLIST_MODIFIED,
    modified
  }
}

const setHasValidShippingAddress = (hasValidShippingAddress) => {
  return {
    type: types.SET_HAS_VALID_SHIPPING_ADDRESS,
    hasValidShippingAddress
  }
}

const wishlistActions = {
  sagaGetWishlist,
  sagaGetProducts,
  sagaSaveWishlist,
  sagaToggleWishlistStatus,
  sagaPutFavorites,
  setWishlist,
  resetWishlist,
  selectCategory,
  selectSubCategory,
  setProducts,
  resetCategories,
  setSearch,
  toggleSubcategory,
  togglePrice,
  toggleBrand,
  resetNonCategoriesFilter,
  removeFromCart,
  quantityUpdate,
  toggleMostNeeded,
  toggleUnlimited,
  toggleWishlistStatus,
  togglePetType,
  cleanCart,
  cleanCartDeleted,
  setWishlistStatus,
  toggleFavorites,
  setWishlistModified,
  setHasValidShippingAddress
}

export default wishlistActions
