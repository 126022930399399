import types from './types'

const isLoading = (clearMsgs = true) => {
  return {
    type: types.IS_LOADING,
    clearMsgs
  }
}

const isNotLoading = () => {
  return {
    type: types.IS_NOT_LOADING
  }
}

const setError = (errorMessage) => {
  return {
    type: types.SET_ERROR,
    error_message: errorMessage
  }
}

const setSuccess = (successMessage) => {
  return {
    type: types.SET_SUCCESS,
    success_message: successMessage
  }
}

const setAlert = (alertMessage) => {
  return {
    type: types.SET_ALERT,
    alert_message: alertMessage
  }
}

const setUnauthorized = (err) => {
  return {
    type: types.SET_UNAUTHORIZED,
    err
  }
}

const setIsNewVersion = (isNewVersion) => {
  return {
    type: types.SET_IS_NEW_VERSION,
    isNewVersion
  }
}

const generalActions = {
  isLoading,
  isNotLoading,
  setError,
  setSuccess,
  setAlert,
  setUnauthorized,
  setIsNewVersion
}

export default generalActions
