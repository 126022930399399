import types from './types'

const INITIAL_STATE = {
  is_loading: false,
  error_message: false,
  success_message: false,
  alert_message: false,
  unauthorized: false,
  isNewVersion: false
}

const generalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.IS_LOADING: {
      return {
        ...state,
        is_loading: true,
        error_message: action.clearMsgs ? false : state.error_message,
        success_message: action.clearMsgs ? false : state.success_message
      }
    }

    case types.IS_NOT_LOADING: {
      return {
        ...state,
        is_loading: false
      }
    }

    case types.SET_ERROR: {
      return {
        ...state,
        is_loading: false,
        error_message: action.error_message,
        success_message: false
      }
    }

    case types.SET_SUCCESS: {
      return {
        ...state,
        is_loading: false,
        error_message: false,
        success_message: action.success_message
      }
    }

    case types.SET_ALERT: {
      return {
        ...state,
        alert_message: action.alert_message
      }
    }

    case types.SET_UNAUTHORIZED: {
      return {
        ...state,
        unauthorized: action?.err?.response?.status === 403 || false
      }
    }

    case types.SET_IS_NEW_VERSION: {
      return {
        ...state,
        isNewVersion: action.isNewVersion
      }
    }

    default:
      return state
  }
}

export default generalReducer
