// Sagas
const SAGA_GET_PANTRY_CAMPAIGN = 'SAGA_GET_PANTRY_CAMPAIGN'
const SAGA_POST_PANTRY_CAMPAIGN = 'SAGA_POST_PANTRY_CAMPAIGN'
const SAGA_PUT_PANTRY_CAMPAIGN = 'SAGA_PUT_PANTRY_CAMPAIGN'
const SAGA_GET_PANTRY_CAMPAIGNS = 'SAGA_GET_PANTRY_CAMPAIGNS'

// getters and setters
const SET_PANTRY_CAMPAIGN = 'SET_PANTRY_CAMPAIGN'
const SET_PANTRY_CAMPAIGN_FORM = 'SET_PANTRY_CAMPAIGN_FORM'
const RESET_PANTRY_CAMPAIGN_FORM = 'RESET_PANTRY_CAMPAIGN_FORM'
const SET_REMOVE_PANTRY_CAMPAIGN_PHOTO = 'SET_REMOVE_PANTRY_CAMPAIGN_PHOTO'
const SET_REMOVE_PANTRY_CAMPAIGN_VIDEO = 'SET_REMOVE_PANTRY_CAMPAIGN_VIDEO'
const SET_PANTRY_CAMPAIGNS = 'SET_PANTRY_CAMPAIGNS'
const SET_PANTRY_CAMPAIGNS_LOADING = 'SET_PANTRY_CAMPAIGNS_LOADING'
const RESET_PANTRY_CAMPAIGNS = 'RESET_PANTRY_CAMPAIGNS'

export default {
  SAGA_GET_PANTRY_CAMPAIGN,
  SAGA_POST_PANTRY_CAMPAIGN,
  SAGA_PUT_PANTRY_CAMPAIGN,
  SAGA_GET_PANTRY_CAMPAIGNS,
  SET_PANTRY_CAMPAIGN,
  SET_PANTRY_CAMPAIGN_FORM,
  RESET_PANTRY_CAMPAIGN_FORM,
  SET_REMOVE_PANTRY_CAMPAIGN_PHOTO,
  SET_REMOVE_PANTRY_CAMPAIGN_VIDEO,
  SET_PANTRY_CAMPAIGNS,
  SET_PANTRY_CAMPAIGNS_LOADING,
  RESET_PANTRY_CAMPAIGNS
}
