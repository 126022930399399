import types from './types'

const sagaGetPantryCampaignEmail = (id) => {
  return {
    type: types.SAGA_GET_PANTRY_CAMPAIGN_EMAIL,
    id
  }
}

const sagaPostPantryCampaignEmail = (payload, callback) => {
  return {
    type: types.SAGA_POST_PANTRY_CAMPAIGN_EMAIL,
    payload,
    callback
  }
}

const sagaGetCalculatePantryCampaignEmailList = (id) => {
  return {
    type: types.SAGA_GET_CALCULATE_PANTRY_CAMPAIGN_EMAIL_LIST,
    id
  }
}

const setPantryCampaignEmailForm = (payload) => {
  return {
    type: types.SET_PANTRY_CAMPAIGN_EMAIL_FORM,
    payload
  }
}

const updatePantryCampaignEmailForm = (key, value) => {
  return {
    type: types.UPDATE_PANTRY_CAMPAIGN_EMAIL_FORM,
    key,
    value
  }
}

const resetPantryCampaignEmailForm = () => {
  return {
    type: types.RESET_PANTRY_CAMPAIGN_EMAIL_FORM
  }
}

const setPantryCampaignEmailFormLoading = (loading = true) => {
  return {
    type: types.SET_PANTRY_CAMPAIGN_EMAIL_FORM_LOADING,
    loading
  }
}

const setExhaustedPantryCampaignEmailList = (exhausted = true) => {
  return {
    type: types.SET_EXHAUSTED_PANTRY_CAMPAIGN_EMAIL_LIST,
    exhausted
  }
}

const updatePantryCampaignEmailList = (data) => {
  return {
    type: types.UPDATE_PANTRY_CAMPAIGN_EMAIL_LIST,
    data
  }
}

const pantryCampaignEmailActions = {
  sagaGetPantryCampaignEmail,
  sagaPostPantryCampaignEmail,
  sagaGetCalculatePantryCampaignEmailList,
  setPantryCampaignEmailForm,
  updatePantryCampaignEmailForm,
  resetPantryCampaignEmailForm,
  setPantryCampaignEmailFormLoading,
  setExhaustedPantryCampaignEmailList,
  updatePantryCampaignEmailList
}

export default pantryCampaignEmailActions
