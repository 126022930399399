import types from './types'

const sagaFundIndex = (payload) => {
  return {
    type: types.SAGA_FUND_INDEX,
    payload
  }
}

const sagaECommerceFundReport = (payload) => {
  return {
    type: types.SAGA_ECOMMERCE_FUND_REPORT,
    payload
  }
}

const sagaECommerceFundReportDetails = (payload) => {
  return {
    type: types.SAGA_ECOMMERCE_FUND_REPORT_DETAILS,
    payload
  }
}

const setPaginator = (paginator) => {
  return {
    type: types.SET_PAGINATOR,
    paginator
  }
}

const setCurrent = (current) => {
  return {
    type: types.SET_CURRENT,
    current
  }
}

const setECommerceFundReportDetails = (payload) => {
  return {
    type: types.SET_ECOMMERCE_FUND_REPORT_DETAILS,
    payload
  }
}

const setLoading = (loading) => {
  return {
    type: types.SET_LOADING,
    payload: loading
  }
}

const fundActions = {
  sagaFundIndex,
  sagaECommerceFundReport,
  sagaECommerceFundReportDetails,
  setPaginator,
  setCurrent,
  setECommerceFundReportDetails,
  setLoading
}

export default fundActions
