import types from './types'

const INITIAL_STATE = {
  users: [],
  isLoading: false,
  signPantryAgreement: false
}

export default function usersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_USERS: {
      return {
        ...state,
        users: action.payload
      }
    }

    case types.SET_USERS_LOADING: {
      return {
        ...state,
        isLoading: action.loading
      }
    }

    case types.SET_SIGN_PANTRY_AGREEMENT: {
      return {
        ...state,
        signPantryAgreement: action.payload
      }
    }

    default:
      return state
  }
}
