import { takeLatest, all } from 'redux-saga/effects'
import types from '../types'
import doPostSkipEa from './doPostSkipEa'
import doPutFixLimbo from './doPutFixLimbo'
import doGetShopifyVariants from './doGetShopifyVariants'
import doGetShopifyProducts from './doGetShopifyProducts'
import doGetLimboProducts from './doGetLimboProducts'
import doGetPendingProducts from './doGetPendingProducts'
import doPutParameters from './doPutParameters'
import doGetParameters from './doGetParameters'
import doGetPendingOrders from './doGetPendingOrders'
import doGetPendingOrdersProducts from './doGetPendingOrdersProducts'
import doGetEaInventory from './doGetEaInventory'
import doGetCartOrderItems from './doGetCartOrderItems'
import doPostCartOrderItem from './doPostCartOrderItem'
import doDeleteCartOrderItem from './doDeleteCartOrderItem'
import doDeleteAllCartOrderItems from './doDeleteAllCartOrderItems'
import doPostShopifyOrder from './doPostShopifyOrder'
import doGetPendingOrdersReport from './doGetPendingOrdersReport'
import doGetLimboProductDetailsBySku from './doGetLimboProductDetailsBySku'
import doGetDownloadLimboProductsReport from './doGetDownloadLimboProductsReport'
import doGetPendingOrdersReportDetails from './doGetPendingOrdersReportDetails'

function* watchGetList() {
  yield takeLatest(types.SAGA_FIX_LIMBO, doPutFixLimbo)
  yield takeLatest(types.SAGA_GET_SHOPIFY_VARIANTS, doGetShopifyVariants)
  yield takeLatest(types.SAGA_GET_SHOPIFY_PRODUCT, doGetShopifyProducts)
  yield takeLatest(types.SAGA_SKIP_EA, doPostSkipEa)
  yield takeLatest(types.SAGA_GET_LIMBO_PRODUCTS, doGetLimboProducts)
  yield takeLatest(types.SAGA_GET_PENDING_PRODUCTS, doGetPendingProducts)
  yield takeLatest(types.SAGA_PUT_PARAMETERS, doPutParameters)
  yield takeLatest(types.SAGA_GET_PARAMETERS, doGetParameters)
  yield takeLatest(types.SAGA_GET_PENDING_ORDERS, doGetPendingOrders)
  yield takeLatest(
    types.SAGA_GET_PENDING_ORDERS_PRODUCTS,
    doGetPendingOrdersProducts
  )
  yield takeLatest(types.SAGA_GET_EA_INVENTORY, doGetEaInventory)
  yield takeLatest(types.SAGA_GET_CART_ORDER_ITEMS, doGetCartOrderItems)
  yield takeLatest(types.SAGA_POST_CART_ORDER_ITEM, doPostCartOrderItem)
  yield takeLatest(types.SAGA_DELETE_CART_ORDER_ITEM, doDeleteCartOrderItem)
  yield takeLatest(
    types.SAGA_DELETE_ALL_CART_ORDER_ITEMS,
    doDeleteAllCartOrderItems
  )
  yield takeLatest(types.SAGA_POST_SHOPIFY_ORDER, doPostShopifyOrder)
  yield takeLatest(
    types.SAGA_GET_PENDING_ORDERS_REPORT,
    doGetPendingOrdersReport
  )
  yield takeLatest(
    types.SAGA_GET_LIMBO_PRODUCT_DETAILS_BY_SKU,
    doGetLimboProductDetailsBySku
  )
  yield takeLatest(
    types.SAGA_DOWNLOAD_SKUS_IN_LIMBO_REPORT,
    doGetDownloadLimboProductsReport
  )
  yield takeLatest(
    types.SAGA_GET_PENDING_ORDERS_REPORT_DETAILS,
    doGetPendingOrdersReportDetails
  )
}

export default function* rootSaga() {
  yield all([watchGetList()])
}
