const SAGA_GET_PANTRY_CAMPAIGN_EMAIL = 'SAGA_GET_PANTRY_CAMPAIGN_EMAIL'
const SAGA_POST_PANTRY_CAMPAIGN_EMAIL = 'SAGA_POST_PANTRY_CAMPAIGN_EMAIL'
const SAGA_GET_CALCULATE_PANTRY_CAMPAIGN_EMAIL_LIST =
  'SAGA_GET_CALCULATE_PANTRY_CAMPAIGN_EMAIL_LIST'
const RESET_PANTRY_CAMPAIGN_EMAIL_FORM = 'RESET_PANTRY_CAMPAIGN_EMAIL_FORM'
const SET_PANTRY_CAMPAIGN_EMAIL_FORM = 'SET_PANTRY_CAMPAIGN_EMAIL_FORM'
const UPDATE_PANTRY_CAMPAIGN_EMAIL_FORM = 'UPDATE_PANTRY_CAMPAIGN_EMAIL_FORM'
const SET_PANTRY_CAMPAIGN_EMAIL_FORM_LOADING =
  'SET_PANTRY_CAMPAIGN_EMAIL_FORM_LOADING'
const SET_EXHAUSTED_PANTRY_CAMPAIGN_EMAIL_LIST =
  'SET_EXHAUSTED_PANTRY_CAMPAIGN_EMAIL_LIST'
const UPDATE_PANTRY_CAMPAIGN_EMAIL_LIST = 'UPDATE_PANTRY_CAMPAIGN_EMAIL_LIST'

export default {
  SAGA_GET_PANTRY_CAMPAIGN_EMAIL,
  SAGA_POST_PANTRY_CAMPAIGN_EMAIL,
  SAGA_GET_CALCULATE_PANTRY_CAMPAIGN_EMAIL_LIST,
  RESET_PANTRY_CAMPAIGN_EMAIL_FORM,
  SET_PANTRY_CAMPAIGN_EMAIL_FORM,
  UPDATE_PANTRY_CAMPAIGN_EMAIL_FORM,
  SET_PANTRY_CAMPAIGN_EMAIL_FORM_LOADING,
  SET_EXHAUSTED_PANTRY_CAMPAIGN_EMAIL_LIST,
  UPDATE_PANTRY_CAMPAIGN_EMAIL_LIST
}
