import types from './types'

const INITIAL_STATE = {
  loading: false,
  form: {
    name: '',
    title: '',
    address: ''
  }
}

const pantryAgreementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PANTRY_AGREEMENT_LOADING: {
      return {
        ...state,
        loading: action.loading
      }
    }

    default:
      return state
  }
}

export default pantryAgreementReducer
