import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import axios from 'axios'
import { getImpersonatedUser } from 'util/impersonation'

let apiUrl

if (process.env.REACT_APP_ENV === 'production') {
  apiUrl = process.env.REACT_APP_API_BACKEND_PRODUCTION
} else if (process.env.REACT_APP_ENV === 'development') {
  apiUrl = process.env.REACT_APP_API_BACKEND_DEVELOPMENT
} else if (process.env.REACT_APP_ENV === 'release') {
  apiUrl = process.env.REACT_APP_API_BACKEND_RELEASE
} else {
  apiUrl = process.env.REACT_APP_API_BACKEND_LOCAL
}
axios.defaults.baseURL = apiUrl
axios.defaults.withCredentials = true

axios.interceptors.request.use((request) => {
  if (
    localStorage.getItem('token') &&
    localStorage.getItem('token').length > 0
  ) {
    request.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    const cuddlyUser = getImpersonatedUser()

    if (cuddlyUser) {
      request.headers['X-Cuddly-User'] = cuddlyUser
    }
  }

  return request
})

// TODO: Find a better way to apply this polyfill.
// eslint-disable-next-line no-extend-native
Array.prototype.remove = function () {
  let what
  const a = arguments
  let L = a.length
  let ax
  while (L && this.length) {
    what = a[--L]
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1)
    }
  }
  return this
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
