import { call, put } from 'redux-saga/effects'
import errorPaser from 'util/laravel_error_parser'
import { generalActions } from '../../general'
import { getShopifyProducts } from './api'
import actions from '../actions'

function* doGetShopifyProducts(action) {
  try {
    yield put(actions.setLoadingSkus(true))
    const response = yield call(getShopifyProducts, action)
    if (response.data.message) {
      yield put(actions.setFixMessage(response.data.message))
    } else if (response.data.variants) {
      yield put(
        actions.setFixVariants(response.data.product, response.data.variants)
      )
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(actions.setLoadingSkus(false))
  }
}

export default doGetShopifyProducts
