import types from './types'

const sagaPostPantryAgreement = (data, callback) => {
  return {
    type: types.SAGA_POST_PANTRY_AGREEMENT,
    data,
    callback
  }
}

const setPantryAgreementLoading = (loading = false) => {
  return {
    type: types.SET_PANTRY_AGREEMENT_LOADING,
    loading
  }
}

export default {
  sagaPostPantryAgreement,
  setPantryAgreementLoading
}
