import { call, put, takeLatest, all } from 'redux-saga/effects'
import axios from 'axios'
import types from './types'
import { generalActions } from '../general'
import actions from './actions'
import errorPaser from 'util/laravel_error_parser'

// Axios Calls
const createCampaign = async () => {
  return await axios.get('api/shelter/campaigns/create')
}

const storeCampaign = async (data) => {
  return await axios.post('api/shelter/campaigns', data)
}

const editCampaign = async (id) => {
  // headers added to prevent caching issues when redirect is called and user uses browser's back button
  return await axios.get(`api/shelter/campaigns/${id}/edit`, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0'
    }
  })
}

const updateCampaign = async (id, data) => {
  return await axios.post(`api/shelter/campaigns/${id}`, data)
}

const indexBreed = async (specie) => {
  return await axios.get(`api/shelter/breeds?specie=${specie}`)
}

const updateCampaignStatus = async (id, status) => {
  return await axios.put(`api/shelter/campaigns/status/${id}`, {
    is_active: status
  })
}

// Doers
function* doCreateCampaign() {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(createCampaign)
    yield put(actions.setCampaignOptions(data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doIndexBreed(action) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(indexBreed, action.specie)
    yield put(actions.setCampaignBreeds(data))
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doStoreCampaign(action) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(storeCampaign, action.data)
    yield put(actions.resetCampaign())
    yield put(generalActions.setSuccess(response.data.message))

    if (action.callback) {
      action.callback(response.data.id)
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doEditCampaign(action) {
  try {
    yield put(generalActions.isLoading())
    const { data } = yield call(editCampaign, action.id)
    yield put(actions.setCampaign(data))
    yield put(generalActions.isLoading())
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doUpdateCampaign(action) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(updateCampaign, action.id, action.data)
    yield put(generalActions.setSuccess(response.data.message))

    if (action.callback) {
      action.callback(response.data.id)
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

function* doUpdateCampaignStatus(action) {
  try {
    yield put(generalActions.isLoading())
    const response = yield call(updateCampaignStatus, action.id, action.status)
    yield put(generalActions.setSuccess(response.data.message))

    if (action.callback) {
      action.callback()
    }
  } catch (err) {
    yield put(generalActions.setError(errorPaser(err)))
    yield put(generalActions.setUnauthorized(err))
  } finally {
    yield put(generalActions.isNotLoading())
  }
}

// Watchers
function* watchDoCreateCampaign() {
  yield takeLatest(types.SAGA_CREATE_CAMPAIGN, doCreateCampaign)
}

function* watchDoIndexBreed() {
  yield takeLatest(types.SAGA_INDEX_CAMPAIGN_BREEDS, doIndexBreed)
}

function* watchDoStoreCampaign() {
  yield takeLatest(types.SAGA_STORE_CAMPAIGN, doStoreCampaign)
}

function* watchDoEditCampaign() {
  yield takeLatest(types.SAGA_EDIT_CAMPAIGN, doEditCampaign)
}

function* watchDoUpdateCampaign() {
  yield takeLatest(types.SAGA_UPDATE_CAMPAIGN, doUpdateCampaign)
}

function* watchDoUpdateCampaignStatus() {
  yield takeLatest(types.SAGA_UPDATE_CAMPAIGN_STATUS, doUpdateCampaignStatus)
}

export default function* rootSaga() {
  yield all([
    watchDoCreateCampaign(),
    watchDoIndexBreed(),
    watchDoStoreCampaign(),
    watchDoEditCampaign(),
    watchDoUpdateCampaign(),
    watchDoUpdateCampaignStatus()
  ])
}
