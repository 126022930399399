import types from './types'

const INITIAL_STATE = {
  paginator: {},
  current: {},
  details: {},
  fullBowlsDetails: {},
  loading: false,
  downloading: false,
  downloadingDonors: false
}

const pantryDonationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PAGINATOR: {
      return {
        ...state,
        paginator: action.paginator
      }
    }

    case types.SET_CURRENT: {
      return {
        ...state,
        current: action.current
      }
    }

    case types.SET_DETAILS: {
      return {
        ...state,
        details: action.payload
      }
    }

    case types.SET_FULL_BOWL_DETAIL: {
      return {
        ...state,
        fullBowlsDetails: action.payload
      }
    }

    case types.SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }

    case types.SET_DOWNLOADING: {
      return {
        ...state,
        downloading: action.payload
      }
    }

    case types.SET_DOWNLOADING_DONORS: {
      return {
        ...state,
        downloadingDonors: action.payload
      }
    }

    default:
      return state
  }
}

export default pantryDonationsReducer
