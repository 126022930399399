import { useEffect, useMemo } from 'react'

const useTabSession = () => {
  const id = useMemo(() => Date.now(), [])

  useEffect(() => {
    if (sessionStorage.getItem('tabId') === null) {
      sessionStorage.setItem('tabId', id)
    }
  }, [id])

  return id
}

export default useTabSession
